/* global monstecLib */
/* global M */

export default class ActionButtons {
    /**
     * Create new button bar ready to be attached to an element.
     *
     * @param {*} buttons An array containing the button configuration; each array item must be an object that has
     *                    both properties 'icon' and 'func'. 'icon' will be the symbol shown on the button (see
     *                    materializecss icons for a reference). 'func' will be a function executed when the
     *                    button is clicked.
     * @param {*} direction Defines where the actual action buttons will appear when activating the main link.
     *                      May be 'top', 'right', 'bottom' or 'left' (default).
     */
    constructor(buttons, direction) {
        this.buttons = buttons;
        this.direction = direction;

        this.i18next = monstecLib.i18next;
        this.utils = monstecLib.produckContext.utils;

        this.log = new monstecLib.Log(1);
    }

    /**
     *
     * @param {*} identifier the ID of the html element to attach the buttons to or a marker css class in
     *                       conjunction with elementTree
     * @param {*} elementTree an otional tree of elements to look for the element having the above ID or class
     *                        (in case the elements are not part of the DOM yet)
     */
    attachTo(identifier, elementTree) {
        const instance = this;
        if (instance.container) {
            instance.log.warn('Already attached!');
            return;
        }

        if (elementTree) {
            instance.container =  elementTree.find('#' + identifier);
            if (instance.container.length == 0) {
                instance.container = elementTree.find('.' + identifier);
            }
        } else {
            instance.container = $('#' + identifier);
        }

        if (instance.container.length == 0) {
            instance.log.error('No element to attach to!');
            return;
        }

        if (!instance.buttons || !Array.isArray(instance.buttons) || instance.buttons.length < 1) {
            instance.log.debug('No buttons defined! Element will not be attached.');
            return;
        }

        instance.container.addClass('action-buttons');
        let moreActionsButtons = instance._addActionButtons();

        let moreActionsLink = $('<a class="actn-btns-more"><i class="material-icons">more_horiz</i></a>');
        instance.moreActionsLink = moreActionsLink;
        instance.container.append(moreActionsLink);

        moreActionsLink.on('click', function(event) {
            event.preventDefault();
            event.stopImmediatePropagation();
            if (instance.blocked) return;
            moreActionsButtons.toggleClass('hide');
        });

        instance.container.hover(function(event) {
                event.preventDefault();
                event.stopImmediatePropagation();
                if (instance.blocked) return;
                moreActionsButtons.removeClass('hide');
            },
            function(event) {
                event.preventDefault();
                event.stopImmediatePropagation();
                if (instance.blocked) return;
                moreActionsButtons.addClass('hide');
            }
        );
    }

    /**
     * Creates the button bar, appends it to the container and returns the created element.
     */
    _addActionButtons() {
        const instance = this;
        let moreActionsButtons = $('<ul class="hide"></ul>');

        instance.buttons.forEach(button => {
            let buttonContainer = $('<li></li>');
            let buttonElement = $('<a class="btn-small prdk-btn icon-btn"><i class="tiny material-icons">' + button.icon + '</i></a>');
            if (button.tooltip) {
                buttonElement.attr('title', instance.i18next.t(button.tooltip));
            }

            buttonElement.on('click', function(event) {
                event.preventDefault();
                event.stopImmediatePropagation();

                if (button.func)  {
                    let funcResult = button.func();

                    // Check if loader is activated for the button and if the function defined for the current
                    // button returns a promise. To check the latter a check for a 'then'-method will be performed.
                    // This is far from a certain check result but the best that can be achieved as far as I know.
                    // Note that 'typeof(funcResult) === Promise' won't work since the promise is just an 'object'
                    // as far as the typeof-function is concerned.
                    // If the prerequisites are fullfilled the appearance of the moreActionsButton will change to
                    // a loader while the operation triggered by the current button is executed.
                    if (funcResult && funcResult.then && button.showLoader) {
                        instance.moreActionsLink.hide();
                        instance.blocked = true;
                        instance.utils.addLoader(instance.container, 'tiny');

                        funcResult.finally(function() {
                            instance.moreActionsLink.show();
                            instance.blocked = false;
                            instance.utils.removeLoader(instance.container);
                        });
                    }
                }

                moreActionsButtons.addClass('hide');
            });

            buttonContainer.append(buttonElement);
            moreActionsButtons.append(buttonContainer);
        });

        switch (instance.direction) {
            case 'top':
                // Calculate the offset for positioning the buttons. The height of a singular button is 32 so
                // the buttonbar has to be lifted by x * 42 / 2, where x is the number of defined buttons. The
                // last part is because the div containing the buttons is centered in its parent container.
                // The additional pixels are for bringing the button bar above the main link.
                let topVertOffset = instance.buttons.length * 34 / 2 + 10;
                instance.container.prepend(moreActionsButtons);
                moreActionsButtons.addClass('simple-vertical-content');
                moreActionsButtons.css('transform', `translateX(0px) translateY(-${topVertOffset}px)`);
                break;
            case 'bottom':
                // see comment for top
                let bottomVertOffset = instance.buttons.length * 34 / 2 + 10;
                instance.container.append(moreActionsButtons);
                moreActionsButtons.addClass('simple-vertical-content');
                moreActionsButtons.css('transform', `translateX(0px) translateY(${bottomVertOffset}px)`);
                break;
            case 'right':
                // Calculate the offset for positioning the buttons. The witdh of a singular button is 50 so
                // the buttonbar has to be shifted by x * 40 / 2 to the left, where x is the number of defined
                // buttons. The last part is because the div containing the buttons is centered in its parent
                // container. The additional pixels are for bringing the button bar completely to the left of
                // the main link.
                let rightHoriOffset = instance.buttons.length * 54 / 2 + 8;
                instance.container.append(moreActionsButtons);
                moreActionsButtons.addClass('simple-horizontal-content');
                moreActionsButtons.css('transform', `translateX(${rightHoriOffset}px) translateY(0px)`);
                break;

            default:
                // see comment for right
                let leftHoriOffset = instance.buttons.length * 54 / 2 + 8;
                instance.container.prepend(moreActionsButtons);
                moreActionsButtons.addClass('simple-horizontal-content');
                moreActionsButtons.css('transform', `translateX(-${leftHoriOffset}px) translateY(0px)`);
        }

        return moreActionsButtons;
    }
}
