/* global monstecLib */
/* global M */

/**
 * If using this component class, a function 'sendComment(referenceId, text)' has to be defined which will send
 * a comment text to a backend service and return a promise indicating the result of the operation.
 */
export default class QuackCommentSection {
    constructor() {
        this.MIN_CHARACTER_COUNT = 15;
        this.MAX_CHARACTER_COUNT = 500;

        this.i18next = monstecLib.i18next;
        this.utils = monstecLib.produckContext.utils;
        this.chatService = monstecLib.produckContext.chatService;
        this.authenticator = monstecLib.produckContext.authenticator;
        this.cookie = monstecLib.produckContext.cookie;
        this.register = monstecLib.produckContext.register;

        this.log = new monstecLib.Log(1);
    }

    /**
     * Attaches the component class to an html element.
     *
     * @param {*} htmlId the html-ID of the comment structure element tree
     */
    attachTo(htmlId) {
        const instance = this;
        instance.commentSection = $('#' + htmlId);
        if (!instance.commentSection) {
            instance.log.error(`Can not attach to element with ID ${htmlId}, no such element found!`);
            return;
        }

        instance.referenceId = instance.commentSection.attr('data-reference-id');
        instance.addCommentControls = instance.commentSection.find('.js-add-new-comment-controls');

        instance.charsHint = instance.commentSection.find('.js-number-of-chars-hint');
        instance.commentArea = instance.commentSection.find('.js-new-comment-area');
        instance.commentArea.on('input', instance._updateCharCounter.bind(instance));

        instance.showControlsLink = instance.commentSection.find('.js-show-add-comment-controls');
        instance.showControlsLink.click(async function() {
            if (await instance.authenticator.checkPermission(instance.authenticator.permCat.ADD_CHAT_COMMENT)) {
                instance.addCommentControls.removeClass('hide');
                $(this).parent().addClass('hide');
                instance.commentArea.val('');
                M.textareaAutoResize(instance.commentArea);
            } else {
                instance.register.initSignInForm(true);
                return;
            }
        });

        const newCommentButton = instance.commentSection.find('.js-add-new-comment-button');
        newCommentButton.click(function() {
            newCommentButton.prop('disabled', true);
            let width = newCommentButton.width();
            let height = newCommentButton.height();
            newCommentButton.data('preservedContent', newCommentButton.text());
            newCommentButton.text('');

            instance.utils.addLoader(newCommentButton[0], 'tiny');

            newCommentButton.width(width);
            newCommentButton.height(height);

            instance._sendComment();
        });

        const closeControlsLink = instance.commentSection.find('.js-close-controls-link');
        closeControlsLink.click(function() {
            instance._resetState();
        });

        // initialise counter label with correct language
        instance._updateCharCounter();
    }

    addCommentToList(text, nickname, timestamp) {
        const instance = this;

        if (!nickname) {
            nickname = instance.i18next.t('general.you');
        }

        if (!timestamp) {
            let now = new Date();
            timestamp = instance.utils.transformDate(now) + ', ' + instance.utils.transformTime(now);
        }

        let existingCommentBlocks = instance.commentSection.find('.quack-comment-block');

        let textContainer = $('<div class="quack-comment-text">');
        textContainer.text(text);

        let metaContainer = $('<div class="quack-comment-meta">');
        metaContainer.append('<span class="quack-comment-user">' + nickname + '</span>');
        metaContainer.append('<span>&nbsp;&dash;&nbsp;</span>');
        metaContainer.append('<span class="quack-comment-date">' + timestamp + '</span>');

        let commentBlock = $('<div class="quack-comment-block">');
        commentBlock.append(textContainer);
        commentBlock.append(metaContainer);

        if (existingCommentBlocks.length == 0) {
            // first comment
            instance.commentSection.prepend(commentBlock);

            // since it is the first comment an empty comment block has to be inserted to create
            // a separation line between the comments and the controls
            commentBlock.after($('<div class="quack-comment-block">'));

        } else {
            // not the first comment so insert at the end
            commentBlock.insertBefore(existingCommentBlocks.last());
        }
    }

    _updateCharCounter() {
        const instance = this;
        let currentText = instance.commentArea.val();
        let currentLength = (!!currentText) ? currentText.length : 0;

        if (currentLength > instance.MIN_CHARACTER_COUNT) {
            instance.charsHint.text(currentLength + '/' + instance.MAX_CHARACTER_COUNT);
            if (currentLength > instance.MAX_CHARACTER_COUNT) {
                instance.commentArea.addClass('invalid');
            } else {
                instance.commentArea.removeClass('invalid');
            }
        } else {
            let label = instance.i18next.t('general.minimum_character_count_label', { min: this.MIN_CHARACTER_COUNT});
            instance.charsHint.text(label);
        }
    }

    _sendComment() {
        const instance = this;
        let text = instance.commentArea.val();

        function resetButton() {
            let sendCommentButton = instance.commentSection.find('.js-add-new-comment-button');
            sendCommentButton.prop('disabled', false);
            instance.utils.removeLoader(sendCommentButton[0]);
            sendCommentButton.text(sendCommentButton.data('preservedContent'));
            sendCommentButton.blur();
        }

        if (!instance.sendComment) {
            instance.log.error('Comment section has not been properly initialised, sendComment is not defined.');
            resetButton();
            return;
        }

        if (!text || text.length < instance.MIN_CHARACTER_COUNT || text.length > instance.MAX_CHARACTER_COUNT) {
            M.toast({html: instance.i18next.t('toasts.data_invalid')});
            resetButton();
            return;
        }

        if (!instance.referenceId || isNaN(Number(instance.referenceId))) {
            instance.log.error('No valid quack ID assigned to section!');
            M.toast({html: instance.i18next.t('toasts.error')});
        }

        instance.sendComment(instance.referenceId, text)
        .then(function() {
            instance._resetState();
            instance.addCommentToList(text);
            M.toast({html: instance.i18next.t('toasts.data_updated')});
            resetButton();
        })
        .catch(function(e) {
            if (e.status == 400) {
                instance.commentArea.addClass('invalid');
                M.toast({html: instance.i18next.t('toasts.data_invalid')});
            } else if (e.status == 401 || e.status == 403) {
                // TODO show registration popup
                M.toast({html: instance.i18next.t('toasts.error')});
            } else {
                M.toast({html: instance.i18next.t('toasts.error')});
            }
            resetButton();
        });
    }

    _resetState() {
        const instance = this;
        instance.showControlsLink.parent().removeClass('hide');
        instance.addCommentControls.addClass('hide');
        instance.commentArea.val('');
        instance._updateCharCounter();
        instance.commentSection.find('.js-add-new-comment-button').prop('disabled', false);
        M.textareaAutoResize(instance.commentArea);
    }
}
