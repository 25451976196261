/**
 * Log levels:
 * 0 : silent
 * 1 : error
 * 2 : warn
 * 3 : info
 * 4 : debug
 */
export default class Log {
    constructor(loglevel, namespace) {
        this.loglevel = loglevel;
        if (!!namespace) {
            this.namespace = namespace;
        }
    }

    debug() {
        if (this.loglevel >= 4) {
            this._callForArgumentNumber(arguments, console.debug);
        }
    }

    info() {
        if (this.loglevel >= 3) {
            this._callForArgumentNumber(arguments, console.info);
        }
    }

    warn() {
        if (this.loglevel >= 2) {
            this._callForArgumentNumber(arguments, console.warn);
        }
    }

    error() {
        if (this.loglevel >= 1) {
            this._callForArgumentNumber(arguments, console.error);
        }
    }

    _callForArgumentNumber(logArguments, logFunction) {
        switch(logArguments.length) {
            case 0:
                return;
            case 1:
                logFunction(this._pimpMessage(logArguments[0]));
                return;
            case 2:
                logFunction(this._pimpMessage(logArguments[0]), logArguments[1]);
                return;
            case 3:
                logFunction(this._pimpMessage(logArguments[0]), logArguments[1], logArguments[2]);
                return;
            case 4:
                logFunction(this._pimpMessage(logArguments[0]), logArguments[1], logArguments[2], logArguments[3]);
                return;
            case 5:
                logFunction(this._pimpMessage(logArguments[0]), logArguments[1], logArguments[2], logArguments[3], logArguments[4]);
                return;
            case 6:
                logFunction(this._pimpMessage(logArguments[0]), logArguments[1], logArguments[2], logArguments[3], logArguments[4], logArguments[5]);
                return;
            default:
                console.warn('LOG - Add case for ' + arguments.length + ' arguments!');
            }
    }

    _pimpMessage(msg) {
        if (!!this.namespace) {
            return this.namespace + " - " + msg;
        } else {
            return msg;
        }
    }
}