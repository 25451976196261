/* global monstecLib */
/* global M */
import i18next from '../localisation.js';
import AbstractOfferSection from './abstractoffersection';

export default class ProductManagementSection extends AbstractOfferSection {
    /**
     * Creates a new instance of this section.
     * 
     * @param {object} syncPermissioChecker a commonly used synchronous permission checker
     */
    constructor(syncPermissioChecker) {
        super();
        this.authChecker = syncPermissioChecker;

        this.utils = monstecLib.produckContext.utils;
        this.productHandler = monstecLib.produckContext.productHandler;
        this.authenticator = monstecLib.produckContext.authenticator;
        this.chatClient = monstecLib.produckContext.chatClient;
        this.chatSupport = monstecLib.produckContext.chatSupport;
        this.portalSupport = monstecLib.produckContext.portalSupport;

        this.log = new monstecLib.Log(1);
    }

    attachTo(rootElementId) {
        let instance = this;
        instance.productSection = $('#' + rootElementId);

        function initSearchFnc() {
            $('#search-bar-wrapper .search-bar-form').bind('submit', (ev) => {
                ev.preventDefault(); //prevent submit
            });

            $('#product-search-submit-btn').click((e) => {
                e.preventDefault();
                var id = $('.search-bar-request').val().trim();
                instance.createProductSearchContainer(id);
            });

            // submit request on keypress return
            $('#search-products-input').keypress((e) => {
                if (e.keyCode === 13) $('#product-search-submit-btn').click();
            });
        }

        // INIT PRODUCT SEARCH MENU
        $('#product-search-mobile').on('click', async function() {
            var uid = await instance.authenticator.getUser();
            uid ? instance.createProductSearchContainer(uid) : instance.createProductSearchContainer();
        });

        initSearchFnc();
        M.textareaAutoResize($('#edit-prod-shortDescription'));
    }

    searchProductsForUser(id) {
        this.createProductSearchContainer(id);
    }

    async initEditFunctionality(onlyForOfferItems) {
        var instance = this;

        let mayWriteOffers = instance.authChecker.checkPermission(instance.authChecker.permCat.WRITE_OFFER);
        if (!mayWriteOffers) {

            let showUpgradeDialogue = function() {
                var headline = i18next.t('text.upgrade_modal_headline');
                var text = i18next.t('text.upgrade_modal_text');
                var optionOne = i18next.t('text.upgrade');
                var optionTwo = i18next.t('text.later');
    
                //creates a suitable modal
                instance.utils.createModal($('body'), headline, text, optionOne, optionTwo, 'accountUpgradeModal');
                $('#accountUpgradeModal').find('.option-one.modal-close').click(function() {
                    $('#general-settings-mobile a').click();
                    const section = $('#target7');
                    setTimeout(function() {
                        $('#sectionSettingsSubsectionAccountHeader').click();
                        setTimeout(function() {
                            section.animate({scrollTop: section[0].scrollHeight}, 500);
                        }, 200);
                    }, 500);
                });
            };
            
            $('#product-add-submit-btn').off('click').on('click', showUpgradeDialogue);
            // In some cases the removal of the modal-overlay by materializecss does not work in some browsers
            // For example if the modal is opened by an edit button in the product overview. Currently the
            // model cannot be used there.

            return;

        } else {
            $('.product-edit-btn').removeClass('disabled');
        }

        $('.product-edit-btn').on('click', async (e) => {
            $(document).trigger("loader:on");
            try {
                await instance._transferOfferDataToForm(e.currentTarget);
            } finally {
                $(document).trigger("loader:off");
            }

            $('#product-upload-wrapper').addClass('active');
            let productSection = $('#target8');
            productSection.animate({scrollTop: 0}, 200);
        });

        if (onlyForOfferItems) return;

        $('#product-add-submit-btn').on('click', () => {
            instance._emptyOfferForm();
            $('#product-upload-wrapper').addClass('active');
            $('textarea#edit-prod-shortDescription').characterCounter();
        });

        // save select field values whenever they change
        $('#product-upload-mode-select').off().on('change', () => {
            $('#product-upload-wrapper .new-object-form').toggleClass('active');
        });

        $('#save-prod-data').on('click', (e) => {
            e.preventDefault();
            e.stopImmediatePropagation();
            $(document).trigger("loader:on");

            let productOffer = {};
            let productWrapper = this.productSection.find('#product-upload-wrapper');
            productOffer.offerId = productWrapper.find('#edit-prod-offerId').val();
            productOffer.shortName = productWrapper.find('#edit-prod-shortName').val();
            productOffer.shortDescription = productWrapper.find('#edit-prod-shortDescription').val();
            productOffer.imageUrl = productWrapper.find('#edit-prod-imageUrl').val();
            productOffer.price = productWrapper.find('#edit-prod-price').val();
            
            let detailsUrl = productWrapper.find('#edit-prod-detailsUrl').val();
            if (!!detailsUrl && detailsUrl.length > 0) {
                productOffer.detailsUrl = detailsUrl;
            }

            instance.productHandler.saveOffer(productOffer)
            .then(async function(responsePayload) {
                $('#product-upload-wrapper').removeClass('active');
                M.toast({html: i18next.t('toasts.data_updated')});
                instance._emptyOfferForm();

                if (responsePayload.offerId) {
                    // offer has been created so a new container has to be built for it
                    let offer = await instance.productHandler.getOfferDetails(responsePayload.offerId);
                    let tOffer = instance.productHandler._transformOffer(offer);
                    let offerHtml = instance.productHandler.buildSingleItem(tOffer, 21112);
                    instance.productSection.find('.product-wrapper').prepend(offerHtml);
                    instance.initEditFunctionality(true);
                    instance.initShareArticleButton();
                    $(document).trigger("loader:off");
                } else {
                    // offer has been updated so the current container has to be replaced with the new one-
                    let offer = await instance.productHandler.getOfferDetails(productOffer.offerId, false);
                    let tOffer = instance.productHandler._transformOffer(offer);
                    let offerHtml = instance.productHandler.buildSingleItem(tOffer, 21112);
                    let offerContainer = instance.productSection.find('.item[data-offer-id=' + productOffer.offerId + ']');
                    offerContainer.replaceWith($(offerHtml));
                    instance.initEditFunctionality(true);
                    instance.initShareArticleButton();
                    $(document).trigger("loader:off");
                }
            })
            .catch(function(response) {
                $(document).trigger("loader:off");
                if (!response || !response.status) {
                    instance.log.error('Fehler beim Senden der Daten.', response);
                } else if (response.status == 400) {
                    instance._processFieldErrorsFromServer(response);
                    M.toast({html: i18next.t('toasts.data_invalid')});
                } else if (response.status == 403) {
                    M.toast({html: i18next.t('toasts.no_access')});
                } else {
                    instance.log.error('Fehler beim Verarbeiten der Daten durch den Server.', response.status, response.statusText);
                    M.toast({html: i18next.t('toasts.error')});
                }
            });
        });

        $('#save-offers-csv').on('click', (e) => {
            e.preventDefault();
            e.stopImmediatePropagation();

            // remove any link of previous upload's problems
            $('#save-offers-csv-wrapper a').remove();

            const file = $('#offer-batch-upload').get(0).files[0];

            instance.productHandler.uploadOfferBatch(file)
            .then(async function() {
                $('#product-upload-wrapper').removeClass('active');
                M.toast({html: i18next.t('toasts.data_updated')});
            })
            .catch(function(error) {
                M.toast({html: i18next.t('toasts.' + error.code)});

                if (!!error.details) {
                    var detailsBlob = new Blob([error.details], {
                        type: 'text/plain'
                    });
                    var urlCreator = window.URL || window.webkitURL;
                    var problemsUrl = urlCreator.createObjectURL(detailsBlob);

                    $('#save-offers-csv-wrapper').append('<a href="' + problemsUrl + '" target="_blank">Probleme ansehen</a>');
                }
            }).finally(async function() {
                let fileInputControl = $('#offer-batch-upload');
                $('#offer-batch-upload-text').val('');
                fileInputControl.replaceWith(fileInputControl.val('').clone(true));

                // reload offer list to show new offers
                var uid = await instance.authenticator.getUser();
                instance.searchProductsForUser(uid);
            });
        });

        $('#close-prod-modal > a').on('click', () => {
            $('#product-upload-wrapper').removeClass('active');
            instance._emptyOfferForm();
        });
    }

    async _transferOfferDataToForm(productContainer) {
        var instance = this;                                

        let offerId = $(productContainer).closest('.item').attr('data-offer-id');
        let offer = await instance.productHandler.getOfferDetails(offerId);

        $('#edit-prod-offerId').val(offer.id);
        $('#edit-prod-shortName').val(offer.product.shortName);
        $('#edit-prod-price').val(offer.price);
        $('#edit-prod-imageUrl').val(offer.product.imageUrl);
        $('#edit-prod-detailsUrl').val(offer.product.detailsUrl);
        $('#edit-prod-shortDescription').val(offer.product.shortDescription);

        M.updateTextFields();
        M.textareaAutoResize($('textarea#edit-prod-shortDescription'));
        $('textarea#edit-prod-shortDescription').characterCounter();

        if ($('#csv-prod-template').hasClass('active')) {
            $('#product-upload-wrapper .new-object-form').toggleClass('active');
        }

        instance.productSection.find('#product-upload-mode-select').val('manual-add');
    }

    _emptyOfferForm() {
        const instance = this;
        let inputElements = instance.productSection.find('#edit-prod-form input, #edit-prod-form textarea');

        inputElements.each(function(index, element) {
            let formElement = $(element);
            formElement.removeClass("valid invalid").css("height", "").val(null);
            M.updateTextFields();
        });
    }

    _processFieldErrorsFromServer(response) {
        let instance = this;
        let errors = JSON.parse(response.responseText).errors;
        let productWrapper = this.productSection.find('#product-upload-wrapper');

        for (let item in errors) {
            if(!errors.hasOwnProperty(item)) continue;

            if (errors[item] == 'MISSING') {
                productWrapper.find('#edit-prod-' + item).addClass('invalid');
            } else if (errors[item] == 'INVALID') {
                productWrapper.find('#edit-prod-' + item).addClass('invalid');
            } else {
                instance.log.error('Encountered unknown validation error type: ', errors[item]);
            }
        }
    }

    /**
    * Creates the actual content of the article overview for experts, which displays all articles
    * as a kind of a list.
    * To show just products belonging to a user Id i.e. my favorites, select products as defined in variable productArray by handing over a referencing id, *else get entire products array as defined in variable products
    */
    async createProductSearchContainer(id) {
        var instance = this;
        
        $(document).trigger("loader:on", ['', 'transparent', document.getElementById('target8')]);
        $('.product-search-result-info').remove();

        var container = $('#target8 .product-wrapper');
        container.empty();
        
        try {
            let offerList = await instance.productHandler.getOfferList(id);
            let articlesHtmlArray = [];
            let articlesHtml = '';

            if (offerList && offerList.size > 0) {
                for (let index in offerList) {

                    if (offerList.hasOwnProperty(index) && (index != 'size') && (index != 'productsRetrievalTime')) {
                        let article = instance.productHandler._transformOffer(offerList[index]);
                        articlesHtmlArray.push(article);
                    }
                }

                //reverse() shows newest articles first
                articlesHtmlArray.reverse().forEach((item) => {
                    articlesHtml += instance.productHandler.buildSingleItem(item, 21112);
                });

                container.append(articlesHtml);

            } else {
                let info = "<span id='product-search-result-info' class='product-search-result-info' >Shop wurde nicht gefunden</span>";
                !$('#product-search-result-info').length ? $('#search-bar-wrapper').after(info) : '';
            }

            instance.initShareArticleButton();
            var containerEditBtnTooltipped = container.find('.product-edit-btn');
            var containerPriceBtnTooltipped = container.find('.item__price.btn');

            M.Tooltip.init(containerEditBtnTooltipped, instance.utils.addToolTipWithArrow(containerEditBtnTooltipped));
            M.Tooltip.init(containerPriceBtnTooltipped, instance.utils.addToolTipWithArrow(containerPriceBtnTooltipped));

        } catch (error) {
            instance.log.debug('Product list could not be retrieved.', error);
            // TODO react with regard to error code
            let info = "<span id='product-search-result-info' class='product-search-result-info'>Keine Produkte gefunden</span>";
            !$('#product-search-result-info').length ? $('#search-bar-wrapper').after(info) : '';
            
        } finally {
            instance.initEditFunctionality();
            $(document).trigger("loader:off");
        }
    }
}
