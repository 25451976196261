/* global monstecLib */
/* global M */

export default class AbstractOfferSection {
    constructor() {
    }

    /**
    *initiates the item forward to customer and shows item in container of customer triggered by experts
    */
    initShareArticleButton() {
        var instance = this;        

        $('.item').on('click', function (e) {
            if ($(e.target).hasClass('item__price')
                    || $(e.target).hasClass('copy-to-chat-btn')
                    || $(e.target).hasClass('buy-btn')
                    || $(e.target).hasClass('instant-buy-btn')
                    || $(e.target).hasClass('material-icons')) {
                e.preventDefault();
            }
        });

        $('.item__price').off().on('click', function () {
            var itemMtin = $(this).closest('.item').attr('data-mtin');
            // send article to customer
            // customer must get information - post article in xpert chat, empty container + article in container, change color of buy button until clicked
            $('.sidenav.right_menu').sidenav('open');
            $('.collapsible.right-menu-collapsible').collapsible('open', 1);
            $('.share-item.btn').removeClass('disabled').attr('data-mtin', itemMtin);

            //add Eventlistener for share-btn / row to open corresponding chat on sending products
            var correspondingTableRow = $(".chat-overview-collapsible-body table tr");
            correspondingTableRow.each(function() {
                var chatId = $(this).attr('data-chatid');
                
                instance.portalSupport.slideInChatContainer($(this), chatId);
            });
        });

        $('.share-item.btn').off().on('click', function() {
            var MTIN = $(this).attr('data-mtin');
            var chatId = $(this).closest('tr').attr('data-chatid');
            var correspondingChatlog = $(".cl[data-chatid=" + chatId + "] .chatlog.conversation-card");

            //close sidenav on mobile view
            if(window.matchMedia("(max-width: 991px)").matches){
                $('.sidenav.right_menu').sidenav('close');
            }

            $(this).addClass('disabled').removeAttr('data-mtin').removeData('mtin');

            let message = {};
            message.text = 'MTIN' + MTIN;
            message.timestamp = new Date().toDateString() + ' ' + new Date().toLocaleTimeString();

            instance.chatClient.sendMessage('MTIN' + MTIN, chatId);
            instance.chatSupport.addTextMessageToChatLog('chat xpert', null, message, correspondingChatlog);
        });
    }
}
