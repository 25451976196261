/* global monstecLib */
/* global M */
import AbstractOfferSection from '../xpert/abstractoffersection';

export default class ProductOfferContainer extends AbstractOfferSection {
    constructor() {
        super();
        this.chatClient = monstecLib.produckContext.chatClient;
        this.identityService = monstecLib.produckContext.identityService;
        this.productHandler = monstecLib.produckContext.productHandler;
        this.portalSupport = monstecLib.produckContext.portalSupport;
        this.pretext = new monstecLib.PredefinedText();


        this.log = new monstecLib.Log(1);
    }

    attachTo(rootElementId) {
        let instance = this;
        instance.rootElement = $('#' + rootElementId);
    }

    async initProdContainer() {
        var articleOverview = document.getElementById('customer-chat-offer-container');
        $(document).trigger("loader:on", ['', '', document.getElementById('customer-chat-offer-container')]);

        const fillWithAmznArticles = function() {

            let amazonIcon = '<i class="fab fa-amazon"></i>';
            let mergedArticleString = '';
                        
            const stringBuilderCallback = function(array) {

                array.forEach((articleObj) => {
                    let string = productHandler.buildSingleItem(articleObj, 2111, amazonIcon);
                    mergedArticleString += string;
                });

                $('.items-wrap.article-overview').empty().append(mergedArticleString);
            }

            instance.productHandler.callAmznProd(9, stringBuilderCallback);
        }

        if (!$('.items-wrap .item.active').length) {
            var instance = this;
            var providerId = $('.cl.active').data('providerid');
            this.log.debug('PROVIDERID', providerId);
            var id = (!!providerId) ? providerId : $('.cl.active').data('chatpartner');
            this.log.debug('ID', id);

            //if (id !== undefined && instance.productArray[id] && instance.productArray[id].article.length > 0) {
            if (id !== undefined) {
                let offerList;
                try {
                    offerList = await instance.productHandler.getOfferList(id);
                    this.log.debug('initProdContainer - OFFERLIST: ', offerList); //REMOVE
                } catch (e) {
                    if (e.status != 404) {
                        this.log.error("Offer list could not be loaded, server returned ", e);
                    }

                    fillWithAmznArticles();
                    $(document).trigger("loader:off");
                    return;
                }

                let selPackages = await instance.productHandler.getBundle(id);
                this.log.debug('initProdContainer - SELPACKAGE: ', selPackages);  //REMOVE
                let itemStringArray = [];
                let itemString = '';

                for (let index in offerList) {
                    if (offerList.hasOwnProperty(index) && (index != 'size') && (index != 'productsRetrievalTime')) {
                        this.log.debug('initProdContainer - INDEX: ', index); // REMOVE
                        let article = instance.productHandler._transformOffer(offerList[index]);
                        itemStringArray.push(article);
                    }
                }

                //reverse() shows newest articles first
                itemStringArray.reverse().forEach((item) => {
                    itemString += instance.productHandler.buildSingleItem(item, selPackages);
                });

                $('.items-wrap.article-overview').empty().append(itemString);
                instance.initCopyTrigger();

                if (id && parseInt(selPackages) === 2311) {
                    try {
                        let paypalId = await instance.identityService.getPaypalClientId(id);
                        instance.productHandler._buttonAdder($(".items-wrap .item.active"), paypalId);
                    } catch (e) {
                        instance.log.error("initProdContainer - Could not initialise paypal button.", e);
                    }
                }
                
                $(document).trigger("loader:off");
            } else {
                fillWithAmznArticles();
                $(document).trigger("loader:off");
            }
        }
    }

    initCopyTrigger() {
        var instance = this;

        $('.copy-to-chat-btn').click(function(e) {
            e.stopImmediatePropagation();
            e.preventDefault();
            var chatlog = $(".cl.active .chatlog.conversation-card");
            var itemMtin = $(this).closest('.item').data('mtin');
            //this is the message and the trigger to show product for chatpartner
            instance.chatClient.sendMessage('MTIN' + itemMtin);             
            instance.pretext.autoTextEvent("Dein Interesse an Artikel MTIN" + itemMtin + " habe ich an den Experten weitergeleitet.", chatlog, 'robot-msg product-msg');
            instance.productHandler.transformMTINS($('.cl.active .chatlog.conversation-card'));
        });
    }

    /** This function handles the product and cart container underneath the chat
    * @param {*} prodArray
    * @param {*} MTIN
    * @param {*} selPackages
    */
    addItem(prodArray, MTIN, selPackages) {
        var instance = this;

        //show articles in users' container
        var buttonCode;
        if (parseInt(selPackages) === 2221 || parseInt(selPackages) === 2211 ) {
            buttonCode = 2211;
        } else {
            buttonCode = 1112;
        }

        //var articleContainer = $('.items-wrap.article-overview');
        //var cartContainer = $('.items-wrap.cart-overview');
        //if (articleContainer) {
        //    var articleContainerString = instance._createArticleForContainer(prodArray, [MTIN], buttonCode);
        //    articleContainer.find(".item.active[data-mtin=" + MTIN + "]").remove(); // remove duplicates
        //    cartContainer.find(".item.active[data-mtin=" + MTIN + "]").remove();
        //    articleContainer.prepend(articleContainerString);
        //    cartContainer.prepend(articleContainerString);
        //    super.initShareArticleButton(prodArray);
        //}
    }

    //builds the string for the set of items, that are shown in container
    _createArticleForContainer(offerArray, articleSelection, selPackages) {
        var instance = this;
        let itemArray = [];
        var itemString = '';

        for (let i = 0; i < articleSelection.length; i++) {
            let article = instance.productHandler._transformOffer(offerArray[articleSelection[i]]);
            itemArray.push(article);
        }

        //reverse() shows newest articles first
        itemArray.reverse().forEach((item) => {
            itemString += instance.productHandler.buildSingleItem(item, selPackages);
        });

        return itemString;
    }
}
