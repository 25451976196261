/* global monstecLib */

export default class Setup {
    constructor() {
        this.initPolyfills();

        this.serviceSupport = new monstecLib.ServiceSupport();
        this.serviceSupport.supportBinary();
    }

    initPolyfills() {
        console.log('Initialising polyfills...');
        if (!Math.cbrt) {
            Math.cbrt = (function(pow) {
                return function cbrt(x){
                    // ensure negative numbers remain negative:
                    return x < 0 ? -pow(-x, 1/3) : pow(x, 1/3);
                };
            })(Math.pow); // localize Math.pow to increase efficiency
        }
    }
}