/* global monstecLib */
/* global M */

/**
 * Page class that allows administrators to check the health status of the produck services
 */
export default class ServiceHealthCheckPage {
    constructor() {
        const instance = this;
        instance.constants = new monstecLib.Constants();
        instance.cookie = new monstecLib.Cookie();
        instance.authenticator = new monstecLib.Authenticator(instance.cookie);
        instance.identityService = new monstecLib.IdentityServiceAccess(instance.authenticator);
        instance.chatService = new monstecLib.ChatServiceAccess(instance.authenticator);
        instance.billingService = new monstecLib.BillingServiceAccess(instance.authenticator);
        instance.catalogueService = new monstecLib.CatalogueServiceAccess(instance.authenticator);
        instance.utils = new monstecLib.Utils();

        let context = monstecLib.produckContext;
        context.setBean('utils', this.utils);
        context.setBean('authenticator', this.authenticator);
        context.setBean('identityService', this.identityService);
        context.setBean('chatService', this.chatService);
        context.setBean('cookie', this.cookie);

        monstecLib.produckContext.initialiseRegisteredBeans();

        instance.log = new monstecLib.Log(1);

        // Check if the current user has the permission to upgrade his account. If he doesn't, redirect him.
        instance.authenticator.checkPermission(instance.authenticator.permCat.CHECK_SERVICE_HEALTH)
        .then(async function(hasPermission) {
            if (hasPermission) {
                await instance._initialise();
            } else {
                instance.log.debug('Authenticated user does not have the permission to check service health.');
                window.location.href = "/";
            }
        })
        .catch(function(e){
            // Page could not be initialised. This can happen for example when no user is authenticated at all.
            instance.log.debug('Initialisation failed.', e);
            window.location.href = "/";
        });
    }

    /**
     * Initialises the page so that it looks like intended.
     */
    async _initialise() {
        const instance = this;

        let checkService = function(service, containerId) {
            let tile = $('#' + containerId);
            let start = performance.now();
            let result = service.checkHealth();

            result.then(function(data) {
                instance.log.debug(`Health data for [${containerId}]: `, data);

                let latency = performance.now() - start;
                if (latency < 2000) {
                    tile.css('background-color', 'green');
                    tile.find('.js-status').text('');
                    tile.find('.js-time').text('');
                } else {
                    tile.css('background-color', 'yellow');
                    tile.find('.js-status').text('');
                    tile.find('.js-time').text(latency + ' ms');
                }
            })
            .catch(function(xhr) {
                if (xhr.status == 0) {
                    instance.log.error('Request could not be performed: ', xhr);
                }

                tile.css('background-color', 'red');
                tile.find('.js-status').text((xhr.status == 0) ? 'Error' : xhr.status);
                tile.find('.js-time').text('');
            });
        };

        let checkInterval = 30000;

        checkService(instance.identityService, 'identityServiceStatusContainer');
        window.setInterval(checkService.bind(instance, instance.identityService, 'identityServiceStatusContainer'), checkInterval);

        window.setTimeout(function() {
            checkService(instance.billingService, 'billingServiceStatusContainer');
            window.setInterval(checkService.bind(instance, instance.billingService, 'billingServiceStatusContainer'), checkInterval);
        }, 5000);

        window.setTimeout(function() {
            checkService(instance.chatService, 'chatServiceStatusContainer');
            window.setInterval(checkService.bind(instance, instance.chatService, 'chatServiceStatusContainer'), checkInterval);
        }, 10000);

        window.setTimeout(function() {
            checkService(instance.catalogueService, 'catalogueServiceStatusContainer');
            window.setInterval(checkService.bind(instance, instance.catalogueService, 'catalogueServiceStatusContainer'), checkInterval);
        }, 15000);
    }
}