import SockJs from 'sockjs-client';

const HEARTBEAT_CLIENT_REQUEST = 'hcr';
const HEARTBEAT_SERVER_ACK = 'hsa';
const AUTHENTICATION_REQUEST = 'atr';
const AUTHENTICATION_REQUEST_ANSWER = 'ata';
const REGISTER_FOR_ALL_INDICATOR = '#all';
const CONNECTION_CHECK_INTERVAL = 3000; // time between connection checks
const HEARTBEAT_COUNTER_LIMIT = 3;
const RETRY_SLOWDOWN_LIMIT = 10;
const SLOW_RETRY_INTERVAL = 2000; // time between retries of connecting to websocket-server after limit is reached
const SAFETY_NET_COUNTER_LIMIT = 10;
const MESSAGE_QUEUE_PROCESSING_INTERVAL = 500;
const SOCKJS_STATUS_OPEN = 1;
const AUTHENTICATION_TIMEOUT = 10000;

/**
 * SockJS client wrapper that sees to a constant connection to the ProDuck Websocket Gateway.
 *
 * Provided callbacks are:
 *  - onOpen(message): called when the websocket connection has been established successfully.
 *  - onError(error): called when the websocket connection could not be established.
 *  - onReconnect(message): called additionally to onOpen when a new websocket connection has been established due to a reconnect
 *
 * Hint: methods starting with an underscore are considered to be 'private' methods.
 */
export default class WebSocketClient {

    constructor(endpoint, externalAuthenticator) {
        this.wsEndpoint = endpoint; // save the endpoint for later reconnect
        this.authenticator = externalAuthenticator;

        this.serverSessionId = undefined;
        this.heartbeatCounter = 0;
        this.retryCount = 0;
        this.safetyNetLastRetryCount = 0;
        this.safetyNetCounter = 0;
        this.messageQueue = [];
        this.consumers = {};

        this.checkInterval = null;
        this.resendInterval = null;
        this.reconnectTimeout = null;
        this.connectionDeferred = null;
        this.authenticationDeferred = null;
        this.isAuthenticated = false;
    }

    /**
     * (Re-)Authenticate the currently active websocket connection.
     *
     * @param {boolean} retry set this to true if the authentication process should be retried in case of a failure
     */
   async authenticate(retry = true) {
        console.log('Websocket client will authenticate user with the websocket chat server.');
        var instance = this;
        instance.authenticationDeferred = $.Deferred();

        try {
            var accessToken = await instance.authenticator.getAccessToken(true);
            instance.sendMessage(JSON.stringify({"type":AUTHENTICATION_REQUEST, "token":accessToken}));

            var timeoutHandler = function() {
                if (instance.authenticationDeferred && instance.authenticationDeferred.state === 'pending') {
                    console.log('Websocket authentication has timed out!');
                    instance.authenticationDeferred.reject({'status':'timeout'});
                    instance.authenticationDeferred = null;
                }
            };

            window.setTimeout(timeoutHandler, AUTHENTICATION_TIMEOUT);

        } catch (error) {
            // The retrieval of the access token from the authenticator failed. Of course it does not make
            // sense to start the authentication procedure in this case. Reject the authenticationDeferred's
            // promise here so that below a refresh of the access token will take place.
            instance.authenticationDeferred.reject({'status':'no-access-token'});
        }

        let userRole = await instance.authenticator.getUserRole();

        return instance.authenticationDeferred.promise()
            .then(function(payload) {
                if (!!payload.id) {
                    instance.serverSessionId = payload.id;
                }

                if (instance.onAuthentication) {
                    instance.onAuthentication(payload);
                }
            })
            .catch(function(response) {
                function reactOnExpiredAuthentication() {
                    $(document).trigger("loader:off");
                    clearTimeout(instance.reconnectTimeout);
                    clearInterval(instance.checkInterval);
                    clearInterval(instance.resendInterval);

                    if (userRole === 'ANONYMOUS' || userRole === 'TEMPORARY') {
                        instance.authenticator.clearAuthenticationData();
                        // Anonymous and temporary users do not have credentials known to them. So it does not make sense to
                        // lead them to the login page. Instead check if they are already on the index-page and if not lead
                        // them there.
                        if (window.location.href.indexOf('index') < 0) {
                            window.location.href = '/index.html';
                        }
                    } else {
                        instance.authenticator.promptForRelogin();
                    }
                }

                if (response.status === 'failed') {
                    if (retry) {
                        // Retry once in case of the unlikely case that the access token became invalid during the
                        // travel of the request to the server.
                        return instance.authenticate(false);
                    } else {
                        reactOnExpiredAuthentication();
                    }
                } else if (response.status === 'timeout') {
                    // In case of a timeout there is no information whether or not the access token is valid. The user
                    // could just be in a train and riding through a dead zone, so keep trying.
                    console.log ('Authentication request timed out, retrying...');
                    return instance.authenticate();
                } else {
                    // so the status is either 'failed' but doRefresh is false or the something happend
                    // that was unexpected when this code was written
                    console.log('Authentication failed, but no refresh will be performed: auth-status:', response.status);
                    reactOnExpiredAuthentication();
                }
            });
    }

    isSessionAuthenticated() {
        return this.isAuthenticated;
    }

    /**
     * Connect to the websocket server and start reconnection mechanism.
     */
    connect() {
        console.log('WebsocketClient - establishing connection');

        // Start rechecking the connection, rechecking is done for the situation when onclose did not
        // work properly or there is an ongoing connection problem.
        if (!this.checkInterval) {
            this.checkInterval = setInterval(this._checkConnection.bind(this), CONNECTION_CHECK_INTERVAL);
        }

        // Start checking of message queue to resend messages that could not be sent during the initial
        // try or during the last resend process.
        if (!this.resendInterval) {
            this.resendInterval = setInterval(this._resendMessages.bind(this), MESSAGE_QUEUE_PROCESSING_INTERVAL);
        }

        return this._wsConnect(); // create the websocket connection
    }

    /**
     * Disconnect websocket connection and stop all interval-based checks.
     */
    disconnect() {
        console.log('WebsocketClient - disconnecting');
        // stop interval based executions
        clearInterval(this.checkInterval);
        clearInterval(this.resendInterval);
        // clear reconnect timeout
        clearTimeout(this.reconnectTimeout);

        this.isAuthenticated = false;
        this.serverSessionId = undefined;

        if (this.wsSocket) {
            // reset the onlcose-handler so that the connection will not automatically be reestablished
            this.wsSocket.onclose = () => {};

            // suppress errors that may be caused by the disconnect
            this.wsSocket.onerror = function (error) {
                error.stopPropagation();
            };

            // finally close the websocket-connection
            this.wsSocket.close();
            this.wsSocket = null;
        }
    }

    _wsConnect() {
        var instance = this;
        // only create new socket on endpoint if it didnt exist yet
        if (instance.wsSocket == null) {
            console.log('Creating a new SockJS-connection to endpoint ' + instance.wsEndpoint + '.');
            instance.wsSocket = new SockJs(instance.wsEndpoint);

            instance.connectionDeferred = $.Deferred();
        }

        instance.wsSocket.onerror = (error) => { instance._onerrorHandler(error); };
        instance.wsSocket.onopen = (message) => { instance._onopenHandler(message); };
        instance.wsSocket.onclose = (message) => { instance._oncloseHandler(message); };
        instance.wsSocket.onmessage = (message) => { instance._messageHandler(message); };

        return instance.connectionDeferred.promise().then(function() {
            console.log('Websocket-connection established.');
            return instance.authenticate();
        });
    }

    /*
     * Resets all counters.
     */
    _onopenHandler(message) {
        console.log('WebSocketClient - opened session: ' + JSON.stringify(message));
        var isReconnect = this.retryCount > 0;
        this.heartbeatCounter = 0;
        this.retryCount = 0;

        if (this.connectionDeferred) {
            this.connectionDeferred.resolve();
        }

        if (isReconnect) {
            if (this.onReconnect) {
                this.onReconnect(message);
            }
        } else {
            if (this.onOpen) {
                this.onOpen(message);
            }
        }
    }

    /*
     * Delegates custom error handling to the SockJS connection.
     */
    _onerrorHandler(error) {
        console.log("WebSocketClient - SockJS-error: ", error);
        if (this.onError) {
            this.onError(error);
        }
    }

    /**
     * The oncloseHandler will handle the situation when the websocket connection has been lost. This will either
     * be done when the websocket sends an onclose-event or when the heartbeat-mechanism detects a connection failure.
     */
    _oncloseHandler(message) {
        console.log('WebSocketClient - closing session: ' + JSON.stringify(message));
        this.wsSocket = null;
        this.isAuthenticated = false;
        this.serverSessionId = undefined;
        this.retryCount++;

        // When the websocket connection gets closed, try to reconnect. A failure when connecting using _wsConnect will
        // trigger a onclose-Event and so this method again.
        // Delay the retry a bit, otherwise a page reload will trigger a reconnect try just before leaving
        // the page which will lead to a connection establishment that will immediately be aborted and cause an exception
        // at both, the client and the server.
        var delay = 500;
        if (this.retryCount > RETRY_SLOWDOWN_LIMIT) {
            // delay even longer if a certain number of retries already took place to save resources
            delay += SLOW_RETRY_INTERVAL;
        }

        if (this.onClose) {
            this.onClose(message);
        }

        this.reconnectTimeout = window.setTimeout(this._wsConnect.bind(this), delay);
    }

    /**
     * Handler for incoming messages via the SockJS connection.
     */
    _messageHandler(message) {
        var payload = JSON.parse(message.data);
        //console.log('Received message of type "' + payload.type + '": ', message);

        if (payload.type === HEARTBEAT_SERVER_ACK) {
            this.heartbeatCounter = 0;

            let chatStates = payload.chatStates;
            for (let chatId in chatStates) {
                if (chatStates.hasOwnProperty(chatId)) {
                    let chatlog = $(".cl[data-chatid=" + chatId + "]");

                    if (chatlog) {
                        let signal = $('#chat-online-signal' + chatId + ' .inner-circle');
                        let chatPartnerId = chatlog.attr('data-chatpartner');

                        if (chatStates[chatId].indexOf(Number(chatPartnerId)) >= 0) {
                            signal.removeClass('signal-offline')
                                .addClass(() => ((!signal.hasClass('signal-online')) ? 'signal-online' : ''))
                                .attr("title", () => ((signal.hasClass('signal-online')) ? 'online' : ''));
                        } else {
                            signal.removeClass('signal-online')
                                .addClass(() => ((!signal.hasClass('signal-offline')) ? 'signal-offline' : ''))
                                .attr("title", () => ((signal.hasClass('signal-offline')) ? 'offline' : ''));
                        }
                    }
                }
            }

        } else if (payload.type === AUTHENTICATION_REQUEST_ANSWER) {
            // When reveiving this message type a Deferred object is necessary to successfully acknowledge
            // the authentication. In certain cases (double clicks etc.) Several authentication requests might
            // have been made, but there is only one 'authenticationDeferred'. This should not be a problem and
            // so first check if the session is already authenticated by a previous request.
            if (!this.isSessionAuthenticated() || this.authenticationDeferred) {
                if (payload.status === 'success') {
                    this.isAuthenticated = true;
                    console.log('Authentication with access token successful.');
                    this.authenticationDeferred.resolve(payload);
                } else {
                    this.isAuthenticated = false;
                    this.authenticationDeferred.reject({'status':'failed'});
                }

                this.authenticationDeferred = null;
            } else {
                console.log('ERROR: Received AUTHENTICATION_REQUEST_ANSWER but there is no authenticationDeferred.');
            }

        } else {
            var consumersToProcess = [];

            if (this.consumers[payload.type]) {
                consumersToProcess = consumersToProcess.concat(this.consumers[payload.type]);
            }

            if (this.consumers[REGISTER_FOR_ALL_INDICATOR]) {
                consumersToProcess = consumersToProcess.concat(this.consumers[REGISTER_FOR_ALL_INDICATOR]);
            }

            if (consumersToProcess.length === 0) {
                console.log('WARNING - No consumer registered for [' + payload.type + '], message will be discarded.');
            } else {
                consumersToProcess.forEach(function(consumer) {
                    consumer.onWebSocketMessage(payload);
                }.bind(this));
            }
        }
    }

    _checkConnection() {
        if (this.retryCount > 0) {
            // The reonnection process is already running so there is no need to trigger it of send
            // a heartbeat request except for when the retry-mechanism got stuck. In this case the
            // safety net mechanism is intended to fix things. It is yet another counter that counts the
            // number of times this method has been called, since the retryCounter last changed.
            if (this.retryCount > this.safetyNetLastRetryCount) {
                this.safetyNetLastRetryCount = this.retryCount;
            } else {
                this.safetyNetCounter++;
            }

            // If the safetyNetCounter reaches the limit, try to trigger an onclose-event
            if (this.safetyNetCounter >= SAFETY_NET_COUNTER_LIMIT) {
                this.safetyNetLastRetryCount = 0;
                this.safetyNetCounter = 0;

                console.log('Saftey net triggered, reconnecting...');
                if (this.wsSocket)  {
                    this.wsSocket.close();
                } else {
                    this._wsConnect();
                }
            }

            return;
        }

        // The heartbeatCounter will be increased for every call to this method. Since it is not possible
        // to synchronously wait for the arrival of the ACK without blocking everything else this counter-
        // based approach is used. When the limit is exceeded the connection is considered to be broken.
        // A reconnection-try will be executed then and the old SockJS-connection-object will be discarded.
        if (this.heartbeatCounter > HEARTBEAT_COUNTER_LIMIT) {
            // The following call to the close-method of the wrapped socket-object will trigger onclose-event
            // and such a receonnect in the corresponding handler.
            console.log('Heartbeat counter reached the limit, reconnecting...');
            if (this.wsSocket) {
                this.wsSocket.close();
            } else {
                this._wsConnect();
            }
        }

        var message = { "type": HEARTBEAT_CLIENT_REQUEST };
        this.heartbeatCounter++;

        this.sendMessage(JSON.stringify(message));
    }

    _resendMessages() {
        if (this.isConnected() && this.messageQueue.length > 0) {
            console.log('Starting resend cycle.', this.messageQueue);
            var resendQueue = this.messageQueue;
            this.messageQueue = [];

            resendQueue.forEach(function(payloadString) {
                var payload = JSON.parse(payloadString);
                if (this.isSessionAuthenticated() || payload.type === AUTHENTICATION_REQUEST) {
                    this.sendMessage(payloadString);
                }
            }.bind(this));
        }
    }

    /**
     * Send a message over the internal connection framework.
     *
     * @param {*} payloadString message payload in JSON format as string
     */
    sendMessage(payloadString) {
        // If the websocket connection is currently unavailable, queue the message, otherwise just send it.
        var payload = JSON.parse(payloadString);
        if (this.isConnected() && (this.isSessionAuthenticated() || payload.type === AUTHENTICATION_REQUEST)) {
            this.wsSocket.send(payloadString);
        } else {
            //never queue heartbeat requests
            if (payload.type !== HEARTBEAT_CLIENT_REQUEST) {
                console.log('Adding message to queue, because websocket connection is currently unavailable or unauthenticated. Message: ', payloadString);
                this.messageQueue.push(payloadString);
            }
        }
    }

    isConnected() {
        return this.wsSocket && this.wsSocket.readyState === SOCKJS_STATUS_OPEN;
    }

    /**
     * Register a message consumer with this client. Consumers will get messages they have registered for.
     * This may be 'all' or any subset of message types given as string array. Consumers cann not register
     * for heartbeat messages. IF a consumer wants to register for all messages, simply leave out the second
     * parameter.
     * A Consumer must provide the function 'onWebSocketMessage' which will be called on message arrival with one
     * parameter which will contain a JSON-formatted payload.
     *
     * @param {*} consumer the object that acts as consumer which must provide an 'onWebSocketMessage'-function
     * @param {*} msg the message type(s) to register for; may be a string or an array of strings
     */
    registerConsumer(consumer, msg) {
        if (!consumer || !consumer.onWebSocketMessage) {
            throw 'Illegal argument! consumer empty or consumer does not provide "onWebSocketMessage".';
        }

        var registerFor = [];
        if (!msg) {
            registerFor.push(REGISTER_FOR_ALL_INDICATOR);
        } else if (typeof type === 'string') {
            registerFor.push(msg);
        } else if (Array.isArray(msg)) {
            msg.forEach(function(type) {
                if (typeof type === 'string') {
                    registerFor.push(type);
                }
            });
        } else {
            throw 'Illegal argument! "msg" must be a string or an array of strings.';
        }

        registerFor.forEach(function(type) {
            if (!this.consumers[type]) {
                this.consumers[type] = [];
            }

            console.log('Registering consumer for message type "' + type + '"');
            this.consumers[type].push(consumer);
        }.bind(this));
    }
}
