/* global monstecLib */
/* global M */

export default class ArticleTemplateModal {
    /**
     * Construct a new form that can then be attached to an element.
     */
    constructor(htmlId) {
        this.id = htmlId;

        this.MIN_TEMPLATE_NAME = 10;
        this.MAX_TEMPLATE_NAME = 100;
        this.MIN_CRITERION_NAME = 10;
        this.MAX_CRITERION_NAME = 100;
        this.MIN_SUB_CRITERION_NAME = 10;
        this.MAX_SUB_CRITERION_NAME = 100;

        this.i18next = monstecLib.i18next;
        this.utils = monstecLib.produckContext.utils;
        this.authenticator = monstecLib.produckContext.authenticator;
        this.chatService = monstecLib.produckContext.chatService;

        function addTag(chipsElement) {
            let textNodeOfNewChip = chipsElement.find('.chip').last()[0].childNodes[0];
            let textOfNewChip = textNodeOfNewChip.data;
            textNodeOfNewChip.data = textOfNewChip.trim().replaceAll(/[^a-zA-Z0-9äöüÄÖÜß\.-]+/g, '-');
        }

        this.chipsOptions = {
            'placeholder': this.i18next.t('toasts.chips'),
            'limit': 5,
            'onChipAdd': function(chips) {addTag(chips);}
        };

        this.log = new monstecLib.Log(1);
    }

    initialise() {
        const instance = this;
        var modalElement = $('body').find('#' + instance.id);

        if (!modalElement || modalElement.length == 0) {
            modalElement = $(`<div id="${instance.id}" class="modal dynamic-modal signin-modal new-object-form">`
                +   `<div class="modal-content">`
                +     `<h4>${instance.i18next.t('articlesection.template_label')}</h4>`
                +     `<div class="js-feedback modal-feedback">`
                +     `</div>`
                +     `<div class="input-field detail-item col s12 m12 l12 xl12">`
                +       `<input id="${instance.id}-name" type="text" class="js-template-name chat-title-field validate" required="required" minlength="10" maxlength="100"/>`
                +       `<label for="${instance.id}-name">${instance.i18next.t('articlesection.template_name_label')}</label>`
                +     `</div>`
                +     `<div class="input-field detail-item col s12 m12 l12 xl12">`
                +       `<textarea id="${instance.id}-description" class="js-template-description materialize-textarea prdk-textarea" maxlength="4000"></textarea>`
                +       `<label for="${instance.id}-description">${instance.i18next.t('articlesection.template_description_label')}</label>`
                +     `</div>`
                +     `<div class="js-criteria row">`
                +       `<div class="js-criterion">`
                +         `<div class="input-field detail-item col s12 m12 l12 xl12">`
                +           `<input id="${instance.id}-criterion1-name" type="text" class="js-criterion-name validate" required="required" minlength="3" maxlength="100"/>`
                +           `<label for="${instance.id}-criterion1-name" class="mandatory">${this.i18next.t('articlesection.criterion_label')}</label>`
                +         `</div>`
                +         `<div class="input-field detail-item col s12 m12 l12 xl12">`
                +           `<textarea id="${instance.id}-criterion1-subs" class="js-sub-criteria materialize-textarea prdk-textarea" data-value="message" name="query" type="text" maxlength="2000"></textarea>`
                +           `<label for="${instance.id}-criterion1-subs">${this.i18next.t('articlesection.sub_criteria_label')}</label>`
                +         `</div>`
                +       `</div>`
                +     `</div>`
                +     `<div class="input-field detail-item col s12 m12 l12 xl12">`
                +        `<a id="addArticleTemplateCriterionButton" title="${instance.i18next.t('articlesection.element_title_add_criterion')}" class="form-action-button">`
                +          `<span>${instance.i18next.t('articlesection.add_criterion_label')}</span>`
                +        `</a>`
                +     `</div>`
                +     `<div class="input-field detail-item col s12 m12 l12 xl12">`
                +       `<div class="category-tags chips chips-placeholder"></div>`
                +     `</div>`
                +   `</div>`
                +   `<div class="modal-footer">`
                +     `<button class="js-save-button option-one waves-effect waves-teal btn-flat">${instance.i18next.t('general.save')}</button>`
                +     `<button class="js-cancel-button option-two modal-close waves-effect waves-teal btn-flat">${instance.i18next.t('general.cancel')}</button>`
                +   `</div>`
                + `</div>`);

            $('body').append(modalElement);

            instance.utils.addCharacterCounter(modalElement.find('.js-template-description'), undefined, 20, 4000, true)

            // init tag module
            let tags = modalElement.find('.chips');
            instance.tagsInput = M.Chips.init(tags, instance.chipsOptions)[0];

            modalElement.find('#addArticleTemplateCriterionButton').on('click', instance._addCriterion.bind(instance));

            modalElement.find('.js-save-button').on('click', function() {
                $(document).trigger("loader:on");

                let template = instance._collectData();

                let promise;
                let templateId = instance.modalElement.data('templateId');
                if (templateId) {
                    template.id = templateId;
                    promise = instance.chatService.updateArticleTemplate(template);
                } else {
                    promise = instance.chatService.createArticleTemplate(template);
                }

                promise.then(function() {
                    instance._resetModalForm();
                    M.toast({html: instance.i18next.t('articlesection.toast_template_saved')});

                    modalElement.modal('close');

                    if (instance.onSuccess) {
                        // onSuccess may be async so a the promise will be returned to wait until that is resolved
                        // before removing the loader in finally.
                        return instance.onSuccess();
                    }
                })
                .catch(function(xhr) {
                    modalElement.find('.js-feedback').children().remove();

                    if (xhr.status === 400) {
                        let feedbackPane = $('<ul></ul>');
                        modalElement.find('.js-feedback').append(feedbackPane);

                        if (xhr && xhr.response) {
                            try {
                                let response = JSON.parse(xhr.response);
                                for (let error in response.errors) {
                                    if (!response.errors.hasOwnProperty(error)) continue;
                                    feedbackPane.append('<li>' + instance.i18next.t(`articlesection.validation_error_template_${error}_${response.errors[error]}`) + '</li>');
                                }

                            } catch (parseError) {
                                instance.log.error('Error object could not be parsed.', parseError);
                            }
                        }
                    } else if (xhr.status === 409) {
                        modalElement.find('.js-feedback').append('<ul><li>' + instance.i18next.t('articlesection.validation_error_template_name_CONFLICT') + '</li></ul>');
                    }

                    M.toast({html: instance.i18next.t('toasts.error')});
                })
                .finally(function() {
                    $(document).trigger("loader:off");
                });
            });

            modalElement.find('.js-cancel-button').on('click', function() {
                instance._resetModalForm();
            });

            modalElement.modal({
                dismissible: false
            });

            this.modalElement = modalElement;
        }
    }

    /**
     * Shows the modal to the user.
     *
     * @param {*} template optional template object which will be used to prefill the modal's form fields
     */
    open(template) {
        const instance = this;

        if (!instance.modalElement) {
            instance.modalElement = $('#' + instance.id).modal('open');
             let tags = instance.modalElement.find('.chips');
             instance.tagsInput = M.Chips.init(tags, instance.chipsOptions)[0];
        } else {
            instance.modalElement.modal('open');
        }

        if (template) {
            instance._fillModalForm(template);
        }
    }

    _resetModalForm() {
        const instance = this;
        instance.modalElement.removeData('templateId');
        instance.modalElement.find('.js-feedback').children().remove();
        instance.modalElement.find('.js-template-name').val('');
        instance.modalElement.find('.js-template-description').val('');

        let criteriaSection = instance.modalElement.find('.js-criteria');
        criteriaSection.find('.js-criterion-name').val('');
        criteriaSection.find('.js-sub-criteria').val('');
        criteriaSection.children().remove('.js-extra-criterion');

        // re-initialise tags
        instance.tagsInput.destroy();
        let tags = instance.modalElement.find('.chips');
        instance.tagsInput = M.Chips.init(tags, instance.chipsOptions)[0];

        M.updateTextFields();
    }

    _addCriterion(criterion) {
        const instance = this;
        let criteriaSection = instance.modalElement.find('.js-criteria');
        let randomId = Math.floor(Math.random() * 10000000);

        let extraCriterionCode = '<div class="js-criterion js-extra-criterion">'
            +   '<div class="input-field detail-item col s11 m11 l11 xl11">'
            +     `<input id="${instance.id}-criterion${randomId}-name" type="text" class="js-criterion-name validate"  required="required" minlength="3" maxlength="100"/>`
            +     `<label for="${instance.id}-criterion${randomId}-name" class="mandatory">${this.i18next.t('articlesection.criterion_label')}</label>`
            +   '</div>'
            +   '<div class="input-field detail-item col s1 m1 l1 xl1">'
            +     '<a class="js-remove-criterion-button form-action-button"><i class="material-icons">remove_circle_outline</i></a>'
            +   '</div>'
            +   '<div class="input-field detail-item col s12 m12 l12 xl12">'
            +     `<textarea id="${instance.id}-criterion${randomId}-description" class="js-sub-criteria materialize-textarea prdk-textarea" data-value="message" name="query" type="text" maxlength="2000"></textarea>`
            +     `<label for="${instance.id}-criterion${randomId}-description"">${this.i18next.t('articlesection.sub_criteria_label')}</label>`
            +   '</div>'
            + '</div>';

        let extraCriterion = $(extraCriterionCode);
        criteriaSection.append(extraCriterion);

        extraCriterion.find('.js-remove-criterion-button').on('click', function() {
            extraCriterion.remove();
        });

        if (criterion) {
            this._fillCriterionElement(extraCriterion, criterion);
        }

        return extraCriterion;
    }

    _fillModalForm(template) {
        const instance = this;

        instance.modalElement.data('templateId', template.id);

        instance.modalElement.find('.js-template-name').val(template.name);
        instance.modalElement.find('.js-template-description').val(template.description);

        let isFirstCriterion = true;
        if (template.criteria && template.criteria.forEach) {
            template.criteria.forEach(function(criterion) {
                if (isFirstCriterion) {
                    let criteriaSection = instance.modalElement.find('.js-criteria');
                    instance._fillCriterionElement(criteriaSection.first('.js-criterion'), criterion);
                    isFirstCriterion = false;
                } else {
                    instance._addCriterion(criterion);
                }
            });
        }

        // create a data object that is compatible with the Chips-form-element of materializecss
        // it must be an array of the form
        // [{tag: 'tag1'},{tag: 'tag2'}, {tag: 'tag3'}] (or even more than three)
        // the materializecss Chips-form-element will be initialised with this object meaning, that
        // the indluded tags will immediatley been shown and the form-element also adds new tags to
        // the array, whenever the user enters a new tag, or removes tags, whenever the user deletes
        // a tag.
        var tagsArray = [];
        if (template.tagString && template.tagString.length > 1) {
            template.tagString.split('|').forEach(function(templateTag) {
                instance.tagsInput.addChip({tag: templateTag});
            });
        }

        M.updateTextFields();
    }

    _fillCriterionElement(criterionElement, criterion) {
        criterionElement.find('.js-criterion-name').val(criterion.name);

        let subCriteria = criterion.subCriteria;
        let subCriteriaString = '';
        if (subCriteria && subCriteria.forEach && subCriteria.length > 0) {
            let isFirst = true;
            subCriteria.forEach(function(subCriterion) {
                if (!isFirst) {
                    subCriteriaString += ',';
                }
                subCriteriaString += subCriterion.name;
                isFirst = false;
            });
        }

        criterionElement.find('.js-sub-criteria').val(subCriteriaString);
    }

    _collectData() {
        const instance = this;
        let template = {};

        // collect general template data
        template.name = instance.modalElement.find('.js-template-name').val();
        template.description = instance.modalElement.find('.js-template-description').val();

        let tagsData = instance.tagsInput.chipsData;
        if (!!tagsData || Array.isArray(tagsData)) {
            let actualTags = tagsData
                .filter(tuple => !!tuple.tag)
                .map(tuple => tuple.tag.trim().replaceAll(/[^a-zA-Z0-9äöüÄÖÜß\.-]+/g, '-'));

            template.tags = actualTags;
        }

        // extract criteria list
        let criteria = [];
        instance.modalElement.find('.js-criteria').children('.js-criterion').each(function(cIndex, element) {
            let criterion = {};
            let criterionElement = $(element);

            criterion.name = criterionElement.find('.js-criterion-name').val();
            criterion.orderNumber = cIndex;

            let subCriteriaString = criterionElement.find('.js-sub-criteria').val();
            if (subCriteriaString) {
                let subCriteria = [];
                subCriteriaString.split(',').forEach(function(subCriterion, scIndex) {
                    if (subCriterion) {
                        subCriterion = subCriterion.trim();
                        if (subCriterion !== '') {
                            subCriteria.push({'name': subCriterion, 'orderNumber': scIndex});
                        }
                    }
                });

                criterion.subCriteria = subCriteria;
            }

            criteria.push(criterion);
        });

        template.criteria = criteria;
        return template;
    }
}