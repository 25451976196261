/* global monstecLib */
/* global Cookies */

import 'js-cookie';

// transpilation via webpack and babel to ES5 enables us to use ES2015 classes here
export default class Cookie {
    constructor(produckWindowPort) {
        this.constants = new monstecLib.Constants();
        this.produckWindowPort = produckWindowPort;
        this.nextDataExchangeId = 1;
        this.deferredStorage = {};

        if (produckWindowPort) {
            var instance = this;

            instance.produckWindowPort.onmessage = e => {
                console.log('ProDuck - Cookie - Received port connection message: ', e);
                var payload = JSON.parse(e.data);

                if (payload && payload.exchangeId) {
                    let deferred = instance.deferredStorage[payload.exchangeId];
                    if (deferred && payload.cookieData) {
                        let cookieContent = payload.cookieData;
                        // console.log('cookieData for exchangeId %i: %o', payload.exchangeId, cookieContent);
                        deferred.resolve(JSON.parse(cookieContent));
                    } else {
                        // console.log('no cookie (for exchangeId %i) or deferred (%o)', payload.exchangeId, deferred);
                        deferred.reject('no cookie');
                    }

                    delete instance.deferredStorage[payload.exchangeId];
                } else if (payload) {
                    // THIS SHOULD NOT BE HERE! THE WHOLE PORT_MESSAGING HAS TO BE REFACTORED A LOT!
                    // In this case the message is not an answer to a message from this 'side', but a message
                    // that should trigger an unprepared action.
                    if (payload.method === 'info#chat') {
                        this.chatInfoLink = payload.link;
                        this.chatInfoProduct = payload.product;
                    }  else if (payload.method=== 'scroll#chatlog') {
                        var chatlog = $('.cl.active .chatlog');
                        setTimeout(() => {
                            $('#chatlog').animate({scrollTop: chatlog[0].scrollHeight}, 1000);
                        }, 100);
                    } else if (payload.method=== 'open#chatPopUp'){

                        var produck = payload.cookieData.produck,
                            sessAu = payload.cookieData.sessAu,
                            sessRe = payload.cookieData.sessRe;

                        instance.saveToRightStorage('produck', JSON.parse(produck), 30);
                        instance.saveToRightStorage('sess_au', JSON.parse(sessAu), 0.04166);
                        instance.saveToRightStorage('sess_re', JSON.parse(sessRe), 31);
                    }
                }

                // on each message check clean the deferredStorage if necessary
                let storageCount = 0;
                for (var i = instance.nextDataExchangeId; i > 0; i--) {
                    if (instance.deferredStorage[payload.exchangeId]) {
                        storageCount++;

                        // Promises that are in the storage for so long that 10 other promises already have been created
                        // are seen as outdated. This is a quite simple and a little naive implementation of a timeout.
                        // Count backwards because promises having a higher order number are more recent.
                        if (storageCount > 10) {
                            instance.deferredStorage[payload.exchangeId].reject('timeout');
                            delete instance.deferredStorage[payload.exchangeId];
                        }
                    }
                }
            };
        }
    }

    setCookie(name, value, expiration) {
        Cookies.set(name, value, {expires: expiration, secure: true, sameSite: 'lax'});
        return value;
    }

    setLocalStorage(name, value) {
        sessionStorage.setItem(name, value);
        return value;
    }

    /**
     * Returns the data stored in the specified cookie.
     *
     * @param {*} name the identifier of the cookie to get the contents of
     */
    getCookie(name) {
        return Cookies.getJSON(name);
    }

    clearCookie() {
        // There is an issue with Firefox here: it won't delete the cookies 'sess_au' and 'sess_re' even if they
        // are expired anyway. Since there is a redirect in place from the chat.html to the xpert.html if a certain
        // cookie does actually exist. Currently this is the 'produck'-cookie and that gets indeed deleted by Firefox,
        // so everything works as expected. But if Firefox suddenly decides that it does not want to delete that cookie
        // anymore, too, then that will create an endless loop: redirecting to xpert.html -> redirecting back to index.html
        // because authentication tokens are expired -> redirecting to xpert.html because cookie is still there.
        // Currently there is no known explanation, why Firefox prevents the deletion of the cookies.
        Cookies.remove('sess_au');
        Cookies.remove('sess_re');
        Cookies.remove('produck');
        Cookies.remove('i18next');

        if(window.isAuthenticated) isAuthenticated = false; //TODO refactor global var away!!
    }

    clearAllCookies() {
        var cookies = document.cookie.split(";");
        cookies.forEach(function(cookie) {
            let eqPos = cookie.indexOf("=");
            let name = (eqPos > -1 ? cookie.substr(0, eqPos) : cookie).trim();

            // produck_marker cookie is an exception because it is dependent on another consent on the specific shop page
            if (name != 'produck_marker') {
                Cookies.remove(name);
            }
        });

        this.initGoogleAnalytics(true);

        if(window.isAuthenticated) isAuthenticated = false; //TODO refactor global var away!!
    }


    initGoogleAnalytics (value) {
        var instance = this;
        var gaProperty = 'G-8FZF0ZCT1P';
        var disableStr = 'ga-disable-' + gaProperty;

        // Disable tracking if the opt-out cookie exists.
        value === true ? gaOptout() : gaOptIn();

        function gaOptIn() {
            window[disableStr] = false;

            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-WXPBRMV');

            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', gaProperty); // to disable use: gtag('config', 'G-8FZF0ZCT1P', { 'send_page_view': false });

            gtag('consent', 'update', {
                'ad_storage': 'granted',
                'analytics_storage': "granted",
                'functionality_storage': "granted",
                'personalization_storage': "granted",
                'security_storage': "granted",
            });
        }

        // Opt-out function
        async function gaOptout() {
            window[disableStr] = value;
            Cookies.remove('_ga');
            Cookies.remove('_gid');
            Cookies.remove('_gat');
            Cookies.remove('_ga_8FZF0ZCT1P');
            Cookies.remove('_gat_UA-115882700-2');
        }
    }

    /*  setCookie (auth, refresh) {

          // set token expiration
          var tokenExpAt = new Date (this.getTokenParam(auth).expiration);
          var tokenExpRt = new Date (this.getTokenParam(refresh).expiration);

          Cookies.set('sess_au', {accToken: auth}, {expires: tokenExpAt}, {secure: true}, {domain: '192.178.168.20/xpert.html'}, {domain: '192.178.168.20/user_chat.html'});

          Cookies.set('sess_re', {refToken: refresh}, { expires: tokenExpRt });

          var authData = Authenticator.getTokenParam(auth);
          setSessionCookie(authData)

          return auth
      }*/

    /**
     * Figures out if application is opened in iframe
     * If so, cookies are substituted by localstorage
     */
    inIframe () {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    /**
    * Stores profile data after first call in cookie
    * @param {object} userdata holds user information, requires at least userId and userName
    */
    saveToRightStorage(storageContentName, storageContentData, expiration) {
        var instance = this;

        if(instance.inIframe()) {
            const setData = (key, data, expiration) => {
                const messageData = {
                    key: key,
                    method: 'set',
                    data: data,
                    expiration: expiration
                };
                instance.produckWindowPort.postMessage(JSON.stringify(messageData));
            };
            setData(storageContentName, storageContentData, expiration);
            instance.setCookie(storageContentName, storageContentData, expiration);

            return storageContentData;
        } else {
            instance.setCookie(storageContentName, storageContentData, expiration);
            return storageContentData;
        }
    }

    async getRightStorage(storageContentName) {
        var instance = this;

        function getCookieFromParentWindow() {
            var currentDataExchangeId = instance.nextDataExchangeId++;
            var dataExchangeDeferred = {};
            var dataExchangePromise = new Promise((resolve, reject) => {
                dataExchangeDeferred.resolve = resolve;
                dataExchangeDeferred.reject = reject;

                const requestData = (key) => {
                    const messageData = {
                        exchangeId: currentDataExchangeId,
                        key: key,
                        method: 'get',
                    };

                    instance.produckWindowPort.postMessage(JSON.stringify(messageData)); // finally this resolves the promise
                };

                requestData(storageContentName);

            }).catch((error) => {
                console.log('Data exchange with parent window failed. Error: ', error);
                return false;
            });

            instance.deferredStorage[currentDataExchangeId] = dataExchangeDeferred;
            return dataExchangePromise;
        }

        if (instance.inIframe()) {
            try {
                var resolvedCookieFromParentWindow = await getCookieFromParentWindow();
                return resolvedCookieFromParentWindow; // delivers a promise as result
            } catch(e) {
                // try direct recall of cookie
                console.log('Cookie retrieval from parent failed, now trying to fetch from own storage', e);
                return await instance.getCookie(storageContentName);
                // else continue without cookie
            }
        } else {
            return await instance.getCookie(storageContentName); // deliver final cookie as result
        }
    }

    clearEntireStorage() {
        var instance = this;

        if(instance.inIframe()) {
            const messageData = {
                method: 'clear',
            };
            return instance.produckWindowPort.postMessage(JSON.stringify(messageData));
        } else {
            return instance.clearCookie();
        }
    }

    removeRightStorage(storageContentName) {
        var instance = this;

        if(instance.inIframe()) {

            const removeData = (key) => {
                const messageData = {
                    key: key,
                    method: 'remove',
                };
                instance.produckWindowPort.postMessage(JSON.stringify(messageData));
            };
            removeData(storageContentName);
        } else {
            return Cookies.remove(storageContentName);
        }
    }

    /**
     * Sets a cookie for an authorised session.
     *
     * @param {Object} authdata {expiration, userId, userRole, userName}
     */
    async setSessionCookie(authdata) {
        var instance = this;
        var produckCookie = await instance.getRightStorage('produck');
        var data = {};

        if (produckCookie) data = produckCookie;

        data.userId = authdata.userId;
        data.userRole = authdata.userRole;
        data.userName = authdata.userName;

        instance.saveToRightStorage('produck', data, 30);
        //instance.saveToRightStorage('produck', data, 0.0006944444); // 1 minute - for debugging
    }

    async saveExtendedUserDataInCookie(userData) {
        var instance = this;
        var produckCookie = await instance.getRightStorage('produck');
        var userName;

        if (userData.firstName) {
            userName = userData.firstName;
        } else if (userData.nickname) {
            userName = userData.nickname;
        } else if (userData.userName) {
            userName = produckCookie.userName.split('@')[0];
        } else {
            // Actually an error case, should not happen; but if, it does rather show 'Ducky' to the user than 'Undefined'
            userName = 'Ducky';
        }

        userData.userName = userName;

        function input(inputVal, alternative) {
            return (inputVal ? inputVal : (alternative ? alternative : ''));
        }
        var portraitImg = input(userData.portraitImg, "/assets/img/android-chrome-192x192.png");
        var backgroundImg = input(userData.backgroundImg, "/assets/img/profiles/prdk_bgrd_profile_pattern_2.jpg");


        if (produckCookie) {
            produckCookie.userData = userData;
            instance.saveToRightStorage('produck', produckCookie, 30);
        }

        return userData;
    }

    async getExtendedUserData() {
        var instance = this;

        var extendedData = false;
        var produckCookie = await instance.getRightStorage('produck');


        if (produckCookie) {
            if (produckCookie.userData) { extendedData = produckCookie.userData };
        } else {
            instance.authenticator = new monstecLib.Authenticator(instance);
            instance.authenticator.promptForRelogin();
        }

        return extendedData;
    }

    // save user's confirmation of cookie-terms
    async setCookieOkCookies () {
        var instance = this;
        var okCookie = await instance.getRightStorage('okc');
        var refreshCookie = await instance.getRightStorage('sess_re');

        //just save this if cookie is not yet existing or user is no registered user (when iframe should not show cookie add "&& !instance.inIframe()")
        if (!okCookie && !refreshCookie && !instance.inIframe()) {
            instance._initCookieDialogue();
            $('#cookie-info').show();
        } else {
            // case 1: if false, ga-disable is turned off (status false) and tracking is active, depending on the user status
            // case 2: for authenticated users, tracking is on (status false)
            // case 3: has okc, but is in iframe - no tracking (status true)
            if (okCookie && !instance.inIframe()) {
                instance.initGoogleAnalytics(!okCookie.improvement);
            } else if (refreshCookie) {
                instance.initGoogleAnalytics(false);
            } else {
                instance.initGoogleAnalytics(true);
            }
        }
        // example of how to extend a cookie
        /*else{Cookies.set('okc2', {okCookie, 'value': {'some': 'somevalue'}, {expires: 5000})*/
    }

    updateChatHeader(userData, correspondingChat, chatTopic){
        var instance = this;
        var currentChatlog;
        if (correspondingChat) {
            currentChatlog = $(".cl[data-chatid=" + correspondingChat + "]");
        } else  {
            currentChatlog = $(".cl.active");
        }

        if(chatTopic) {
            currentChatlog.attr('data-first-message', chatTopic);
        }

        var xpertNameEle = currentChatlog.find('.xpertside');
        var userNameEle = currentChatlog.find('.userside');

        if (xpertNameEle.length) {
            xpertNameEle.html(userData.nickname);
        } else {
            userNameEle
                .html(userData.nickname)
                .attr('href', '/profile/' + userData.id)
                .attr('target', '_blank')
                .addClass('prdk-link');
        }

        const img = new Image();

        if (userData.portraitImg) {
            img.src = userData.portraitImg;
        } else {
            img.src = "/assets/img/android-chrome-192x192.png";
        }

        currentChatlog.find('.portrait_link').html('').append(img).css({"align-items": "flex-start"});
        currentChatlog.find('.xpert_status').show();
    }

    _initCookieDialogue() {
        
        const instance = this;
        $('#cookie-confirm').click(function() {
            let cookieConf = {'cookieConf': 'true', 'improvement': true};
            instance.saveToRightStorage('okc', cookieConf, 1460);

            instance.initGoogleAnalytics(false); //if false, ga disable is turned off and tracking is active

            $('#cookie-info').hide();
        });

        $('#cookie-confirm-partial').click(function() {
            let improvement = $('#cookie-info-checkbox-improvement').prop('checked'),
                cookieConf = {'cookieConf': 'true', 'improvement': improvement},
                duration;

            improvement ? duration = 1460 : 30;

            instance.saveToRightStorage('okc', cookieConf, duration);

            instance.initGoogleAnalytics(!improvement); //if false, ga disable is turned off and tracking is active

            $('#cookie-info').hide();
        });



        $('#cookie-preferences-link').click(function() {
            if($('#card-cookie-checkboxes').is(':visible') ) {
                $('#card-cookie-checkboxes').hide();
                $('#card-cookie-details').hide();
                $(this).find('i').html('keyboard_arrow_down');
            } else {
                $('#card-cookie-checkboxes').show();
                $('#card-cookie-details').show();
                $(this).find('i').html('keyboard_arrow_up');
            }
        });

        function changeCookieInfoSection(sectionIdentifier) {
            $('#cookie-details-main-area').children().hide();
            $('#cookie-info-' + sectionIdentifier).show();
            $('.cookie-details-sidenav').children().css({"border-right-color": "#3a3a3a",
                "border-right-width":"1px",
                "border-right-style":"solid"});
            $('#cookie-info-' + sectionIdentifier + '-link').css('border-right', 'none');
            $('.cookie-details-sidenav').children().removeClass('active');
            $('#cookie-info-' + sectionIdentifier + '-link').addClass('active');
        }

        $('#cookie-info-general-link').click(function() {
            changeCookieInfoSection('general');
        });

        $('#cookie-info-required-link').click(function() {
            changeCookieInfoSection('required');
        });

        $('#cookie-info-improvement-link').click(function() {
            changeCookieInfoSection('improvement');
        });
    }

    async getUidFromCookies() {
        var instance = this;
        var sessionAuthCookie = await instance.getRightStorage('sess_au');
        if(!!sessionAuthCookie){
            var uid = (await instance.getRightStorage('produck')).userId;
            return uid;
        }
    }

    storeSelectionToLocalStorage(name, content) {
        localStorage.setItem(name, JSON.stringify(content));
    }

    async saveLastVisitedPage(url) {
        var instance = this;
        
        if (!url) return;

        var locationCookie = await instance.getRightStorage('location');
        if (!locationCookie) {
            locationCookie = {};
        }

        locationCookie.lastVisited = url;
        instance.saveToRightStorage('location', locationCookie, 30);
    }

    /** this fnc can be used to redirect customer to pages more than one step back
    * e.g. to include profile completion in signup process and redirect to initial site afterwards
    */
    async saveSpecificPage(url) {
        var instance = this;
        
        if (!url) return;

        var locationCookie = await instance.getRightStorage('location');
        if (!locationCookie) {
            locationCookie = {};
        }

        //specpag: specific page
        locationCookie.specpag = url;
        instance.saveToRightStorage('location', locationCookie, 30);
    }

    async getLastVisitedPage() {
        var instance = this;
        var locationCookie = await instance.getRightStorage('location');
        if (locationCookie) {
            return locationCookie.lastVisited;
        }
    }

    async getSpecificVisitedPage() {
        var instance = this;
        var locationCookie = await instance.getRightStorage('location');
        if (locationCookie) {
            return locationCookie.specpag;
        }
    }

    async deleteSpecificVisitedPage() {
        var instance = this;
        var locationCookie = await instance.getRightStorage('location');
        if (locationCookie) {
            locationCookie.specpag = "";
            instance.saveToRightStorage('location', locationCookie, 30);
        }
    }
}
