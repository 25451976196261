/* global monstecLib */
/* global M */

/**
 * Section that enables users to view questions, manipulate own questions and give answers.
 *
 * When using this class it is mandatory to include the template "question.html" in the element
 * the instance of this class will be attached to.
 */
export default class QuestionSection {
    /**
     * Creates a new instance of this section.
     *
     * @param {object} syncPermissioChecker a commonly used synchronous permission checker
     */
    constructor(syncPermissioChecker) {
        this.authChecker = syncPermissioChecker;

        this.i18next = monstecLib.i18next;
        this.chatService = monstecLib.produckContext.chatService;
        this.chatSupport = monstecLib.produckContext.chatSupport;

        this.log = new monstecLib.Log(1);
    }

    /**
     * Attaches the current instance to a specific element and sets all necessary handlers on sub-elements.
     * This function should not do heavy work. Put data initialisation code in the function 'initialise' and
     * call it when the user actually gets to see the section the first time.
     * Best just do the work in 'attachTo' that is absolutely necessary to make the component reachable to
     * the user.
     *
     * @param {string} htmlId identifies the container element that will form the question section
     */
    attachTo(htmlId) {
        const instance = this;
        instance.log.debug('Attaching question section to element having the ID ' + htmlId);

        instance.htmlId = htmlId;
        instance.container = $('#' + htmlId);

        let tabs = $('#questionSectionNavigation');
        instance.questionSectionNavigation = M.Tabs.init(tabs)[0];
        instance.chatSupport.scrollBarTabs(tabs[0]);

        /* -------------------------------------- */
        /*** create "all questions"-subsection  ***/
        /* -------------------------------------- */
        instance.answerableQuestionList = new monstecLib.QuestionList(
            instance.authChecker.getUser(),
            instance.authChecker.checkPermission(instance.authChecker.permCat.WRITE_ANSWER)
        );
        instance.answerableQuestionList.attachTo('answerableQuestionsList');

        let answerableQuestionListDataProvider = async function(filter) {
            try {
                let response = await instance.chatService.getAnswerableQuestions(filter);
                return response;
            } catch(error) {
                if (error.status == 404) {
                    M.toast({html: instance.i18next.t('toasts.no_data_found')});
                    return [];
                } else {
                    instance.log.error('Could not retrieve list of answerable questions.', error);
                    M.toast({html: instance.i18next.t('toasts.error')});
                    return [];
                }
            }
        };

        instance.answerableQuestionList.setDataProvider(answerableQuestionListDataProvider);
        $('#answerableQuestionsNavItem').on('click', function() {
            instance.answerableQuestionList.updateView();
        });

        /* -------------------------------------- */
        /*** create "own questions"-subsection  ***/
        /* -------------------------------------- */
        let ownQuestionList = new monstecLib.QuestionList(instance.authChecker.getUser(), false);
        ownQuestionList.attachTo('myQuestionsList');

        let myQuestionListDataProvider = async function(filter) {
            try {
                filter.user = instance.authChecker.getUser();
                let response = await instance.chatService.getAnswerableQuestions(filter);
                return response;
            } catch(error) {
                if (error.status == 404) {
                    M.toast({html: instance.i18next.t('toasts.no_data_found')});
                    return [];
                } else {
                    instance.log.error('Could not retrieve list of own questions.', error);
                    M.toast({html: instance.i18next.t('toasts.error')});
                    return [];
                }
            }
        };

        ownQuestionList.setDataProvider(myQuestionListDataProvider);
        $('#myQuestionsNavItem').on('click', function() {
            ownQuestionList.updateView();
        });

        //stop collapsible
        $('#answerableQuestionsList .collapsible, #myQuestionsList .collapsible').on("click", function(e) {
            e.stopImmediatePropagation();
        });  

        /* --------------------------------------------------------- */
        /***  add new-question-form to "all questions"-subsection  ***/
        /* --------------------------------------------------------- */
        const questionForm = new monstecLib.QuestionForm();
        questionForm.attachTo('newQuestionFormWrapper');

        questionForm.sendAction = async function(question) {
            try {
                let response = await instance.chatService.createQuestion(question);
                return response;
            } catch(error) {
                if (error.status != 400) {
                    instance.log.error('Error when sending question to service: ', error);
                } else {
                    return error;
                }
            }
        };

        questionForm.onSendingSuccessful = function(sendResponse) {
            questionForm.close(true);
            setTimeout(function () {
                instance.questionSectionNavigation.select('myQuestionsList');
                //setTimeout(function() {
                //    $('#myQuestionsList').find('.collapsible-header[data-question-id=' + sendResponse.questionId + ']').click();
                //}, 100);
            }, 200);
        };

        $('#createQuestionButton').on('click', () => {
            questionForm.open(true);
        });
    }

    /**
     * Makes the component ready to be viewed by the user.
     *
     * @param {boolean} fetchAnwserableQuestionsOnInit Defaults to true; define whether the list
     *    of answerable questions will be fetched on initialisation. This is generally useful when
     *    showing the section so there is content immediately. However there a cases when the
     *    answerableQuestions-subsection is not the one that is shwon initially. For example when
     *    the page is reloaded an the user has been on another tab, which is going to be shown after
     *    the reload. Then of course the answerable questions do not have to be loaded. Furthermore
     *    if a mechanism is used to directly access a certain subsection and that one is the
     *    answerableQuestions-subsection it might happen, that the question-list will be fetched twice
     *    depending on how the subsection is accessed. Then this parameter can also be used to
     *    prevent that.
     */
    initialise(fetchAnwserableQuestionsOnInit = true) {
        const instance = this;

        if (!instance.initialised) {
            instance._initQuestionFilter(instance.answerableQuestionList);

            if (fetchAnwserableQuestionsOnInit) {
                instance.answerableQuestionList.updateView();
            }

            instance.initialised = true;
        }
    }

    /**
     * Initialises the filter controls of the all questions subsection
     */
    _initQuestionFilter(questionList) {
        const instance = this;
        const filterWrapper = instance.container.find('#answerableQuestionsFilterWrapper');

        //initialise button for showing / hiding the chat-filter
        $(document).on('click', '#showQuestionFilterButton', function () {
            let symbol = $(this).find('i');
            if (symbol.text() == 'keyboard_arrow_down') {
                symbol.text('keyboard_arrow_up');
                filterWrapper.slideDown('slow');
            } else {
                symbol.text('keyboard_arrow_down');
                filterWrapper.slideUp('slow');
            }
        });

        const questionFilterDateUntilInput = M.Datepicker.init(instance.container.find('#answerableQuestionFilterDateUntilInput'), {
            autoClose: true,
            maxDate: new Date(),
            showClearBtn: true,
            format: monstecLib.i18next.t('general.date_format_short'),
            i18n: {
                cancel: monstecLib.i18next.t('general.cancel'),
                clear: monstecLib.i18next.t('general.clear'),
                done: monstecLib.i18next.t('general.ok'),
                months: monstecLib.i18next.t('general.months'),
                monthsShort: monstecLib.i18next.t('general.months_short'),
                weekdays: monstecLib.i18next.t('general.weekdays'),
                weekdaysShort: monstecLib.i18next.t('general.weekdays_short'),
                weekdaysAbbrev: monstecLib.i18next.t('general.weekdays_abbreviation')
            }
        });

        const filterQuestions = function () {
            let topic = $('#answerableQuestionFilterTopicInput').val();
            let date = questionFilterDateUntilInput[0].date;

            let filter = {
                page: 1
            };

            if (topic) filter.topic = topic;
            if (date) filter.date = date.toISOString();

            questionList.setFilter(filter);
            questionList.updateView();
        };

        const applyButton = instance.container.find('.apply-filter-button');
        applyButton.click(filterQuestions);
        instance.container.on('keypress', '#answerableQuestionFilterTopicInput, #answerableQuestionFilterDateUntilInput', (e) => {
            if (e.keyCode === 13) applyButton.click();
        });

        instance.container.find('.clear-filter-button').click(function () {
            instance.container.find('#answerableQuestionFilterTopicInput').val('');
            instance.container.find('#answerableQuestionFilterDateUntilInput').val('');
            questionFilterDateUntilInput[0].date = undefined;
            questionList.resetFilter();
            questionList.updateView();
        });
    }
}
