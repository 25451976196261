
export default class ServiceSupport {
    /**
     * Only call once for the setup of the application!
     */
    supportBinary() {
        $.ajaxTransport("+binary", function (options, originalOptions, jqXHR) {
            // check for conditions and support for blob / arraybuffer response type
            if (window.FormData && ((options.dataType && (options.dataType == 'binary')) || (options.data && ((window.ArrayBuffer && options.data instanceof ArrayBuffer) || (window.Blob && options.data instanceof Blob))))) {
                return {
                    // create new XMLHttpRequest
                    send: function (headers, callback) {
                        // setup all variables
                        var xhr = new XMLHttpRequest(),
                            url = options.url,
                            type = options.type,
                            async = options.async || true,
                            // blob or arraybuffer. Default is blob
                            dataType = options.responseType || "blob",
                            data = options.data || null,
                            username = options.username || null,
                            password = options.password || null;

                        xhr.addEventListener('load', function () {
                            var responseData = {};
                            responseData[options.dataType] = xhr.response;
                            // make callback and send data
                            callback(xhr.status, xhr.statusText, responseData, xhr.getAllResponseHeaders());
                        });

                        if (type == 'GET' && !!data && !!url) {
                            let segments = [];
                            for (let property in data) {
                                if (!data.hasOwnProperty(property)) continue;
                                segments.push(encodeURIComponent(property) + "=" +  encodeURIComponent(data[property]));
                            }

                            let segmentsJoinChar = (url.indexOf('?') >= 0) ? '&' : '?';
                            url += segmentsJoinChar + (segments.length > 0 ? segments.join("&") : "");
                        }

                        xhr.open(type, url, async, username, password);

                        // setup custom headers
                        for (var i in headers) {
                            xhr.setRequestHeader(i, headers[i]);
                        }

                        xhr.responseType = dataType;
                        xhr.send(data);
                    },
                    abort: function () {
                        jqXHR.abort();
                    }
                };
            }
        });
    }

    async sendFormData(formData, endpoint, progressCallback, successCallback, errorCallback) {
        let accessToken = await this._getAccessToken();

        return $.ajax({
            url: endpoint,
            type: 'POST',
            data: formData,
            cache: false,
            contentType: false, // must be false so that content type will include boundary
            headers: {"Authorization": "Bearer " + accessToken},
            processData: false, // must be false to prevent JQuery from converting the file to a string
            success: function(response) {
                if (successCallback) {
                    successCallback();
                }

                return response;
            },
            error: function(response) {
                if (errorCallback) {
                    errorCallback();
                }

                return response;
            },
            xhr: function() {
                var myXhr = $.ajaxSettings.xhr();
                if (myXhr.upload && progressCallback) {
                    myXhr.upload.addEventListener('progress', progressCallback, false);
                }

                return myXhr;
            }
        });
    }
}
