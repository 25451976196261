/* global monstecLib */

/**
 * Provides access to the billing service by creating a REST client and providing some
 * quick access methods for retrieving data.
 */
export default class BillingServiceAccess {
    constructor(authenticatorSingleton) {
        this.constants = new monstecLib.Constants();
        this.authenticator = authenticatorSingleton;
        this.restClient = this._createRestClientForBillingService();

        this.log = new monstecLib.Log(1);
    }

    initialiseContextBeans() {
        this.authenticator = monstecLib.produckContext.authenticator;
    }

    /**
     * Creates a REST Client that enables you to contact and work with the billing service.
     */
    _createRestClientForBillingService() {
        var restBuilder = new monstecLib.RestClientBuilder();
        var restApi = restBuilder.createRestClient(this.constants.apiHostBilling);

        // configure resources to use
        restApi.res('link');
        restApi.res('sales');
        restApi.res('recompenses');
        restApi.res('settings');

        return restApi;
    }

    async checkHealth() {
        const instance = this;
        let accessToken = await this._getAccessToken();

        return $.ajax({
            url: this.constants.apiHostBilling.slice(0,-1) + 'actuator/health',
            type: 'GET',
            cache: false,
            headers: {'Authorization': 'Bearer ' + accessToken},
            error: function(response) {
                console.log('Could not retrieve information: ', response);
            }
        });
    }

    async _getAccessToken() {
        try {
            return await this.authenticator.getAccessToken();
        } catch (error) {
            this.authenticator.promptForRelogin();
            return;
        }
    }

    async generateAffiliateLink(url) {
        var instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        let affiliateLink = instance.restClient.link.post({ "target": url });
        return affiliateLink;
    }

    async getAffiliateSalesOfXDays(days) {
        var instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        var startDate = new Date(Date.now() - (days * 24 * 60 * 60 * 1000));
        startDate.setHours(0);
        startDate.setMinutes(0);
        startDate.setSeconds(0);

        let affiliateSales = instance.restClient.sales.get({ "start": startDate.toISOString() });
        return affiliateSales;
    }

    /**
     * Returns an array of lable-value-pairs
     */
    async getRecompenseCategories() {
        var instance = this;
        let list = [];

        try {
            let table = await instance.restClient.recompenses.get();

            table.forEach(function(entry) {
                let pair = {};
                pair.label = entry.category;
                pair.value = entry.id;
                list.push(pair);
            });
        } catch(e) {
            instance.log.error('Could not retrieve recompense list from service.', e);
        }

        return list;
    }

    async getUserSettings() {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.settings.get();
    }

    async saveUserSettings(settings) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.settings.put(settings);
    }
}
