import RestClient from 'another-rest-client';

/**
 * Builder class for creating rest clients.
 */
export default class RestClientBuilder {
    constructor() {
    }

    /**
     * Creates another REST client accessing the service behind the given URI.
     *
     * @param {*} serviceBase the URI representing the base address of the REST service
     */
    createRestClient(serviceBase) {
        var instance = this;
        // remove trailing slashes because 'another rest client' works without those in its standard
        // configuration
        if (serviceBase.endsWith('/')) {
            serviceBase = serviceBase.substring(0, serviceBase.length - 1);
        }

        // use new endpoint
        var restClient = new RestClient(serviceBase);

        // if the current user is authenticated and has a valid bearer token, use it
        restClient.on('request', function(xhr) {
            if(!!restClient.accessToken) {
                xhr.setRequestHeader('Authorization', 'Bearer ' + restClient.accessToken);
            }
        });

        return restClient;
    }
}
