/* global monstecLib */
/* global M */
import ObjectList from '../common/objectlist';

/**
 * List of ProDuck questions.
 */
export default class QuestionList extends ObjectList {
    constructor(currentUserId, includeAnswerControls) {
        super();
        this.currentUserId = currentUserId;
        this.includeAnswerControls = includeAnswerControls;
        this.noResultsProperty = 'questionsection.no_questions';
        this.tutorialProperty = {'link': '/docu/question.html#add-new-question', 'text': 'questionsection.tutorial_link'};

        this.i18next = monstecLib.i18next;
        this.utils = monstecLib.produckContext.utils;
        this.authenticator = monstecLib.produckContext.authenticator;
        this.chatService = monstecLib.produckContext.chatService;

        this.log = new monstecLib.Log(1);
    }

    /**
     * Creates a DOM-element that will display a single question in the list of questions.
     *
     * @param {*} question the question to create a list item for
     */
    _createListItem(question) {
        const instance = this;

        let checkboxTitle, checkboxChip;
        let itemHeaderId = instance.htmlId + 'QuestionItemHeader' + question.id;
        let productName = (question.productName)  ? '<span class="object-title current-content">' + instance.i18next.t('text.product') + ': ' + question.productName + '</span>'  : '';

        if (question.status == 'ANSWERED') {
            checkboxChip = '<div class="chip status-chip accepted" >' + instance.i18next.t('questionsection.answer_status_pos') + '</div>';
        } else {
            checkboxChip = '<div class="chip status-chip REQUESTED">' + instance.i18next.t('questionsection.answer_status_neg') + '</div>';
        }

        let questionItemHtml = '<li class="js-collapsible-item">'
          + '  <div class="collapsible-header object-list-item disabled" id="' + itemHeaderId + '" data-question-id="' + question.id + '" >'
          + '    <div class="question-info-header simple-vertical-content">'
          + '      <a title="' + instance.i18next.t('questionsection.open_question_quack') +'" href="' + instance._buildQuestionHref(question) + '" target="_blank" id="' + itemHeaderId + 'Topic" class="question-topic object-title current-content bold">' + question.topic + '</a>'
          +        productName
          +        checkboxChip
          + '    </div>'
          + '    <div class="simple-horizontal-content">'
          + '      <div id="' + instance.htmlId + 'QuestionActionButtons' + question.id + '">'
          + '      </div>'
          + '    </div>'
          + '  </div>'
          + '  <div class="collapsible-body"></div>'
          + '</li>';

        const questionItem = $(questionItemHtml);
        questionItem.data('questionId', question.id);
        const questionItemBody = questionItem.find('.collapsible-body');

        if (question.userId == this.currentUserId) {
            instance._addEditableBehaviour(question, questionItem, itemHeaderId, questionItemBody);
        } else {
            instance._addLinkBehaviour(question, questionItem, itemHeaderId);
        }

        return questionItem;
    }

    /**
     * If a question item has been created by the current user this method should be called to
     * enable editing of the question.
     */
    _addEditableBehaviour(question, questionItem, itemHeaderId, questionItemBody) {
        const instance = this;
        let topicElement = questionItem.find('#' + itemHeaderId + 'Topic');
        const topicEditField = new monstecLib.InPlaceEditField(topicElement);

        let topicButtonConfig = [
            { icon: 'question_answer', func: function() {
                var host = "https://www.produck.de";

                window.open(`${host}/quackref/question/${question.id}/`, '_blank');
            }, tooltip: 'questionsection.open_question_quack' },
            { icon: 'edit',
                showLoader: true,
                func: function() {
                topicEditField.onCommit = async function() {
                    let questionUpdate = {};
                    questionUpdate.id = question.id;
                    questionUpdate.topic = topicEditField.getValue();
                    
                    try {
                        await instance.chatService.updateQuestion(questionUpdate);
                        M.toast({html: instance.i18next.t('toasts.data_updated')});
                        return true;
                    } catch (errorResponse) {
                        instance.log.debug('Update of question failed!', errorResponse);
                        if (errorResponse.status == 400) {
                            M.toast({html: instance.i18next.t('toasts.data_invalid')});
                        } else {
                            M.toast({html: instance.i18next.t('toasts.error')});
                        }
                    }
                };

                topicEditField.replaceSource();
            }, tooltip: 'questionsection.edit_question'}
        ];

        let topicButtons = new monstecLib.ActionButtons(topicButtonConfig, 'left');
        topicButtons.attachTo(instance.htmlId + 'QuestionActionButtons' + question.id, questionItem);
    }

    /**
 * If a question item has been created by the other user this method should be called to
 * enable "go to question".
 */
    _addLinkBehaviour(question, questionItem, itemHeaderId) {
        const instance = this;
        let topicElement = questionItem.find('#' + itemHeaderId + 'Topic');
        const topicEditField = new monstecLib.InPlaceEditField(topicElement);

        let topicButtonConfig = [
            { icon: 'link', func: function() {
                window.open(instance._buildQuestionHref(question), '_blank');
            }, tooltip: 'questionsection.open_question_quack' }
        ];

        let topicButtons = new monstecLib.ActionButtons(topicButtonConfig, 'left');
        topicButtons.attachTo(instance.htmlId + 'QuestionActionButtons' + question.id, questionItem);
    }

    _buildQuestionHref(question) {
        let host = "https://www.produck.de";

        let href = `${host}/quackref/question/${question.id}/`;

        return href;
    }
}
