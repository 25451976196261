/* global monstecLib */
import ServiceSupport from './servicesupport';

/**
 * Provides access to the catalogue service by creating a REST client and providing some
 * quick access methods for retrieving data.
 */
export default class CatalogueServiceAccess extends ServiceSupport {
    constructor(authenticatorSingleton) {
        super();
        this.constants = new monstecLib.Constants();
        this.authenticator = authenticatorSingleton;
        this.restClient = this._createRestClientForCatalogueService();
    }

    initialiseContextBeans() {
        this.authenticator = monstecLib.produckContext.authenticator;
    }

    /**
     * Creates a REST Client that enables you to contact and work with the catalogue service.
     */
    _createRestClientForCatalogueService() {
        var restBuilder = new monstecLib.RestClientBuilder();
        var restApi = restBuilder.createRestClient(this.constants.apiHostCatalogue);

        // configure resources to use
        restApi.res('product');
        restApi.res('offer');
        restApi.res('offers');
        restApi.res('thirdpartyoffers');
        restApi.thirdpartyoffers.res('hot');
        restApi.thirdpartyoffers.res('asins');

        return restApi;
    }

    async checkHealth() {
        const instance = this;
        let accessToken = await this._getAccessToken();

        return $.ajax({
            url: this.constants.apiHostCatalogue.slice(0,-1) + 'actuator/health',
            type: 'GET',
            cache: false,
            headers: {'Authorization': 'Bearer ' + accessToken},
            error: function(response) {
                console.log('Could not retrieve information: ', response);
            }
        });
    }

    async _getAccessToken() {
        try {
            return await this.authenticator.getAccessToken();
        } catch (error) {
            this.authenticator.promptForRelogin();
            return;
        }
    }

    async getOffer(offerId) {
        const instance = this;
        return instance.restClient.offer.get({'offerId': offerId});
    }

    async getOfferByMtin(shopId, mtin) {
        const instance = this;
        return instance.restClient.offer.get({'shopId': shopId, 'mtin': mtin});
    }

    async getOffers(shopId) {
        var instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        if (!!shopId) {
            return instance.restClient.offers.get({'shopId': shopId});
        } else {
            return instance.restClient.offers.get();
        }
    }

    async createProduct(product) {
        var formData = new FormData();
        formData.append('shortName', product.shortName);
        formData.append('shortDescription', product.shortDescription);
        if (product.detailsUrl) formData.append('detailsUrl', product.detailsUrl);
        formData.append('imageUrl', product.imageUrl);
        // TODO formData.append('image', file);

        return super.sendFormData(formData, this.constants.apiHostCatalogue + 'product');
    }

    async createOffer(offer) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.offer.post(offer);
    }

    async updateOffer(offer) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.offer.put(offer);
    }

    async createOffers(file) {
        var formData = new FormData();
        formData.append('file', file);

        return this.sendFormData(formData, this.constants.apiHostCatalogue + 'offers');
    }

    /**
     * Retrives the currently-featured-products-advertisements from the service.
     */
    async getHotAmazonOffers() {
        const instance = this;
        return instance.restClient.thirdpartyoffers.hot.get();
    }

    /**
     * Retrives a certain number (corresponding to the number of given distinct ASINs) of product
     * advertisements from the service. If there is no data for a specific ASIN there will be no
     * product advertisement data for that ASIN in the result.
     *
     * @param {*} asins an array of Amazon product IDs as strings or a single ASIN as string
     * @param {number} userId the user whose amazon tag will be integrated in the product links
     */
    async getAmazonProductData(asins, userId) {
        const instance = this;

        if (asins instanceof Array) {
            const params = [];
            if (userId) {
                params.push({'userId': userId});
            }

            asins.forEach((asin) => {params.push({'ids': asin});});

            let func = instance.restClient.thirdpartyoffers.asins.get;
            return func.apply(instance.restClient, params);
        } else {
            return instance.restClient.thirdpartyoffers.asins.get({'userId': userId, 'ids': asins});
        }
    }
}
