/* global monstecLib */
//TODO merge this lib with credentials.js 
import 'url-search-params-polyfill';

export default class PasswordReset {
    constructor(identityServiceAccess) {
        if (!identityServiceAccess) {
            console.log('ERROR: PasswordReset - IdentityServiceAccess not set!');
        }

        this.identityService = identityServiceAccess;
    }

    /**
     * Defines the trigger of the password reset process and binds it to a form element.
     *
     * @param {*} form the form element that will trigger the password reset process by its submit event.
     */
    resetPw(form) {
        var instance = this;

        form.bind('submit', function (e) {
            e.preventDefault();
            var input = $($(this).find("input"));

            instance.identityService.requestPasswordResetToken(input.val().trim())
                .then(function() {
                    $('.helper-text').css({'display':'block'});
                    input.val("");
                })
                .catch(function(xhr) {
                    if (xhr.status === 400) {
                        $('.helper-text').css({'display':'block'});
                        input.val("");
                    } else {
                        console.log('Password reset could not be performed, server returned error code ' + xhr.status);
                        window.location.replace('failure-reset.html');
                    }
                });

            return false;
        });
    }

    /**
     * Defines password reset functionality and bind it to a specific form element.
     *
     * @param {*} form the form element that contains the password reset input elements
     */
    setPw(form) {
        var instance = this;
        var utils = new monstecLib.Utils();
        var token = utils.getParameterByName('token');

        function failSetPw() {
            window.location.replace('failure-reset.html');
        }

        if (!token) {
            failSetPw();
        }

        form.bind('submit', function (e) {
            e.preventDefault();
            var input = $("#set-password1").val().trim();

            instance.identityService.resetPassword(token, input)
                .then(function () {
                    window.location.replace('/index.html');
                })
                .catch(function (xhr) {
                    // error handler, receives a XmlHttpRequest-objec (xhr)
                    console.log('New password could not be set, server returned error code ' + xhr.status);
                    failSetPw();
                });

            return false;
        });
    }

    /**
     * Helper function that determines and shows if the passwords the user entered in the input
     * fields for the new password and the repetition of it are equal.
     */
    matchPasswords() {
        $("#set-password2").focusout(function() {
            var pwInput1 = $("#set-password1").val().trim();
            var pwInput2 = $("#set-password2").val().trim();

            if((pwInput1.length < 8)){
                $('#set-password1').addClass('invalid');
            } else if (pwInput1 != pwInput2) {
                $('#set-password2').addClass('invalid');
            } else {
                $('#set-password2').attr('class', 'valid password');
                $('#submit-pw').attr('class', 'btn waves-effect waves-light');
            }
        });
    }
}
