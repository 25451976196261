/* global monstecLib */

/**
 * Provides a handler that will be called whenever a textarea with multimedia support is opened.
 */
export default class NavBarControl {
    /**
     * Init a Nav Bar
     * 
     */
    constructor(externalAuthenticator, externalCookies, externalUtils) {
        this.authenticator = externalAuthenticator;
        this.cookie = externalCookies;
        this.utils = externalUtils;
    }


}
