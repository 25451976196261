export default class Constants {
  constructor() {
    this.webHost = 'https://api.produck.de/';
    this.apiHostBilling = 'https://api.produck.de/billing/';
    this.apiHostCatalogue = 'https://api.produck.de/catalogue/';
    this.apiHostChat = 'https://api.produck.de/chat-service/';
    this.apiHostIdentity = 'https://api.produck.de/identity/';
    this.apiHostOauth = 'https://api.produck.de/oauth/';
    this.apiHostOauth2 = 'https://www.produck.de/oauth2/';
    this.websocketChat = 'https://api.produck.de/chatsocket/';

  }
}