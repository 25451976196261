
export default class wvBridge {
    constructor(){
        this.constants = new monstecLib.Constants();
    }

    //handler for incoming chat requests
    icrHandler(payload) {
        this.init(payload);
        window.webViewBridge.send('incomingChatRequest', payload, function(res) {
            console.log(res);
        }, function(err) {
            console.log(err);
        });
    }

    //handler for incoming chatMessages
    icmHandler(payload) {
        this.init(payload);

        window.webViewBridge.send('incomingChatMessage', payload, function(res) {
            console.log(res);
        }, function(err) {
            console.log(err);
        });
    }

    init(payload) {
        var promiseChain = Promise.resolve();
        var promises = {};
        var callbacks = {};

        return window.webViewBridge = {
            /**
             * send message to the React-Native WebView onMessage handler
             * @param targetFunc - name of the function to invoke on the React-Native side
             * @param data - data to pass
             * @param success - success callback
             * @param error - error callback
             */
            send: function(targetFunc, data, success, error) {
                console.log('message send from webview with: ' + targetFunc);
                success = success || function(){};
                error = error || function () {};

                var msgObj = {
                    targetFunc: targetFunc,
                    data: data || {}
                };

                var msg = JSON.stringify(msgObj);           

                promiseChain = promiseChain.then(function () {
                    return new Promise(function (resolve, reject) {
                        console.log("sending message to app " + msgObj.targetFunc);

                        promises[msgObj.data] = {resolve: resolve, reject: reject};
                        callbacks[msgObj.data] = {
                            onsuccess: success,
                            onerror: error
                        };

                        console.log(msg);
                        window.postMessage(msg, '*');
                        resolve();
                    })
                }).catch(function (e) {
                    console.error('rnBridge send failed ' + e.message);
                });
            },
        };


        window.document.addEventListener('message', function(e) {
            console.log("message received from app");
            window.webViewBridge.send('message received from app', JSON.stringify(e), function(res) {
                console.log(res);
            }, function(err) {
                console.log(err);
            });

            var message;
            try {
                message = JSON.parse(e.data)
                window.webViewBridge.send('message treated', JSON.stringify(message), function(res) {
                    console.log(res);
                }, function(err) {
                    console.log(err);
                });
                handleMessageFromApp(message);

            }
            catch(err) {
                console.error("failed to parse message from app " + err);
                return;
            }  

            //resolve promise - send next message if available
            if (promises[message.msgId]) {
                promises[message.msgId].resolve();
                delete promises[message.msgId];
            }

            /*trigger callback - calls the success / error functions specified in the window.webview.send method
            ** message arguments are set in app.js in react native
            */
            if (message.args && callbacks[message.msgId]) {
                if (message.isSuccessfull) {
                    callbacks[message.msgId].onsuccess.apply(null, message.args);
                }
                else {
                    callbacks[message.msgId].onerror.apply(null, message.args);
                }
                delete callbacks[message.msgId];
            }
        });
    }

    //handles all messages from webview and returns requested data
    handleMessageFromApp(payload) {
        this.init(payload);

        console.log('App request received', payload);

        window.webViewBridge.send('App request received', JSON.stringify(payload), function(res) {
            console.log(res);
        }, function(err) {
            console.log(err);
        });

        switch (payload.method) {
            //new chat requests while offline track latest chatId to compare to latest known chatId in App and trigger alert in case not equal
            case 'getRequests':
                const lastIdinPendingRequests = $('.chat-request-wrapper').data('last-chatid');
                console.log(lastIdinPendingRequests);
                const returnPayload = {lastIdinPendingRequests};
                window.webViewBridge.send('newPendingChatRequests', JSON.stringify(returnPayload), function(res) {
                    console.log(res);
                }, function(err) {
                    console.log(err);
                });
                break;
        }

        return 'app request accomplished';
    }
}

