/* global monstecLib */
/* global M */

/**
 * Page class that allows users to upgrade their account to role PROVIDER.
 */
export default class ProviderUpgradePage {
    constructor(produckWindowPort) {
        const instance = this;
        instance.constants = new monstecLib.Constants();
        instance.cookie = new monstecLib.Cookie(produckWindowPort);
        instance.authenticator = new monstecLib.Authenticator(instance.cookie);
        instance.identityService = new monstecLib.IdentityServiceAccess(instance.authenticator);
        instance.billingService = new monstecLib.BillingServiceAccess();
        instance.utils = new monstecLib.Utils();

        instance.log = new monstecLib.Log(1);

        // Check if the current user has the permission to upgrade his account. If he doesn't, redirect him.
        instance.authenticator.checkPermission(instance.authenticator.permCat.UPGRADE_TO_PROVIDER)
        .then(async function(hasPermission) {
            if (hasPermission) {
                await instance._initialise();
                instance.utils.styleShariff();
                $("body").localize();
            } else {
                instance.log.debug('Authenticated user does not have the permission to upgrade to provider.');
                window.location.href = "/";
            }
        })
        .catch(function(e){
            // Page could not be initialised. This can happen for example when no user is authenticated at all.
            instance.log.debug('Initialisation failed.', e);
            window.location.href = "/";
        });
    }

    /**
     * Initialises the page so that it looks like intended.
     */
    async _initialise() {
        const instance = this;

        await instance._fillCategorySelect();
        await instance._prefillUserData();

        $('#upgradeToProviderButton').click(function(e) {
            e.preventDefault();
            instance._sendUpgradeData();
        });
    }

    /**
     * Loads the data for the product category and fills the corresponding select field with it.
     */
    async _fillCategorySelect() {
        const instance = this;
        let categories = await instance.billingService.getRecompenseCategories();

        const categoryField = $('#productCategorySelect');
        categoryField.append(new Option('',''));

        categories.forEach(function(category) {
            categoryField.append(new Option(category.label, category.value));
        });

        let materialSelect = M.FormSelect.init(categoryField)[0];
        materialSelect.input.setAttribute('id', 'productCategoryInput');

        categoryField.on('change', function() {
            let currentValue = categoryField.val();
            if (currentValue || currentValue.length > 0) {
                materialSelect.input.classList.remove('invalid');
            } else {
                materialSelect.input.classList.add('invalid');
            }
        });
    }

    /**
     * Loads already available data of the user and prefills input fields with it.
     */
    async _prefillUserData() {
        const instance = this;
        let userId = await instance.authenticator.getUser();
        let userData = await instance.identityService.getUserData(userId);

        if (userData) {
            // The order matters because the fields will be filled starting from the on that comes later in
            // the form, so that the first empty field in the form will have the focus at last.
            let prefillValues = ['website', 'lastName', 'firstName', 'company'];
            let focusTarget;


            prefillValues.forEach(function(currentValue) {
                let existingValue = userData[currentValue];
                let currentField = $('#' + currentValue + 'Input');

                if (existingValue) {
                    currentField.val(existingValue);
                } else {
                    // remember the field to be the one to get the focus if no higher prioritised gets it
                    focusTarget = currentField;
                }
            });

            M.updateTextFields(); // adjust text field labels

            if (focusTarget) {
                focusTarget.focus();
            } else {
                $('#productCategorySelect').focus(); // TODO does not work, because of materializecss changing html structure
            }
        }
    }

    /**
     * Sends the form data to the server. Validate first!
     */
    async _sendUpgradeData() {
        const instance = this;
        const i18next = monstecLib.i18next;

        let upgradeData = {};
        upgradeData.firstName = $('#firstNameInput').val();
        upgradeData.lastName = $('#lastNameInput').val();
        upgradeData.website = $('#websiteInput').val();
        upgradeData.company = $('#companyInput').val();
        upgradeData.paypal = $('#paypalInput').val();
        upgradeData.street = $('#streetInput').val();
        upgradeData.houseNumber = $('#houseNumberInput').val();
        upgradeData.zipCode = $('#zipCodeInput').val();
        upgradeData.city = $('#cityInput').val();
        upgradeData.productCategory = $('#productCategorySelect').val();

        instance.log.debug('Sending the following user data for upgrading: ', upgradeData);

        try {
            let result = await instance.identityService.upgradeToProvider(upgradeData);
            instance.authenticator.logout();

            instance.utils.createSimpleAlertModal(i18next.t('text.upgrade_account_successful'), 
                                                  undefined, 10000, i18next.t('text.close'))
            .then(function() {
                // back to login
                window.location.href = "/login.html";
            })
            .catch(function() {
                // when the modal timeouts also back to login
                window.location.href = "/login.html";
            });

        } catch (errorResponse) {
            if (errorResponse.status == 400) {
                M.toast({html: i18next.t('toasts.check_input')});
                instance._processFieldErrorsFromServer(errorResponse);
                return;
            } else {
                M.toast({html: i18next.t('toasts.error')});
                instance.log.error('User data could not be sent to ProDuck.', errorResponse);
                return;
            }
        }
    }

    /**
     * If form validation has not been complete before sending data to the service the server
     * will return an appropriate error message in case of an error. This message will process
     * that answer an mark faulty fields.
     */
    _processFieldErrorsFromServer(response) {
        let instance = this;
        let errors = JSON.parse(response.responseText).errors;

        instance.log.debug('Server returned the following validation errors: ', errors);

        for (let item in errors) {
            if(!errors.hasOwnProperty(item)) continue;

            if (errors[item] == 'MISSING') {
                $('#' + item + 'Input').addClass('invalid');
            } else if (errors[item] == 'INVALID') {
                $('#' + item + 'Input').addClass('invalid');
            } else {
                instance.log.error('Encountered unknown validation error type: ', errors[item]);
            }
        }
    }
}
