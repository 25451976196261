/* global monstecLib */
/* global M */

/**
 * Edit field meant to replace a displayed text and enable the user to change it.
 *
 * Define the methods 'onCommit' and 'onCancel' to react on clicks on the two buttons of this control element.
 * 'onCommit' may be async, 'onCancel' must not be async.
 *
 * Define 'onGetContent' to define a custom source for the initial content of the textarea in the inplace-edit-field
 * instead of just taking the inner html out of the defined source element. The result of 'onGetContent' will be
 * treated as string. The method may be async.
 *
 * Define 'onBeforeRender(value)' to perform operations on the field's value right before it will be rendered to
 * the source element. The method must not be async.
 */
export default class InPlaceEditField {
    /**
     * Create a new in place edit field that can be linked to a single source element.
     *
     * @param {*} source the DOM element that will contain the string the edit fireld will be prefeilled with and that
     *                   will be injected with the content of the edit field on a commit.
     *
     * @param {boolean} wysiwyg defines if the textarea of the current instance will be decorated with a wysiwyg bar
     */
    constructor(source, wysiwyg, scrollCorrection) {
        this.source = source;
        this.wysiwyg = wysiwyg;
        this.scrollCorrection = scrollCorrection;
        this.utils = monstecLib.produckContext.utils;

        this.log = new monstecLib.Log(1);
    }

    /**
     * Creates and shows the an editable text area for user input and hides the source element. Call this method
     * following a user action that should trigger the appearance of the actual input element replacing the
     * static display element linked to the in-place-edit-field as source.
     */
    async replaceSource() {
        const instance = this;
        if (!instance._checkPrerequisites()) return;

        let editArea = $('<div class="in-place-edit-field">'
            + '  <textarea class="js-html-input-area wswg-enabled inplace-edit-field-textarea" type="text"></textarea>'
            + '  <div class="js-visual-input-area in-place-edit-field-visual hide"></div>'
            + '  <div class="save-options">'
            + '    <div class="edit-item">'
            + '      <button type="submit" class="js-commit-button btn-small prdk-btn icon-btn edit-button waves-effect waves-light"><i class="material-icons">check</i></button>'
            + '    </div>'
            + '    <div class="edit-item">'
            + '      <button type="cancel" class="js-cancel-button btn-small prdk-btn icon-btn edit-button waves-effect waves-light"><i class="material-icons">cancel</i></button>'
            + '    </div>'
            + '  </div>'
            + '</div>');

        let commitButton = editArea.find('.js-commit-button');
        commitButton.on('click', async function(event) {
            event.stopImmediatePropagation();

            if (instance.onCommit) {
                try {
                    instance.utils.addButtonLoader(commitButton);
                    let finished = await instance.onCommit();

                    if (finished) {

                        let fieldValueUnwrapped = editArea.find('.js-html-input-area').val();                         

                        let fieldValue = instance.wysiwyg ? instance.utils.wrapParagraphs(fieldValueUnwrapped) : fieldValueUnwrapped;   

                        if (instance.onBeforeRender) {
                            fieldValue = await instance.onBeforeRender(fieldValue);
                        }

                        instance.source.html(fieldValue);
                        instance._tidyUp();
                    } else {
                        instance.log.debug('oncommit not finished.');
                    }

                    instance.utils.removeButtonLoader(commitButton);

                } catch(e) {
                    instance.log.error('oncommit threw an error!', e);
                    instance.utils.removeButtonLoader(commitButton);
                }
            } else {
                instance._tidyUp();
            }
        });

        editArea.find('.js-cancel-button').on('click', async function(event) {
            event.stopImmediatePropagation();

            if (instance.onCancel) {
                instance.onCancel();
            }

            instance._tidyUp();
        });

        let content;
        if (instance.onGetContent) {
            try {
                // Use a Semaphore-like structure to be able to prevent mutliple openings of the textfield
                // during data retrieval by onGetContent.
                instance.blocked = true;
                content = await instance.onGetContent();
                instance.blocked = false;
            } catch (e) {
                instance.log.error("Fetching of content failed, cannot open edit field", e);
                instance.blocked = false;
                instance.source.removeClass('hide');
                return;
            }
        } else {
            content = instance.source.html().trim();
        }

        let inputTextarea = editArea.find('.js-html-input-area');

        inputTextarea.val(content);

        inputTextarea.on('click keypress keydown keyup', function(event) {
            // prevents clicks and key strokes to trigger behaviour of elements 'under' the textarea
            event.stopPropagation();
        });

        // If the instance variable 'wysiwyg' has been set to true during the construction of the inplace-edit-field,
        // a bar of utility-buttons will be shown to the user above the text area.
        if (this.wysiwyg) {
            instance.utils.addWswgBar(inputTextarea);
        }

        instance.source.addClass('hide');
        instance.editArea = editArea;
        editArea.insertAfter(instance.source);
        instance.utils.adjustTextarea(inputTextarea, 1500);

        //keep on last position to ensure proper working
        if (typeof this.scrollCorrection !== 'undefined' || this.scrollCorrection !== null) {
            instance.utils.adjustScrollBugInTextarea(inputTextarea, this.scrollCorrection);
        }
        return content; // will be a promise in case of a asynchronous onGetContent-operation
    }

    /**
     * Get the value of the textarea on the current instance. This method will return 'undefined' if the edit field is
     * not currently in editing mode.
     */
    getValue() {
        const instance = this;

        if (!instance.editArea) {
            return undefined;
        } else {

            let fieldTextarea = instance.editArea;
            let fieldValueUnwrapped = fieldTextarea.find('.js-html-input-area').val();

            let fieldValue = instance.wysiwyg ? instance.utils.wrapParagraphs(fieldValueUnwrapped) : fieldValueUnwrapped;   

            return fieldValue;
        }
    }

    /*
     * Checks if all prerequisites are fulfilled to replace the source and returns true
     * if so, otherwise false.
     *
     * Internal method! Not to be called on instances of this class.
     */
    _checkPrerequisites() {
        const instance = this;

        if (!instance.source) {
            instance.log.error('No source defined!');
            return false;
        }

        if (instance.source.length > 1) {
            instance.log.error('Source must be a single element!');
            return false;
        }

        if (!!instance.blocked || !!instance.editArea) {
            instance.log.debug('Already in visible mode or about to be visible!');
            return false;
        }

        return true;
    }

    /*
     * After editing has been completed or cancelled, this method will restore the state of the
     * page-area that was replaced by the inplace-edit-field.
     *
     * Internal method! Not to be called on instances of this class.
     */
    _tidyUp() {
        const instance = this;
        instance.editArea.remove();
        instance.editArea = undefined;
        instance.source.removeClass('hide');
    }

}
