/* global monstecLib */
/* global M */
/* global MAX_OPEN_CHATS */ //defined in xpert.html
import i18next from "./localisation.js";
import Canvas from "./canvas.js";
import "js-cookie";

// class for functionality required in xpert page
// TODO split by functionality and not only by page so common functions can be reused
export default class Xpert {
    constructor(
        chatServiceAccess,
        identityServiceAccess,
        billingServiceAccess,
        externalChatClient,
        externalChatSupport,
        externalAuthenticator,
        externalCookie,
        externalWsClient,
        externalProfileHandler,
        externalProductHandler
    ) {
        // create and configure chatclient
        this.constants = new monstecLib.Constants();
        this.utils = new monstecLib.Utils();
        this.chatService = chatServiceAccess;
        this.identityService = identityServiceAccess;
        this.billingService = billingServiceAccess;
        this.chatclient = externalChatClient;
        this.cookie = externalCookie;
        this.chatSupport = externalChatSupport;
        this.chatclient.setChatServiceClient(chatServiceAccess);
        this.chatclient.setIdentityServiceClient(identityServiceAccess);
        this.chatclient.configure({
            icr: this.notifyChatRequest.bind(this),
            ire: function (chatId) {
                this.expireRequestForChat(chatId);
                this._invalidateIncomingRequestDialogue(chatId);
                this.determineVisibilityOfRequestListTidyButton();
            }.bind(this),
            ira: function (chatId, userId) {
                this._invalidateIncomingRequestDialogueForSiblingAcceptance(
                    chatId,
                    userId,
                    0
                );
                this.determineVisibilityOfRequestListTidyButton();
            }.bind(this),
            ird: function (chatId, userId) {
                this._invalidateIncomingRequestDialogueForSiblingAcceptance(
                    chatId,
                    userId,
                    1
                );
                this.determineVisibilityOfRequestListTidyButton();
            }.bind(this),
            nom: this.chatSupport.getMessages.bind(this.chatSupport),
            imd: this.chatSupport.markMessageDelivered.bind(this.chatSupport),
            imr: this.chatSupport.markMessageRead.bind(this.chatSupport),
            not: this.chatSupport.signalTyping.bind(this.chatSupport),
            ice: this.onChatEndedByChatPartner.bind(this),
        });
        this.authenticator = externalAuthenticator;
        this.wsClient = externalWsClient;
        this.profiledatahandler = externalProfileHandler;
        this.producthandler = externalProductHandler;
        this.canvas = null;
        this.currentChatFilter = null;
        this.portalSupport = null;
        this.canvas = new monstecLib.Canvas(
            this.chatService,
            this.billingService
        );

        this.log = new monstecLib.Log(1);
    }

    async initialise() {
        var instance = this;
        this.target = this.utils.getValueFromAnchor("target");
        this.portalSupport = new monstecLib.PortalSupport(
            instance.authenticator,
            instance.cookie,
            instance.profiledatahandler,
            instance.chatService
        );

        instance.setUserIdInChatclient();
        instance.updateChatRequestList();
        this.chatclient.addReconnectAction(
            instance.updateChatRequestList.bind(instance)
        );
        this.chatclient.addReconnectAction(function () {
            let chatContainers = $(".cl[data-chatid]");
            for (let i = 0; i < chatContainers.length; i++) {
                let chatId = $(chatContainers[i]).attr("data-chatid");
                instance.chatSupport.getMessages(chatId);
            }
        });

        const authChecker =
            await instance.authenticator.getSynchronousPermissionChecker();

        instance.initArchive(authChecker);

        instance.questionSection = new monstecLib.QuestionSection(authChecker);
        instance.questionSection.attachTo("targetQuestion");
        monstecLib.pageComponentRegistry.register(
            "targetQuestion",
            instance.questionSection
        );

        instance.articleSection = new monstecLib.ArticleSection(authChecker);
        instance.articleSection.attachTo("targetArticle");
        monstecLib.pageComponentRegistry.register(
            "targetArticle",
            instance.articleSection
        );

        instance.productSection = new monstecLib.ProductManagementSection(
            authChecker
        );
        instance.productSection.attachTo("target8");
        monstecLib.pageComponentRegistry.register(
            "target8",
            instance.productSection
        );

        instance.quacksSection = new monstecLib.ExpertChatsView();
        instance.quacksSection.attachTo("targetQuacks");
        monstecLib.pageComponentRegistry.register(
            "targetQuacks",
            instance.quacksSection
        );

        instance.settingsSection = new monstecLib.SettingsSection();
        instance.settingsSection.attachTo("target7");
        monstecLib.pageComponentRegistry.register(
            "target7",
            instance.settingsSection
        );

        window.onbeforeunload = function () {
            this.portalSupport.saveSessLocation();
        }.bind(this);
        this.portalSupport.refreshSite($("#refresh-btn-menu"));

        // INIT DASHBOARD MENU
        $("#chat-dashboard-mobile").on("click", function () {
            instance.canvas.fillDashboard();
        });

        // INIT ACTIVE CHATS SECTION MENU
        $("#menu-itm-chats").on("click", function () {
            instance.reconnectActiveChats();
        });

        // INIT QUESTION SECTION MENU
        $("#question-mobile").on("click", function () {
            instance.questionSection.initialise();
        });

        // INIT ARTICLE SECTION MENU
        $("#article-mobile").on("click", function () {
            instance.articleSection.initialise();
        });

        // INIT CHAT ADMINISTRATION MENU
        $("#chat-administration-mobile").on("click", function () {
            instance.quacksSection.triggerChatOverviewLoadByMenu();
        });

        // INIT SETTINGS MENU
        $("#general-settings-mobile").on("click", async function () {
            instance.settingsSection.initialise();
        });

        // INIT PROFILE MENU
        $("#xpert-profile-mobile").on("click", function () {
            instance.profiledatahandler.displayUserProfile();
        });

        // initialise the button for emptying the chat request list
        $("#request-list-empty-btn").on("click", function () {
            instance.removeExpiredRequestsFromList();
        });

        this.portalSupport.setLanguage();
        this.portalSupport.initSideNav();
        pretext.shortCutBtn();

        return this.portalSupport;
    } // End of intialise

    initArchive(authChecker) {
        if (
            authChecker.checkPermission(authChecker.permCat.READ_ORIGINAL_CHATS)
        ) {
            let provChatsView = new monstecLib.ProviderChatsView(
                "targetProvChats"
            );
            monstecLib.pageComponentRegistry.register(
                "targetProvChats",
                provChatsView
            );

            $("#chat-archive-mobile").click(function () {
                monstecLib.pageComponentRegistry
                    .getComponent("targetProvChats")
                    .buildChatList();
            });

            $("#chat-archive-mobile").removeClass("hide");
        } else {
            $("#chat-archive-mobile").remove();
            $("#targetProvChats").remove();
        }
    }

    async setUserIdInChatclient() {
        var instance = this;
        var uid = await instance.cookie.getUidFromCookies();
        if (uid) {
            instance.chatclient.setUserId(uid);
        }
    }

    initVideoChat(chatContainer, chatId) {
        var instance = this;
        chatContainer.find(".open_video_chat_room").click(function () {
            var partnerId = chatContainer.data("chatpartner");
            var chatRoomHref =
                "https://meet.jit.si/" + "ProDuck-" + chatId + "-" + partnerId;
            instance.log.debug(
                "Initialising video chat for chat " +
                    chatId +
                    " and user " +
                    partnerId
            );

            window.open(
                chatRoomHref,
                "sub",
                "height=1000,width=1000,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no, status=yes"
            );
            instance.chatclient.sendMessage(
                "Der Experte hat einen Videochat gestartet. Bitte klicke folgenden Link, um dem Videochat beizutreten: " +
                    chatRoomHref
            );
            pretext.autoTextEvent(
                "Der Link zum Videochat (" +
                    chatRoomHref +
                    ") wurde an deinen Chatpartner gesendet. Er bzw. sie kann damit deinem Videochat beitreten.",
                chatContainer.find(".chatlog"),
                "robot-msg"
            );
        });
    }

    /**
     * Fetches the current chat requests from the chat-service and updates the GUI-list with the new entries.
     */
    updateChatRequestList() {
        var instance = this;

        instance.chatService
            .getChatRequestList()
            .then(function (requestList) {
                var container = $("#target3 .chat-request-wrapper");
                container.empty();
                if (!requestList || requestList.length <= 0) {
                    instance.updateRequestCounter(requestList.length);
                } else {
                    //save the most recent chatId as checkpoint for webview exchange with app
                    var maxChatId = Math.max.apply(
                        null,
                        requestList.map((a) => a.id)
                    );
                    $(".chat-request-wrapper").attr(
                        "data-last-chatid",
                        maxChatId
                    );

                    instance.updateRequestCounter(requestList.length);

                    let requestPromises = [];
                    requestList.forEach(function (chatRequest) {
                        requestPromises.push(
                            instance.identityService
                                .getPublicUserData(chatRequest.userId)
                                .then((userData) => {
                                    return {
                                        request: chatRequest,
                                        user: userData,
                                    };
                                })
                        );
                    });

                    let addChatRequestToContainer = function (
                        chatRequest,
                        userData
                    ) {
                        var userName = userData ? userData.nickname : "unknown";

                        let productLink =
                            instance.utils.convertSourceLink(chatRequest);

                        container
                            .css({ "align-content": "" })
                            .append(
                                '<div class="chat-request-entity" id="chatRequest' +
                                    chatRequest.id +
                                    '" data-request-id="' +
                                    chatRequest.id +
                                    '" data-chat-id="' +
                                    chatRequest.chatId +
                                    '">' +
                                    '<h3 class="header" style="display:none">Horizontal Card</h3>' +
                                    '<div class="card horizontal">' +
                                    '<div class="card-image">' +
                                    '<img src="/assets/img/android-chrome-192x192.png">' +
                                    "</div>" +
                                    '<div class="card-stacked">' +
                                    '<div class="profile_info">' +
                                    '<h4 class="data_descr">' +
                                    userName +
                                    "</h4>" +
                                    '<p class="data_descr">' +
                                    chatRequest.topic +
                                    "</p>" +
                                    productLink +
                                    "</div>" +
                                    '<div class="card-action">' +
                                    '<div class="interact">' +
                                    '<span class="person_add"><a class="data_descr" href="#"><i class="material-icons md-light">person_add</i></a></span>' +
                                    "</div>" +
                                    '<div class="specialities">' +
                                    '<div class="panel_block">' +
                                    instance.utils.formatDate(
                                        new Date(chatRequest.requestTime)
                                    ) +
                                    "</div>" +
                                    "</div>" +
                                    "</div>" +
                                    "</div>" +
                                    "</div>" +
                                    "</div>"
                            );
                        let showChatRequestButton = container.find(
                            "#chatRequest" + chatRequest.id
                        );
                        if (chatRequest.status === "EXPIRED") {
                            instance.expireRequest(showChatRequestButton);
                        } else {
                            showChatRequestButton.on("click", function (ev) {
                                if (
                                    $(".data_descr.source_link > a").is(
                                        ev.target
                                    )
                                ) {
                                    ev.stopPropagation();
                                    return;
                                }

                                instance.transmitSearchText(chatRequest);
                            });
                        }
                    };

                    Promise.all(requestPromises)
                        .then(function (allResults) {
                            allResults.forEach(function (singeResult) {
                                addChatRequestToContainer(
                                    singeResult.request,
                                    singeResult.user
                                );
                            });

                            instance.determineVisibilityOfRequestListTidyButton();
                        })
                        .catch(function (e) {
                            instance.log.error(
                                "updateChatRequestList - Could not retrieve data of users.",
                                e
                            );
                        });
                }
            })
            .catch(function (error) {
                console.log("Request list could not be fetched.", error);
            });
    }

    determineVisibilityOfRequestListTidyButton() {
        if (
            $(".chat-request-wrapper").children("[data-expired=true]").length >
            0
        ) {
            $("#request-list-interaction-wrapper").css("display", "flex");
        } else {
            $("#request-list-interaction-wrapper").hide();
        }
    }

    /**
     * Updates the number behind the text of the navigation item for the chat request list.
     */
    updateRequestCounter(number) {
        if (!number) {
            number = $(".chat-request-wrapper").children().length;
        }

        if ($(".thumb-nav__item[data-href='#target3'] .number-counter")) {
            $(
                ".thumb-nav__item[data-href='#target3'] .number-counter"
            ).remove();
        }

        if (number > 0) {
            $(".thumb-nav__item[data-href='#target3']").append(
                '<span class="number-counter">' + number + "</span>"
            );
        } else {
            var container = $("#target3 .chat-request-wrapper");
            container.css({ "align-content": "center" });
            container.append(
                '<div id="no-request-active" class="no-result-indicator"><h3 data-i18n="text.no_requests"></h3></div>'
            );
            $("#no-request-active h3").localize();
        }
    }

    /**
     * Marks the chat request corresponding to a specific chat as expired.
     *
     * @param {*} chatId the technical identifier of the chat to expire a request for
     */
    expireRequestForChat(chatId) {
        let container = $(
            '.chat-request-entity[data-chat-id="' + chatId + '"]'
        );
        this.expireRequest(container);
    }

    expireRequest(container) {
        const instance = this;
        container.attr("data-expired", true);

        let profileName = container.find(".profile_info h4");
        profileName.text(
            "(" + i18next.t("text.expired") + ") " + profileName.text()
        );

        let requestId = container.attr("data-request-id");

        let interactions = container.find(".interact");
        interactions.children().remove();
        interactions.append(
            '<span class="remove"><a class="data_descr" href="#"><i class="material-icons md-light">close</i></a></span>'
        );

        container.off("click");
        container.on("click", function (ev) {
            if ($(".data_descr.source_link > a").is(ev.target)) {
                ev.stopPropagation();
                return;
            }

            var headline = i18next.t("text.request_remove");
            var text = i18next.t("text.request_remove_enquiry");
            var optionOne = i18next.t("general.yes");
            var optionTwo = i18next.t("general.cancel");

            //creates a suitable modal
            instance.utils.createModal(
                $("body"),
                headline,
                text,
                optionOne,
                optionTwo,
                "remove-chat-request-modal"
            );

            // call server endpoint for removing the expired request when the user clicks 'yes' and remove the request from the list
            $(document)
                .off("click", "#remove-chat-request-modal .option-one")
                .on("click", ".modal-close.option-one", function () {
                    instance.chatService
                        .removeRequest(requestId)
                        .then(function () {
                            container.remove();
                            instance.updateRequestCounter();
                            instance.determineVisibilityOfRequestListTidyButton();
                        });
                });
        });
    }

    /**
     * Removes a single chat-request from the list of chat-requests.
     *
     * @param {number} requestId the technical identifier of the chat-request to remove from the request list
     */
    removeChatRequestFromList(requestId) {
        this.log.debug(
            "Removing container for chat request " + requestId + " from list."
        );
        this._removeChatRequestContainerFromList($("#chatRequest" + requestId));
    }

    removeChatRequestForChatFromList(chatId) {
        this.log.debug(
            "Removing container for chat request of chat " +
                chatId +
                " from list."
        );
        this._removeChatRequestContainerFromList(
            $('.chat-request-entity[data-chat-id="' + chatId + '"]')
        );
    }

    _removeChatRequestContainerFromList(container) {
        var instance = this;
        if (!container) {
            this.log.debug("Chat Requets Container already removed.");
            return;
        }

        let listContainer = container.parent();
        container.remove();

        let numberOfRemainingRequests = listContainer.children().length;

        if (numberOfRemainingRequests === 0) {
            // if the last request got removed, show an appropriate info
            instance.updateRequestCounter(numberOfRemainingRequests);
        } else {
            // if otherwise there are still requests left, update the counter
            $(
                ".thumb-nav__item[data-href='#target3'] .number-counter"
            ).remove();

            $(".thumb-nav__item[data-href='#target3']").append(
                '<span class="number-counter">' +
                    numberOfRemainingRequests +
                    "</span>"
            );
        }
    }

    /**
     * Removes all expired chat requests from the chat request list and send a request to the server to mark those
     * chat requests as removed from view.
     */
    removeExpiredRequestsFromList() {
        const instance = this;
        let requestContainerList = $(".chat-request-wrapper").children(
            "[data-expired=true]"
        );

        var headline = i18next.t("text.request_remove_all");
        var text = i18next.t("text.request_remove_all_enquiry");
        var optionOne = i18next.t("general.yes");
        var optionTwo = i18next.t("general.cancel");

        //creates a suitable modal
        this.utils.createModal(
            $("body"),
            headline,
            text,
            optionOne,
            optionTwo,
            "remove-all-chat-requests-modal"
        );

        // call server endpoint for removing the expired request when the user clicks 'yes' and remove the request from the list
        $(document)
            .off("click", "#remove-all-chat-requests-modal .option-one")
            .on(
                "click",
                "#remove-all-chat-requests-modal .option-one",
                function () {
                    instance.chatService
                        .removeExpiredRequests()
                        .then(function () {
                            // remove all containers in the list
                            requestContainerList.remove();

                            // hide the tidy up button
                            $("#request-list-interaction-wrapper").hide();

                            // update request counter
                            instance.updateRequestCounter();
                        });
                }
            );
    }

    _invalidateIncomingRequestDialogue(chatId) {
        var instance = this;
        instance.expireRequestForChat(chatId);
        instance.determineVisibilityOfRequestListTidyButton();

        var dialogue = $(".incomingRequest[data-chatid=" + chatId + "]");
        if (dialogue.length > 0) {
            // add info to dialogue
            dialogue
                .find(".card-content")
                .append("<p>" + i18next.t("text.request_preempted") + "</p>");

            // remove Buttons and add a new one
            var cardActionContainer = dialogue.find(".card-action");
            cardActionContainer.find("a").remove();
            cardActionContainer.append(
                '<a class="confirmAbort" href="#">' +
                    i18next.t("text.done") +
                    "</a>"
            );
            cardActionContainer.find(".confirmAbort").click(function () {
                dialogue.remove();
            });
        }
    }

    /**
     * Invalidates a chat request dialogue because of the action in another session of the user.
     *
     * @param {*} chatId the technical identifier of the chat
     * @param {*} type 0 -> accept, 1 -> decline
     */
    _invalidateIncomingRequestDialogueForSiblingAcceptance(
        chatId,
        userId,
        type
    ) {
        const instance = this;
        instance.removeChatRequestForChatFromList(chatId);
        instance.determineVisibilityOfRequestListTidyButton();

        instance._buildChatViewContent();

        let dialogue = $(".incomingRequest[data-chatid=" + chatId + "]");
        if (dialogue.length > 0) {
            // add info to dialogue
            let textIdentifier =
                type == 0
                    ? "request_accepted_by_sibling"
                    : "request_declined_by_sibling";
            dialogue
                .find(".card-content")
                .append("<p>" + i18next.t("text." + textIdentifier) + "</p>");

            // remove Buttons and add a new ones
            let cardActionContainer = dialogue.find(".card-action");
            cardActionContainer.find("a").remove();

            if (type == 0) {
                let chatLink = $(
                    '<a class="confirmRequest" href="#">' +
                        i18next.t("text.to_chat") +
                        "</a>"
                );
                chatLink.click(function () {
                    dialogue.remove();
                    $("#navItemChat").click();
                });
                cardActionContainer.append(chatLink);
            }

            let stayLink = $(
                '<a class="postponeRequest" href="#">' +
                    i18next.t(type == 0 ? "text.stay_here" : "general.ok") +
                    "</a>"
            );
            stayLink.click(function () {
                dialogue.remove();
            });
            cardActionContainer.append(stayLink);
        }
    }

    _initChatCloseButtons(chatContainer, chatId) {
        let instance = this;

        // handles all transformations on active side when closing chat
        chatContainer
            .find(".leave_chat, #chatClosingBtn")
            .on("click", function () {
                if (chatId > 0) {
                    // if there is no chatId no actual chat has been established so there is no need to inform the server
                    // since the chat will already be DECLINED anyway
                    instance.chatclient.wsStopChat(chatId);
                }

                chatContainer.data("chatid", "-1").attr("data-chatid", "-1"); // -1 cancels the connection between two chatpartners,
                // this does change the data-chatid (node element) and
                // the data-object

                let signal = $(
                    "#chat-online-signal" + chatId + " .inner-circle"
                );
                signal
                    .removeClass("signal-online")
                    .attr("title", i18next.t("text.closed"));

                if ($("body#xpert").length > 0) {
                    instance.onChatEnded(chatId, chatContainer);
                }
            });
    }

    _initChatCloseButton(chatContainer, chatId) {
        let instance = this;

        // close chat window
        chatContainer.find(".close_chat").on("click", function () {
            const chatTabWrapper = $("#chat_tab_wrapper");

            function closeChatWin() {
                chatContainer.remove();
                chatTabWrapper
                    .find(".tabs > .tab > a.active")
                    .parent(".tab")
                    .remove();
                chatTabWrapper
                    .find(".tabs > .tab")
                    .last()
                    .find("a")
                    .addClass("active");
                chatTabWrapper.find(".tabs").tabs();
            }

            let numberOfTabs = chatTabWrapper.find(".tabs > .tab").length;

            if (numberOfTabs > 2) {
                closeChatWin();
            } else if (numberOfTabs === 2) {
                $(".cl.active").remove();
                chatTabWrapper
                    .find(".tabs > .tab > a.active")
                    .parent(".tab")
                    .remove();
                chatTabWrapper.css({ display: "none" });
                $(".chat_wrapper").addClass("onechat");
                $(".cl").last().addClass("active").css({ display: "block" });
            } else if (numberOfTabs === 1) {
                $(".cl.active").remove();
                chatTabWrapper.remove();
                $(".chat_wrapper").removeClass("onechat");
                $("#no-chat-active").show();
            }
        });
    }

    /*
     * This function enables the initiation of different menu items from the url that is called.
     * It uses the # Anchor i.e. #target1, which is called in utils.js
     */
    async deepLinkRef() {
        const instance = this;

        let calledTarget = "#target" + this.target;
        let sectionSubTarget;
        let windowHref = window.location.href;
        let sessStorage = await instance.cookie.getRightStorage("produck");
        let uid = await instance.cookie.getUidFromCookies();

        // if target in url and is not target2 (chat)
        if (windowHref.indexOf("#target") > -1) {
            $(calledTarget).addClass("active");
        } else if (sessStorage && sessStorage.location) {
            $("#" + sessStorage.location).addClass("active");
            this.target = sessStorage.location.split("target").pop();
        } else {
            $("#targetArticle").addClass("active");
            sectionSubTarget = "articleSectionNavigation";
            instance.articleSection.initialise();
        }

        //activate target when dashboard is called
        if (parseInt(this.target) === 1) {
            instance.canvas.fillDashboard();
            Canvas.called = true;
        }

        // target2: reconnect active chats
        if (parseInt(this.target) === 2) {
            instance.reconnectActiveChats();
        }

        //target3 (chat requests) is always initialized on load

        //activate target when profile is called
        if (parseInt(this.target) === 5) {
            instance.profiledatahandler.displayUserProfile();
        }

        //activate target when quacksoverview is called
        if (this.target === "Quacks") {
            instance.quacksSection.triggerChatOverviewLoadByMenu();
        }

        // define subsection navigation for question section
        if (this.target === "Question") {
            sectionSubTarget = "questionSectionNavigation";
            instance.questionSection.initialise();
        }

        // define subsection navigation for article section
        if (this.target === "Article") {
            sectionSubTarget = "articleSectionNavigation";
            instance.articleSection.initialise();
        }

        //activate target when archive is called
        if (this.target === "ProvChats") {
            monstecLib.pageComponentRegistry
                .getComponent("targetProvChats")
                .buildChatList();
        }

        //activate target when settings are called
        if (uid && parseInt(this.target) === 7) {
            async function fnc() {
                instance.settingsSection.initialise(uid);
            }
        }

        //activate target when products are called
        if (uid && parseInt(this.target) === 8) {
            instance.productSection.searchProductsForUser(uid);
        }

        // open certain tab
        if (sectionSubTarget && sessStorage && sessStorage.targetTab) {
            $("#" + sectionSubTarget).tabs("select", sessStorage.targetTab);
        }
    }

    /**
     * Event Handler that is intended to be called, when a chat gets ended by the chat partner.
     *
     * @param {number} chatId the ID of the chat that has been ended
     * @param {number} userId the ID of the user that has left the chat
     * @param {Object} chatLog a DOM object representing the chat log (if not given the method will
     *                         look for one using the chatId)
     */
    async onChatEndedByChatPartner(chatId, userId, chatLog) {
        var instance = this;
        // if no chatLog is given, check if there is one associcated with the chatId at all
        if (!chatLog) {
            chatLog = $(".cl[data-chatid=" + chatId + "]");
        }

        // If a chatLog could be found, the current user has already started the chat so he must be alarmed
        // that the other user has left. Otherwise the chat is only in the request list. Otherwise the chat
        // requesting user has ended the chat before an expert accepted it. So the chat must be removed from
        // the request list.
        if (chatLog.length > 0) {
            let currentUser;
            try {
                currentUser = await instance.authenticator.getUser();
            } catch (e) {
                currentUser = -1;
            }

            var partnerName = $(
                ".cl[data-chatid=" + chatId + "] .xpertside"
            ).text();
            var textBody =
                currentUser != userId
                    ? partnerName + i18next.t("modals.chat_left")
                    : i18next.t("modals.chat_left_sibling_session");
            instance.utils.createSimpleAlertModal(textBody);
            instance.onChatEnded(chatId, chatLog);
        } else {
            instance.expireRequestForChat(chatId);
            instance.determineVisibilityOfRequestListTidyButton();

            // additionally the chat request info dialogue could be open
            var dialogue = $(".incomingRequest[data-chatid=" + chatId + "]");
            if (dialogue.length > 0) {
                // add info to dialogue
                dialogue
                    .find(".card-content")
                    .append(
                        "<p>" + i18next.t("text.request_cancelled") + "</p>"
                    );

                // remove Buttons and add a new one
                var cardActionContainer = dialogue.find(".card-action");
                cardActionContainer.find("a").remove();
                cardActionContainer.append(
                    '<a class="confirmAbort" href="#">' +
                        i18next.t("text.done") +
                        "</a>"
                );
                cardActionContainer.find(".confirmAbort").click(function () {
                    dialogue.remove();
                    //the chat request will stay in the list as expired
                });
            }
        }
    }

    /**
     * Event Handler that is intended to be called, when a chat has ended.
     *
     * @param {number} chatId the ID of the chat that has been ended
     * @param {Object} chatLog a DOM object representing the chat log (if not given the method will
     *                         look for one using the chatId)
     */
    onChatEnded(chatId, chatLog) {
        let instance = this;

        var currentChatLog;
        if (chatLog) {
            currentChatLog = chatLog;
        } else {
            currentChatLog = $(".cl[data-chatid=" + chatId + "]");
        }

        currentChatLog
            .find(".leave_chat")
            .addClass("close_chat")
            .attr("title", i18next.t("text.close_chat"));
        currentChatLog.find(".close_chat > i").html("close");
        currentChatLog.find(".close_chat").removeClass("leave_chat");
        currentChatLog.find(".chat_footer").remove();
        currentChatLog.find(".chatlog").css("height", "100%");
        $(
            ".chat-overview-collapsible-body tr[data-chatid=" + chatId + "]"
        ).remove();

        // when a chat is closed the expert gets the option to mark the chat as a quack
        var quackifySection = currentChatLog.find(".chat-quackify-section");
        quackifySection.attr("data-chatid", chatId);
        var ChatlogInCl = currentChatLog.find(".chatlog")[0];
        ChatlogInCl.scrollTop = 0;

        // init tag module
        function addTag(chipsElement) {
            let textNodeOfNewChip = chipsElement.find(".chip").last()[0]
                .childNodes[0];
            let textOfNewChip = textNodeOfNewChip.data;
            textNodeOfNewChip.data = textOfNewChip
                .trim()
                .replaceAll(/[^a-zA-Z0-9äöüÄÖÜß\.-]+/g, "-");
        }

        var chipsOptions = {
            placeholder: i18next.t("toasts.chips"),
            limit: 5,
            onChipAdd: function (chips) {
                addTag(chips);
            },
        };

        var chipsElement = currentChatLog.find(".quackify-tags");
        M.Chips.init(chipsElement, chipsOptions); // hint: returns instances

        // initialise the language and domain select
        var lngOptions = {
            classes: "edit-language",
        };
        var domainOptions = {
            classes: "edit-domain",
        };

        var languageSelect = currentChatLog.find(".quackLngSelect");
        var domainSelect = currentChatLog.find(".quackDomainSelect");
        M.FormSelect.init(languageSelect, lngOptions);
        M.FormSelect.init(domainSelect, domainOptions);

        // transfer the initial message of the user as suggestion for the topic of the quack
        var quackTopicField = currentChatLog.find(".quack-topic-field");
        quackTopicField.val(currentChatLog.attr("data-first-message"));

        // finally hide the chat messages and show the quackify section instead
        currentChatLog.find(".chat").hide(); // hide text-messages in chatlog
        currentChatLog.find("button").hide(); // hide buttons in chatlog
        quackifySection.show();
        instance._initChatCloseButton(currentChatLog, chatId);
    }

    /**
     * @param {object} chatRequest must be of the following form:
     *                             chatRequest = {
     *                                 id: [number],
     *                                 chatId: [number],
     *                                 userId: [number],
     *                                 topic: [string],
     *                                 requestTime: [date],
     *                                 product: [string],
     *                                 link: [string]
     *                             };
     */
    transmitSearchText(chatRequest) {
        var initialTitle = document.title;
        var instance = this;
        var requestWrapper = '<ul id="newRequests"></ul>';

        let chatRequestId = chatRequest.id,
            chatId = chatRequest.chatId,
            userId = chatRequest.userId,
            chatTopic = chatRequest.topic,
            chatTime = chatRequest.requestTime,
            product = chatRequest.product,
            link = chatRequest.link;

        var productReference;
        if (product && link) {
            productReference =
                '<p class="product-link-wrapper"><a href="' +
                link +
                '" target="_blank">' +
                product +
                "</a></p>";
        } else if (link) {
            productReference =
                '<p class="product-link-wrapper"><a href="' +
                link +
                '" target="_blank">' +
                i18next.t("text.product_ref") +
                "</a></p>";
        } else if (product) {
            productReference =
                '<p class="product-link-wrapper">' + product + "</p>";
        } else {
            productReference = "";
        }

        // remove any previous open incoming request dialogue
        $(".incomingRequest").remove();

        var requestModal =
            '<li class="incomingRequest" style="display:none;"><div class="card teal lighten-1">' +
            '<div class="card-content white-text">' +
            '<span class="card-title">' +
            i18next.t("text.request_info") +
            "</span></div>" +
            '<div class="card-action">' +
            '<a class="confirmRequest" data-href="#target2">' +
            i18next.t("text.accept") +
            "</a>" +
            '<a class="declineRequest" data-href="#">' +
            i18next.t("text.decline") +
            "</a>" +
            '<a class="postponeRequest" data-href="#">' +
            i18next.t("text.later") +
            "</a></div></div></div></li>";

        if ($("#newRequests").length < 1) {
            $("body").append(requestWrapper);
        }

        $("#newRequests").append(requestModal);
        var incomingRequestDialogue = $(".incomingRequest");
        incomingRequestDialogue.attr("data-requestId", chatRequestId);
        incomingRequestDialogue.attr("data-chatid", chatId);

        const card = incomingRequestDialogue.find(".card-content").first();
        const liNmbr = incomingRequestDialogue.length;
        const currentConfirmBtn = $(
            ".incomingRequest[data-requestId=" +
                chatRequestId +
                "] .confirmRequest"
        );
        const currentPostponeBtn =
            incomingRequestDialogue.find(".postponeRequest");
        const currentDeclineBtn = $(
            ".incomingRequest[data-requestId=" +
                chatRequestId +
                "] .declineRequest"
        );
        const searchText = document.createElement("p"); // container for text itself
        searchText.className = "query";
        var chatTimeConv = new Date(chatTime).toLocaleTimeString();
        var chatDateConv = new Date(chatTime).toDateString();

        function initChatCard(nickname) {
            card.append(searchText);
            searchText.innerHTML =
                i18next.t("text.from") +
                " " +
                nickname +
                " - " +
                chatTopic +
                " (" +
                chatDateConv +
                " " +
                chatTimeConv +
                ")";
            card.append(productReference);
        }

        function assignButtonFunctionality(userData, userId) {
            instance.portalSupport.slideInChatContainer(
                currentConfirmBtn,
                chatId
            );

            currentConfirmBtn.on("click", async function () {
                let ackResponse;
                try {
                    ackResponse = await instance.chatclient.acknowledgeChat(
                        chatId
                    );
                } catch (error) {
                    // acknowledgment is in conflict with another user's action
                    instance._invalidateIncomingRequestDialogue(chatId);
                    return;
                }

                instance.chatSupport.extendChat(
                    chatId,
                    userData.nickname,
                    userId,
                    ackResponse.providerId,
                    true
                );
                var chatContainer = $(".cl[data-chatid=" + chatId + "]");
                var chatlog = chatContainer.find(".chatlog.conversation-card");
                instance.cookie.updateChatHeader(userData, chatId, chatTopic);
                instance.initVideoChat(chatContainer, chatId);
                instance._initChatCloseButtons(chatContainer, chatId);

                incomingRequestDialogue.remove();

                pretext.addExpertChatRequestInfo(
                    chatlog,
                    chatTopic,
                    product,
                    link
                );

                document.title = initialTitle;

                // Fetch all messages the user might have sent while the chat request has been pending.
                instance.chatSupport.getMessages(chatId);

                // update the chat-request-list because the achknowledged chat must be removed
                instance.removeChatRequestFromList(chatRequestId);
            });

            currentPostponeBtn.on("click", function () {
                incomingRequestDialogue.remove();
                document.title = initialTitle;
            });

            currentDeclineBtn.on("click", currentDeclineBtn, function () {
                incomingRequestDialogue.remove();
                instance.chatclient.submitRequestDeclined(chatId);
                document.title = initialTitle;

                // update the chat-request-list because the declined chat must be removed
                instance.removeChatRequestFromList(chatRequestId);
            });
        }

        async function showRequestDialogue() {
            var response;
            try {
                response = await instance.identityService.getPublicUserData(
                    userId
                );
            } catch (error) {
                console.log("Error:", error);
            } finally {
                // regardless whether the user information (i.e. the nickname) could be fetched or not fill the
                // card and assign button functionality
                var userData = { nickname: "Ducky" };

                if (response) {
                    userData = response;
                }

                initChatCard(userData.nickname);
                assignButtonFunctionality(userData, userId);

                incomingRequestDialogue.show();
                instance.utils.controlSoundPlay();

                document.title =
                    "(" + liNmbr + ") " + i18next.t("text.new_request");
            }
        }

        showRequestDialogue();
    }

    /**
     * Will be called, when an expert receives a notification about an incoming chat request.
     *
     * @param {object} payload the payload of the websocket icr message that leads to the call of this method
     */
    notifyChatRequest(payload) {
        // only directly show the notification if the expert is online
        if ($("#os-checkbox-mobile").is(":checked")) {
            let chatRequest = {
                id: payload.requestId,
                chatId: payload.chatId,
                userId: payload.userId,
                topic: payload.topic,
                requestTime: $.now(),
                product: payload.product,
                link: payload.link,
            };
            this.transmitSearchText(chatRequest);
            $(".incomingRequest").last().css({ display: "block" });
        }

        // update the chat-request-list because the requested chat must be added
        this.updateChatRequestList();
    }

    initQuackKeyGenerationFunctions() {
        var instance = this;
        var container = $("#produckQuackTokenContainer");
        var addButton = $("#addQuackTokenButton");
        var maxTokens = 20;

        // Prefill the input fields with tokens and domains
        function prefillInputFields(response) {
            container.empty(); // Clear the container
            response.forEach((item, tokenCount) => {
                var newTokenField = $(
                    '<div class="token-field">' +
                        '<div class="input-field"><input type="text" id="produckDomainField_' +
                        tokenCount +
                        '" name="quackDomains[]" size="25" value="' +
                        item.domain +
                        '" class="validate" pattern="^(?!produck.de$)(?!www.)[a-zA-Z0-9\\-]+\\.[a-zA-Z]{2,6}" />' +
                        '<label for="produckDomainField_' +
                        tokenCount +
                        '">Domain</label>' +
                        '<span class="helper-text" data-success="Domain format correct" data-error="Domain format incorrect" tabindex="-1"></span>' +
                        "</div>" +
                        '<div class="input-field"><input type="text" disabled id="produckQuackTokenField_' +
                        tokenCount +
                        '" name="quackTokens[]" size="25" value="' +
                        item.token +
                        '"/>' +
                        '<label for="produckQuackTokenField_' +
                        tokenCount +
                        '">Quack Token</label></div>' +
                        '<i class="material-icons content-copy" data-i18n="[title]text.copy" title="Kopieren">content_copy</i>' +
                        '<button type="button" class="btn prdk-btn generateTokenButton" data-i18n="text.generate_token">Generate Token</button>' +
                        '<button type="button" class="btn prdk-btn  deleteTokenButton" data-i18n="text.delete_token">Delete Token</button>' +
                        "</div>"
                );
                container.append(newTokenField);

                let copyBtn = $(newTokenField).find(".content-copy");
                let inputVal = $(newTokenField)
                    .find('input[name="quackTokens[]"]')
                    .val()
                    .trim();
                instance.utils.initCopytoClipboard(inputVal, copyBtn);
            });
        }

        // Add new token field
        addButton.on("click", function () {
            var tokenFields = container.find(".token-field");
            var tokenCount = tokenFields.length;

            if (tokenCount < maxTokens) {
                var newTokenField = $(
                    '<div class="token-field">' +
                        '<div class="input-field"><input type="text" id="produckDomainField_' +
                        tokenCount +
                        '" name="quackDomains[]" size="25" class="validate" pattern="^(?!produck.de$)(?!www.)[a-zA-Z0-9\\-]+\\.[a-zA-Z]{2,6}" />' +
                        '<label for="produckDomainField_' +
                        tokenCount +
                        '">Domain</label>' +
                        '<span class="helper-text" data-success="Domain format correct" data-error="Domain format incorrect"  tabindex="-1"></span>' +
                        "</div>" +
                        '<div class="input-field"><input type="text" disabled id="produckQuackTokenField_' +
                        tokenCount +
                        '" name="quackTokens[]" size="25" />' +
                        '<label for="produckQuackTokenField_' +
                        tokenCount +
                        '">Quack Token</label></div>' +
                        '<i class="material-icons content-copy" data-i18n="[title]text.copy" title="Kopieren">content_copy</i>' +
                        '<button type="button" class="btn prdk-btn generateTokenButton" data-i18n="text.generate_token">Generate Token</button>' +
                        '<button type="button" class="btn prdk-btn  deleteTokenButton" data-i18n="text.delete_token">Delete Token</button>' +
                        "</div>"
                );
                container.append(newTokenField);
                M.updateTextFields();
            } else {
                instance.utils.createSimpleAlertModal(
                    "You have reached the maximum number of tokens."
                );
            }
        });

        // Delete token field
        container.on("click", ".deleteTokenButton", function () {
            const $el = $(this);
            var tokenField = $el
                .siblings(".input-field")
                .find('input[name="quackTokens[]"]');

            if (!tokenField.val()) {
                instance.utils.createSimpleAlertModal(
                    "No token value found, thus token deletion could not be accomplished."
                );
            } else {
                if (
                    !confirm(
                        "Deleting a token will invalidate connections to external partner sites. Do you want to continue?"
                    )
                ) {
                    return;
                }
            }

            // Generate token using the chat service
            instance.chatService
                .deleteQuackToken(tokenField.val())
                .then(function () {
                    $el.parent().remove();
                    instance.utils.createSimpleAlertModal(
                        "Token successfully deleted. Please inform any external partner sites that were connected via this quack token."
                    );
                })
                .catch(function (error) {
                    console.log("Error deleting token:", error);
                    instance.utils.createSimpleAlertModal(
                        "Failed to delete token."
                    );
                });
        });

        function validateDuplicateDomains(domain, currentField) {
            let isDuplicate = false;
            $('input[name="quackDomains[]"]').each(function () {
                if (this !== currentField && $(this).val().trim() === domain) {
                    isDuplicate = true;
                    return false; // Exit loop
                }
            });
            return isDuplicate;
        }
        // Generate token
        container.on("click", ".generateTokenButton", async function () {
            var tokenInput = $(this)
                .siblings(".input-field")
                .find('input[name="quackTokens[]"]');
            var domainInput = $(this)
                .siblings(".input-field")
                .find('input[name="quackDomains[]"]');

            var copyBtn = $(this).siblings(".content-copy");

            var domain = domainInput.val().trim();

            if (!domain) {
                instance.utils.createSimpleAlertModal(
                    "A domain must be set to generate a token. Please enter the partner domain you want to get connected to first."
                );
                return;
            } else if (validateDuplicateDomains(domain, domainInput[0])) {
                instance.utils.createSimpleAlertModal(
                    "This domain is already in use."
                );
                return;
            }

            if (tokenInput.val()) {
                if (
                    !confirm(
                        "Generating a new token will invalidate the existing token and hence require to be updated on partner sites afterwards. Do you want to continue?"
                    )
                ) {
                    return;
                }
            }
            // Generate token using the chat service
            instance.chatService
                .generateQuackToken(domain)
                .then(function (response) {
                    tokenInput.val(response.token);
                    let inputVal = response.token;
                    instance.utils.initCopytoClipboard(inputVal, copyBtn);
                    instance.utils.createSimpleAlertModal(
                        "Token generated successfully. Please update the token on connected partner sites. Read more in our <a href=\"https://www.produck.de/docu/plugins.html#wordpress\" target=\"_black\">Docu</a>."
                    );
                    M.updateTextFields();
                })
                .catch(function (error) {
                    console.log("Error generating token:", error);
                    instance.utils.createSimpleAlertModal(
                        "Failed to generate token."
                    );
                });
        });

        // Fetch and prefill tokens and domains when the collapsible header is clicked
        let collapsibleHeader = $("#quack-key-collapsible");
        collapsibleHeader.on("click", async function () {
            if (!$(this).parent("li").hasClass("active")) {
                instance.utils.addLoader(
                    $("#quackkey-loader-container"),
                    "small"
                );
                console.log("Fetching tokens and domains.");

                instance.chatService
                    .getQuackTokensAndDomains()
                    .then(function (response) {
                        console.log("Fetching token successful: ", response);
                        prefillInputFields(response);
                        M.updateTextFields();
                        instance.utils.removeLoader(
                            $("#quackkey-loader-container")
                        );
                    })
                    .catch(function (error) {
                        console.log("Quack token service error: ", error);
                        instance.utils.createSimpleAlertModal(
                            i18next.t("text.no_token", {
                                buttonName: i18next.t("text.generate_token"),
                            })
                        );
                        instance.utils.removeLoader(
                            $("#quackkey-loader-container")
                        );
                    });
            }
        });
    }

    initDnsKeyGenerationFunctions() {
        var instance = this;
        var container = $("#dnsTokenContainer");
        var addButton = $("#addDnsTokenButton");
        var updateButton = $("#updateVerificationsButton");
        let collapsibleHeader = $("#dns-key-collapsible");
        var maxTokens = 20;

        function addAcceptanceSwitches(item) {
            let title = i18next.t("text.general.on") + "/" + i18next.t("text.general.off");
        
            const settingsArray = [
                { id: 'articleAcceptanceSwitch', name: 'articleAcceptanceStatus', label: 'settings.article_acceptance' },
                { id: 'chatAcceptanceSwitch', name: 'chatAcceptanceStatus', label: 'settings.chat_acceptance' }
            ];
        
            let switchWrapper = $('<div class="switch-wrapper flex-box w100 flex-wrap"><p class="w100" data-i18n="settings.acceptance_info"></p></div>');
        
            settingsArray.forEach(setting => {
                let switchHtml = `
                    <div class="switch w100" title="${title}">
                        <div class="notification-wrapper">
                            <span class="notification-name" data-i18n="${setting.label}"></span>
                        </div>
                        <div class="notification-label">
                            <label>
                                <span data-i18n="general.off"></span>
                                <input id="${setting.id}_${item.id}" data-name="${setting.name}" type="checkbox" autocomplete="off" />
                                <span class="lever"></span>
                                <span data-i18n="general.on"></span>
                            </label>
                        </div>
                    </div>`;
                switchWrapper.append(switchHtml);
            });
        
            return switchWrapper;
        }
        
        function initSwitches(switchWrapper, item) {
            console.log('initSwitches called ', $(switchWrapper), $(switchWrapper).find('input'));
        
            $(switchWrapper).find('input').each((index, elem) => {
                const $elem = $(elem);
                const dataName = $elem.data('name');
        
                // Set the initial checked state based on the item data
                $elem.prop('checked', item[dataName]);
        
                // Add change event listener to handle the saving of settings
                $elem.on('change', function() {
                    let payload = {
                        domain: item.domain,
                        settings: {
                            articleAcceptanceStatus: $('#articleAcceptanceSwitch_' + item.id).is(':checked'),
                            chatAcceptanceStatus: $('#chatAcceptanceSwitch_' + item.id).is(':checked')
                        }
                    };
        
                    console.log(payload);
        
                    instance.identityService.saveUserWebsiteSettings(payload)
                        .then(response => console.log("Settings saved successfully", response))
                        .catch(error => console.error("Error saving settings", error));
                });
            });
        }
        
        function prefillInputFields(response) {
            container.empty(); // Clear the container
            response.forEach((item, tokenCount) => {
                let verificationStatus = item.verificationStatus;
                let verificationHelperText, verificationHelperClass;
                let acceptanceSwitches = addAcceptanceSwitches(item);
        
                switch (verificationStatus) {
                    case "Verified":
                        verificationHelperText = "Domain is verified";
                        verificationHelperClass = "valid";                   
                        break;
                    case "Failed":
                        verificationHelperText = "Domain verification failed";
                        verificationHelperClass = "invalid";
                        break;
                    case "Pending":
                        verificationHelperText = "Domain verification is pending";
                        verificationHelperClass = "invalid";
                        break;
                    default:
                        verificationHelperText = "Domain verification status unknown";
                        verificationHelperClass = "invalid";
                        break;
                }
        
                var newTokenField = $(
                    `<div class="token-field">
                        <div class="input-field" data-website-id="${item.id}">
                            <input type="text" id="dnsDomainField_${tokenCount}" name="dnsDomains[]" size="25" value="${item.domain}" class="validate" pattern="^(?!produck\\.de$)(?!www\\.)[a-zA-Z0-9\\-]+\\.[a-zA-Z]{2,6}" />
                            <label for="dnsDomainField_${tokenCount}">Domain</label>
                            <span class="helper-text" data-success="Domain format correct" data-error="Domain format incorrect" tabindex="-1"></span>
                        </div>
                        <div class="input-field">
                            <input type="text" disabled id="dnsTokenField_${tokenCount}" name="dnsTokens[]" size="25" value="${item.verificationToken}" class="${verificationHelperClass}" />
                            <label for="dnsTokenField_${tokenCount}">DNS Token</label>
                            <span class="helper-text" data-success="${verificationHelperText}" data-error="${verificationHelperText}" tabindex="-1"></span>
                        </div>
                        <i class="material-icons content-copy" data-i18n="[title]text.copy" title="Copy">content_copy</i>
                    </div>`
                );

                let actionButtons = $(
                    `<button type="button" class="btn prdk-btn generateTokenButton" data-i18n="text.generate_token">Generate Token</button>
                    <button type="button" class="btn prdk-btn deleteTokenButton" data-i18n="text.delete_token">Delete Token</button>`
                )
        
                // Append elements as jQuery objects
                $(newTokenField).append(acceptanceSwitches).append(actionButtons);
                container.append(newTokenField);
        
                // Initialize copy to clipboard functionality
                let copyBtn = $(newTokenField).find(".content-copy");
                let inputVal = $(newTokenField).find('input[name="dnsTokens[]"]').val().trim();
                instance.utils.initCopytoClipboard(inputVal, copyBtn);
        
                // Initialize switches with the actual jQuery object
                initSwitches(acceptanceSwitches, item);        
                $(newTokenField).localize();
            });
        }

        // Add new token field
        addButton.on("click", function () {
            var tokenFields = container.find(".token-field");
            var tokenCount = tokenFields.length;

            if (tokenCount < maxTokens) {
                var newTokenField = $(
                    '<div class="token-field">' +
                        '<div class="input-field">' +
                        '<input type="text" id="dnsDomainField_' +
                        tokenCount +
                        '" name="dnsDomains[]" size="25" class="validate" pattern="^(?!produck\\.de$)(?!www\\.)[a-zA-Z0-9\\-]+\\.[a-zA-Z]{2,6}" />' +
                        '<label for="dnsDomainField_' +
                        tokenCount +
                        '">Domain</label>' +
                        '<span class="helper-text" data-success="Domain format correct" data-error="Domain format incorrect"  tabindex="-1"></span>' +
                        "</div>" +
                        '<div class="input-field"><input type="text" disabled id="dnsTokenField_' +
                        tokenCount +
                        '" name="dnsTokens[]" size="25" />' +
                        '<label for="dnsTokenField_' +
                        tokenCount +
                        '">DNS Token</label></div>' +
                        '<i class="material-icons content-copy" data-i18n="[title]text.copy" title="Copy">content_copy</i>' +
                        '<button type="button" class="btn prdk-btn generateTokenButton" data-i18n="text.generate_token">Generate Token</button>' +
                        '<button type="button" class="btn prdk-btn deleteTokenButton" data-i18n="text.delete_token">Delete Token</button>' +
                        "</div>"
                );
                container.append(newTokenField);
                M.updateTextFields();
            } else {
                instance.utils.createSimpleAlertModal(
                    "You have reached the maximum number of tokens."
                );
            }
        });

        // Delete token field
        container.on("click", ".deleteTokenButton", function () {
            const $el = $(this);
            var tokenField = $el
                .siblings(".input-field")
                .find('input[name="dnsTokens[]"]');
            const tokenVal = tokenField.val();

            if (!tokenVal) {
                instance.utils.createSimpleAlertModal(
                    "No token value found, thus token deletion could not be accomplished."
                );
            } else {
                if (
                    !confirm(
                        "Deleting this token will unverify your site and remove additional features that are only available to verified sites. Do you want to continue?"
                    )
                ) {
                    return;
                }
            }

            // Generate token using the chat service
            instance.identityService
                .deleteUserWebsite(tokenVal)
                .then(function () {
                    $el.parent().remove();
                    instance.utils.createSimpleAlertModal(
                        "Token successfully deleted."
                    );
                })
                .catch(function (error) {
                    console.log("Error deleting token:", error);
                    instance.utils.createSimpleAlertModal(
                        "Failed to delete token."
                    );
                });
        });

        function validateDuplicateDomains(domain, currentField) {
            let isDuplicate = false;
            $('input[name="dnsDomains[]"]').each(function () {
                if (this !== currentField && $(this).val().trim() === domain) {
                    isDuplicate = true;
                    return false; // Exit loop
                }
            });
            return isDuplicate;
        }

        async function fetchAndPrefillDNSTokenList() {
            instance.utils.addLoader($("#dnskey-loader-container"), "small");

            console.log("Fetching tokens and domains.");

            instance.identityService
                .getUserWebsites()
                .then(function (response) {
                    console.log("Fetching tokens successful: ", response);
                    prefillInputFields(response);
                    M.updateTextFields();
                    instance.utils.removeLoader($("#dnskey-loader-container"));
                })
                .catch(function (error) {
                    console.log("DNS tokens service error: ", error);
                    instance.utils.createSimpleAlertModal(
                        "No dns tokens found. Please generate a new token."
                    );
                    instance.utils.removeLoader($("#dnskey-loader-container"));
                });
        }

        // Generate token
        container.on("click", ".generateTokenButton", async function () {
            var tokenInput = $(this)
                .siblings(".input-field")
                .find('input[name="dnsTokens[]"]');
            var domainInput = $(this)
                .siblings(".input-field")
                .find('input[name="dnsDomains[]"]');

            var copyBtn = $(this).siblings(".content-copy");

            var domain = domainInput.val().trim();

            if (!domain) {
                instance.utils.createSimpleAlertModal(
                    "A domain must be set to generate a token. Please enter the domain you want to verify first."
                );
                return;
            } else if (validateDuplicateDomains(domain, domainInput[0])) {
                instance.utils.createSimpleAlertModal(
                    "This domain is already in use."
                );
                return;
            }

            if (tokenInput.val()) {
                if (
                    !confirm(
                        "Generating a new token will invalidate the existing token and require it to be updated on partner sites afterwards. Do you want to continue?"
                    )
                ) {
                    return;
                }
            }
            // Generate token using the DNS service
            instance.identityService
                .getWebsiteDNSKey(domain)
                .then(function (response) {
                    tokenInput.val(response.token);
                    let inputVal = response.token;
                    instance.utils.initCopytoClipboard(inputVal, copyBtn);
                    instance.utils.createSimpleAlertModal(
                        'Token generated successfully. In the DNS settings of your domain, please add a new entry of type "TXT" and enter the name "_produck" and the generated token as TXT-value.'
                    );
                    M.updateTextFields();
                })
                .catch(function (error) {
                    console.log("Error generating token:", error);
                    instance.utils.createSimpleAlertModal(
                        "Failed to generate token."
                    );
                });
        });

        // Update DNS Token List
        updateButton.on("click", async function () {
            fetchAndPrefillDNSTokenList();
        });

        // Fetch and prefill tokens and domains when the collapsible header is clicked
        collapsibleHeader.on("click", async function () {
            if (!$(this).parent("li").hasClass("active")) {
                fetchAndPrefillDNSTokenList();
            }
        });
    }

    initialiseAffiliateLinkGenerator() {
        let instance = this;
        if (
            instance.authenticator.checkPermission(
                instance.authenticator.permCat.CREATE_AFFILIATE_LINK
            )
        ) {
            let targetInput = $("#affiliate-link-generator-input");
            let generatorButton = $("#affiliate-link-generator-button");
            let resultDiv = $("#affiliate-link-generator-result");
            let resultDivWidget = $("#affiliate-widget-result");
            let resultDivWidgetLarge = $("#affiliate-widget-result-large");
            let resultDivWidgetBlank = $("#affiliate-widget-result-blank");
            let resultDivWidgetImage = $("#affiliate-widget-result-image");
            let resultDivSpan = resultDiv.find("input");
            let resultDivresultDivWidgetSpan = resultDivWidget.find("input");
            let resultDivresultDivWidgetLargeSpan =
                resultDivWidgetLarge.find("input");
            let resultDivresultDivWidgetBlankSpan =
                resultDivWidgetBlank.find("input");
            let resultDivresultDivWidgetImageSpan =
                resultDivWidgetImage.find("input");
            let patterns = instance.utils.linkifyText();
            let isASIN = false;

            $("#affiliate-link-generator").removeClass("hide");

            let generationAction = function generateLink() {
                let targetLink = targetInput.val(),
                    asin;

                if (
                    targetLink &&
                    targetLink.length == 10 &&
                    (!targetLink.match(patterns.urlPattern) ||
                        !targetLink.match(patterns.pseudoUrlPattern))
                ) {
                    isASIN = true;
                    asin = targetInput.val().trim();
                    targetLink = `https://www.amazon.de/dp/${asin}`;
                } else {
                    isASIN = false;
                    $(".js-link-gen-result").hide();
                }

                resultDivSpan.val("");

                if (targetLink && targetLink.length > 0) {
                    instance.utils.addButtonLoader(
                        generatorButton,
                        true,
                        false
                    );
                    instance.billingService
                        .generateAffiliateLink(targetLink)
                        .then(function (affiliateLink) {
                            instance.utils.removeButtonLoader(generatorButton);
                            resultDivSpan.val(affiliateLink);
                            resultDiv.show();
                            instance.utils.initCopytoClipboard(
                                affiliateLink,
                                resultDiv.find(".content-copy")
                            );

                            if (isASIN) {
                                let widgetLink = `[asin="${asin}",type="standard"]`;
                                let widgetLinkLarge = `[asin="${asin}",type="large"]`;
                                let widgetLinkBlank = `[asin="${asin}",type="blank"]`;
                                let widgetLinkImage = `[asin="${asin}",type="image"]`;

                                resultDivresultDivWidgetSpan
                                    .val("")
                                    .val(widgetLink);
                                resultDivresultDivWidgetLargeSpan
                                    .val("")
                                    .val(widgetLinkLarge);
                                resultDivresultDivWidgetBlankSpan
                                    .val("")
                                    .val(widgetLinkBlank);
                                resultDivresultDivWidgetImageSpan
                                    .val("")
                                    .val(widgetLinkImage);
                                instance.utils.initCopytoClipboard(
                                    widgetLink,
                                    resultDivWidget.find(".content-copy")
                                );
                                instance.utils.initCopytoClipboard(
                                    widgetLinkLarge,
                                    resultDivWidgetLarge.find(".content-copy")
                                );
                                instance.utils.initCopytoClipboard(
                                    widgetLinkBlank,
                                    resultDivWidgetBlank.find(".content-copy")
                                );
                                instance.utils.initCopytoClipboard(
                                    widgetLinkImage,
                                    resultDivWidgetImage.find(".content-copy")
                                );

                                resultDivWidget.show();
                                resultDivWidgetLarge.show();
                                resultDivWidgetBlank.show();
                                resultDivWidgetImage.show();
                            }

                            M.updateTextFields();
                        })
                        .catch(function (error) {
                            switch (error.status) {
                                case 400:
                                    resultDivSpan.val(
                                        i18next.t("text.link_invalid")
                                    );
                                    break;
                                case 406:
                                    resultDivSpan.val(
                                        i18next.t("text.illegal_domain")
                                    );
                                    break;
                                case 0:
                                    resultDivSpan.val(
                                        i18next.t("text.service_not_reachable")
                                    );
                                    break;
                                case 401:
                                case 403:
                                    resultDivSpan.val(
                                        i18next.t("toasts.no_access")
                                    );
                                    break;
                                default:
                                    resultDivSpan.val(
                                        i18next.t("toasts.error")
                                    );
                                    instance.log.error(
                                        "Server error when trying to generate affiliate link",
                                        error
                                    );
                            }
                            instance.utils.removeButtonLoader(generatorButton);
                            resultDiv.show();
                            M.updateTextFields();
                        });
                } else {
                    resultDivSpan.val(i18next.t("text.link_invalid"));
                    resultDiv.show();
                    M.updateTextFields();
                }
            };

            generatorButton.on("click", generationAction);

            targetInput.on("keypress", function (e) {
                if (e.key == "Enter") {
                    generationAction();
                }
            });
        }
    }

    // This method is used to draw attention to the draggable feedback button on the index page
    highlightFeedback() {
        var tooltip = $(".tooltipped");
        var instance = M.Tooltip.getInstance(tooltip);

        setTimeout(() => {
            // Open the tooltip of the feedback button at page load regardless of mouse position so that
            // every visitor sees the text.
            instance.open();
        }, 1000);

        tooltip
            .delay(4500)
            .effect("shake", { direction: "left", times: 5, distance: 5 }, 700);

        setTimeout(() => {
            $(".material-tooltip").animate({ opacity: "0" }, 500);
            // Close the tooltip opened by code after 10 seconds (it will still be shown on mouse hover)
            instance.close();
        }, 10000);
    }

    setTime() {
        function addZero(i) {
            if (i < 10) {
                i = "0" + i;
            }
            return i;
        }

        var d = new Date();
        var h = addZero(d.getHours());
        var m = addZero(d.getMinutes());
        var time = h + ":" + m;

        return time;
    }

    convertChatToQuack() {
        const instance = this;
        var chatContainer = $(".cl.active");
        var quackifySection = chatContainer.find(".chat-quackify-section");
        var chatId = quackifySection.attr("data-chatid");
        var quackTopicField = chatContainer.find(".quack-topic-field");
        var title = quackTopicField.val();

        // collect tags for transmission to server
        // As soon https://github.com/Dogfalo/materialize/issues/6317 is fixed in MaterializeCSS the
        // following can be replaced something that directly uses the data array of the chips element
        var tags = [];
        var chips = quackifySection.find(".chip");
        chips.each(function (index, chip) {
            var textNode = $(chip).contents().first();
            if (!textNode) return;
            var tag = textNode.text();
            if (tag && tag.length > 0) {
                tags.push(tag);
            }
        });

        var quackLanguageField = quackifySection.find(".quackLngSelect");
        var quackDomainField = quackifySection.find(".quackDomainSelect");
        var langIso = quackLanguageField.val();
        var quackMode = quackDomainField.val();

        function successCallback() {
            console.log(
                "Quackify request returned with success for chatId " +
                    chatId +
                    "."
            );
            chatContainer.find(".chat-quackify-section").hide();
            chatContainer.find(".quackified-section").show();
        }

        function errorCallback(reason) {
            let errorMessage = '';

            if (reason.status === 400) {

                if (reason.responseText && reason.responseText.includes('insufficient content')) {
                    errorMessage += "The chat is too short to get published. Please add more content to reach at least 100 words. You can manage your chats under \"Quacks\" menu (Chat Archive)\n";
                }
                
                if (!errorMessage) {
                    errorMessage = i18next.t("text.quack_error");
                }
                
            } else {
                errorMessage = i18next.t("text.quack_error") + " In case the error persists, do not hesitate to contact us via the <a target=\"_blank\" href=\"/contact.html\">contact form</a>.";
            }
            
            instance.utils.createSimpleAlertModal(errorMessage);
            instance.log.error("Quackify request returned with FAILURE for chatId " + chatId + ". Reason: ", reason);    
        }

        var quackData = {
            id: chatId,
            mode: quackMode,
            title: title,
            tags: tags,
            lang: langIso,
        };

        this.chatclient.quackifyChat(quackData, successCallback, errorCallback);
    }

    /****** CHAT HANDLING ******/

    async reconnectActiveChats() {
        const instance = this;
        $(document).trigger("loader:on", [
            "",
            "transparent",
            document.getElementById("target2"),
        ]);

        // check if there are active chats and if so reconnect the xperts GUI to them
        console.log("Reconnecting active chats...");
        return instance._buildChatViewContent().finally(function () {
            $(document).trigger("loader:off");
        });
    }

    async _buildChatViewContent() {
        const instance = this;

        // first wipe the current content and reset chat partner names
        $("#target2 .cl[data-chatid]").remove();
        $("#target2 #chat_tab_wrapper").remove();
        $(".chat-overview-collapsible-body table").empty();
        instance.chatSupport.nameFunctionStatus.clear();

        // then asyncronously load all active chats
        return instance.chatclient
            .reconnectChats(1)
            .then(async function (response) {
                var currentExpertId = await instance.authenticator.getUser();

                if (!currentExpertId) {
                    throw "Tried to reconnect chat but current user's ID could not be retrieved from authenticator!";
                }

                if (response && response.length > 0) {
                    // found active chats so for each get the name of the chat partner and the messages
                    var chatsToProcess;
                    if (response.length > MAX_OPEN_CHATS) {
                        chatsToProcess = response.slice(0, MAX_OPEN_CHATS);
                    } else {
                        chatsToProcess = response;
                    }

                    let chatPromises = [];
                    chatsToProcess.forEach(function (chat) {
                        chatPromises.push(
                            instance.identityService
                                .getPublicUserData(chat.userId)
                                .then((userData) => {
                                    return { chat: chat, user: userData };
                                })
                        );
                    });

                    // set the chatclients state, the visible chat will be the first one retrieved
                    instance.chatclient.chatId = response[0].id;
                    instance.chatclient.nomChatId = response[0].id;
                    instance.chatclient.userId = currentExpertId;

                    return Promise.all(chatPromises)
                        .then(function (allResults) {
                            allResults.forEach(function (singleResult) {
                                instance._addChatToContainer(
                                    singleResult.chat,
                                    singleResult.user
                                );
                                return singleResult;
                            });
                        })
                        .catch(function (e) {
                            instance.log.error(
                                "Connecting active chats failed - Could not retrieve data of users.",
                                e
                            );
                            return e;
                        });
                }
            })
            .catch(function (error) {
                console.log("Reconnecting chats failed!", error);
            });
    }

    _addChatToContainer(activeChat, userData) {
        const instance = this;
        instance.chatSupport.extendChat(
            activeChat.id,
            userData.nickname,
            activeChat.userId,
            activeChat.providerId
        );
        var chatContainer = $(".cl[data-chatid=" + activeChat.id + "]");
        var chatlog = chatContainer.find(".chatlog.conversation-card");
        pretext.addExpertChatRequestInfo(
            chatlog,
            activeChat.topic,
            activeChat.product,
            activeChat.link
        );
        instance.cookie.updateChatHeader(
            userData,
            activeChat.id,
            activeChat.topic
        );

        // load the messages and add them to the particular container using the callback defined above
        instance.chatSupport.getMessages(activeChat.id);

        //Note! It is not necessary to register the current websocket session to the reconnected chats
        //because that has already been done by the reconnect process.

        instance.initVideoChat(chatContainer, activeChat.id);
        instance._initChatCloseButtons(chatContainer, activeChat.id);
    }

    /****** WSCONNECT HANDLER ******/

    initWsHandler() {
        var instance = this;
        instance.wsClient.onClose = function () {
            if ($("#os-checkbox-mobile").prop("checked") == true) {
                $("#os-checkbox-mobile")
                    .siblings(".lever")
                    .addClass("inactive");
            }

            $(".chat-online-signal[id] .inner-circle").each(function (
                index,
                signal
            ) {
                $(signal).removeClass("signal-online");
                $(signal).addClass("signal-offline").attr("title", "offline");
            });
        };

        instance.wsClient.onAuthentication = function () {
            if ($("#os-checkbox-mobile").prop("checked") == true) {
                instance.chatclient.registerOnlineStatus();
                $("#os-checkbox-mobile")
                    .siblings(".lever")
                    .removeClass("inactive");
            }
        };

        return instance.wsClient
            .connect()
            .then(function () {
                instance.chatclient.registerOnlineStatus();
                $("#os-checkbox-mobile").prop("checked", true);
                return true;
            })
            .catch(function () {
                console.log(
                    "ERROR: could not establish websocket connection. Reconnection of chats will not take place."
                );
            });
    }

    /******END WSCONNECT HANDLER******/
}
