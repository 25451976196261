/* global monstecLib */

/**
 * Provides a handler 'onStarClicked' that will be called whenever a single star of the control, that actually has a star-value is clicked.
 * If the rating control should be initialised with a specific value, a method 'getCurrentRating' must be defined, before attaching the component.
 */
export default class RatingControl {
    /**
     * Creates a new rating display / control
     * 
     * @param {string} the name of the permission the user must have in order to be able to use the rating control
     * @param {*} editable determines whether or not the current user will be able to change the rating represented by the current instance
     */
    constructor(permission, editable) {
        this.MAX_STARS = 5;
        this.EMPTY_STAR = ' <i class="material-icons">star_border</i>';
        this.HALF_STAR = ' <i class="material-icons">star_half</i>';
        this.FULL_STAR = ' <i class="material-icons">star</i>';
        this.RAW_ICON = ' <i class="material-icons"></i>';
        this.MODE_DISPLAY = 0;
        this.MODE_SET = 1;
        this.MODE_CHANGEABLE = 2;
        this.MODE_CHANGE = 3;

        this.i18next = monstecLib.i18next;
        this.utils = monstecLib.produckContext.utils;
        this.authenticator = monstecLib.produckContext.authenticator;
        this.register = monstecLib.produckContext.register;

        this.permission = permission;
        this.editable = !!editable;

        this.rating ={};
        this.rating.value = 0;

        this.log = new monstecLib.Log(1);
    }

    async attachTo(htmlId) {
        const instance = this;
        instance.ratingContainer = $('#' + htmlId);

        if (!instance.ratingContainer || instance.ratingContainer.length == 0) {
            instance.log.error(`Could not find element #${htmlId} to attach rating control to!`);
            return;
        }

        instance.starsContainer = $('<div>');
        instance.starsContainer.addClass('stars');
        instance.ratingContainer.append(instance.starsContainer);

        instance.commandLink = $('<a>');
        instance.commandLink.addClass('unobtrusive-action-link');
        instance.commandLink.click(instance._commandHandler.bind(instance));
        instance.ratingContainer.append(instance.commandLink);

        if (instance.getCurrentRating) {
            try {
                let initialRating = await instance.getCurrentRating();

                if (initialRating && initialRating.value) {
                    if (initialRating.value < 0) {
                        initialRating.value = 0;
                    } else if (initialRating.value > this.MAX_STARS) {
                        initialRating.value = this.MAX_STARS;
                    }
        
                    this.rating = initialRating;
                }
            } catch (e) {
                instance.log.debug('Could not get current Rating.', e);
            }
        }

        if (!instance.editable) {
            instance._setMode(this.MODE_DISPLAY);
        } else if (instance.rating.value == 0) {
            instance._setMode(this.MODE_SET);
        } else {
            instance._setMode(this.MODE_CHANGEABLE);
        }
    }

    /**
     * Set the value of the rating the current control represents. This method will only work if
     * the control is editable. After setting the value the control will be in MODE_CHANGEABLE.
     * 
     * @param {*} value the rating value
     */
    setRating(value) {
        const instance = this;
        if (!instance.editable) {
            throw 'RatingControl-instance is not editable!';
        }

        if (!instance.rating) instance.rating = {};
        instance.rating.value = value;
        instance._setMode(instance.MODE_CHANGEABLE);
    }

    _setMode(mode) {
        const instance = this;
        this.mode = mode;

        if (mode == instance.MODE_DISPLAY) {
            instance._drawRating();
            instance.commandLink.text('');
            instance.commandLink.addClass('hide disabled');
            instance.starsContainer.removeClass('changing');

        } else if (mode == instance.MODE_SET) {
            instance._drawEditControls();
            instance.commandLink.text(instance.i18next.t('general.rate'));
            instance.commandLink.removeClass('hide');
            instance.commandLink.addClass('disabled');
            instance.starsContainer.addClass('changing');

        } else if (mode == instance.MODE_CHANGE) {
            instance._drawEditControls();
            instance.commandLink.text(instance.i18next.t('general.cancel'));
            instance.commandLink.removeClass('hide disabled');
            instance.starsContainer.addClass('changing');

        } else { // i.e. MODE_CHANGEABLE
            instance._drawRating();
            instance.commandLink.text(instance.i18next.t('general.change'));
            instance.commandLink.removeClass('hide disabled');
            instance.starsContainer.removeClass('changing');
        }
    }

    _drawEditControls() {
        const instance = this;
        instance.starsContainer.empty();
        for (let i = 0; i < 5; i++) {
            instance._addStar(instance.RAW_ICON, i + 1);
        }
    }

    _drawRating() {
        const instance = this;
        instance.starsContainer.empty();

        // n counts the number of stars that have already been added
        // - begin at 1 because the n will be incremented after each loop run
        // - furthermore a rating value of < 1 should not lead to a star added here;
        //   that will be processed in the next step
        let n = 1;
        for (; n <= instance.rating.value; n++) {
            instance._addStar(instance.FULL_STAR);
        }
        // rewind the last increment for that the loop wont have been processed and so no start added
        n--;
  
        let remainder = (n > 0) ? (instance.rating.value % n) : instance.rating.value;
        if (remainder > 0.75) {
            instance._addStar(instance.FULL_STAR);
            n++;
        } else if (remainder > 0.25) {
            instance._addStar(instance.HALF_STAR);
            n++;
        } else if (remainder > 0) {
            instance._addStar(instance.EMPTY_STAR);
            n++;
        }

        for (; n < instance.MAX_STARS; n++) {
            instance._addStar(instance.EMPTY_STAR);
        }
    }

    _addStar(starType, starValue) {
        const instance = this;
        let star = $(starType);

        // if the given star value actually is a number greater than 0 add it to the star as an attribute
        // and the the handler that will transfer the value to the service eventually.
        if (instance.utils.isNumber(starValue) || starValue > 0) {
            star.attr('data-value', starValue);
            star.click(instance._starClickHandler.bind(instance));
        }
        
        instance.starsContainer.append(star);
    }

    async _starClickHandler(e) {
        const instance = this;

        let mayRate = await instance.authenticator.checkPermission(instance.permission);
        if (!mayRate) {
            instance.register.initSignInForm(true);
            return;
        }

        let clickedStar = $(e.target);
        let starValue = clickedStar.attr('data-value');

        if (instance.onStarClicked) {
            instance.onStarClicked(starValue);
        } else {
            if (instance.rating.value <= 0) {
                instance._setMode(instance.MODE_SET);
            } else {
                instance._setMode(instance.MODE_CHANGEABLE);
            }

            instance.log.warn('No star click handler is defined.');
        }
    }

    async _commandHandler() {
        const instance = this;

        let mayRate = await instance.authenticator.checkPermission(instance.permission);
        if (!mayRate) {
            instance.register.initSignInForm(true);
            return;
        }

        switch (instance.mode) {
        case instance.MODE_CHANGEABLE:
            instance._setMode(instance.MODE_CHANGE);
            break;
        case instance.MODE_CHANGE:
            instance._setMode(instance.MODE_CHANGEABLE);
            break;
        case instance.MODE_DISPLAY:
        case instance.MODE_CHANGEABLE:
            // nothing to do
            break;
        default:
            console.log.error('Encountered unknown mode:', instance.mode);
        }
    }
}
