/* global monstecLib */
/* global M */

export default class IFrameMessageDispatcher {
    constructor(portToParent) {
        this.port = portToParent;
    }

    sendInfoAboutMessage() {
        if (!this.port) return;

        const portMessagePayload = {
            method: 'info#message'
        };

        this.port.postMessage(JSON.stringify(portMessagePayload));
    }

    sendInfoAboutExpertJoined() {
        if (!this.port) return;

        const portMessagePayload = {
            method: 'info#join'
        };

        this.port.postMessage(JSON.stringify(portMessagePayload));
    }

    static inIframe() {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    // makes the handshake (port exchange) between main domain and iframe
    static waitForPortConnection() {
        return new Promise((resolve) => {
            //const allowedOrigins = [
            //    'https://monstec.de',
            //    'https://www.monstec.de',
            //    'https://www.smartkat.net/de',
            //    'http://www.gummiwerk-meuselwitz.de'
            //];

            //listen to answer from parent
            // Add "&& allowedOrigins.includes(e.origin)" to verify root domain
            window.onmessage = e => {
                if (e.data === 'HereIsYourPort') {
                    let produckWindowPort = e.ports[0];
                    produckWindowPort.postMessage(JSON.stringify({ status: 'Thank you, port received' }));
                    resolve(produckWindowPort);
                }
            };
            //ask parent for new port
            window.parent.postMessage("sendPortToProduck", "*");
        });
    }
}