
export default class AboutPage {
    constructor(cookieManager, externalAuthenticator) {
        this.cookieManager = cookieManager;
        this.authenticator = externalAuthenticator;
        this.utils = new monstecLib.Utils();
        this.chatService = new monstecLib.ChatServiceAccess();
        this.lazyload = new monstecLib.LazyLoad();
    }
    // Parameter targetClass is string defining css-class or html-id
    assignScrollFunction(element, targetClass, offsetTolerance) {
        $(element).click(function (e) {
            e.preventDefault();
            $('html, body')
                .animate({
                    scrollTop: $(targetClass).offset().top - offsetTolerance
                }, 'slow', 'swing', function () { });
        });
    }

    initPageFunctions(navigateViaUpDownBtn) {
        var instance = this;        

        if (navigateViaUpDownBtn && window.matchMedia("(min-width: 992px)").matches) {
            instance._initNavigateViaUpAndDownBtn();
        }

        //closes and opens profiles
        $(".pers-profile.open-button").click(function () {
            var $mainCard = $(this).parents().eq(2);
            $mainCard.toggleClass('off');
        });

        //closes and opens profiles
        $(document).on("click", "#close-bio", function () {
            var $this = $(this).parent();
            $this.removeClass('off');
            $this.css({
                "transform": "rotate3d(0,1,0, 0deg)", "transition": "all 1s ease-in-out"
            });
            $('.pers-profile').css({ "transition": "all 2s ease-in" });
        });

        // hide h1 bottom headline when end of site reached
        document.onscroll = function() {
            if (window.innerHeight + window.scrollY >= (document.body.clientHeight -1) ) {
                $('#headline-container').addClass('hide-con');
            } else {
                $('#headline-container').removeClass('hide-con');
            }
        }

        instance.lazyload.lazyloaderInit();

        // init Material Design
        function intiMaterialDesign() {

            // side navigation for mobile
            let elems = document.querySelectorAll('.sidenav');
            M.Sidenav.init(elems, { edge: 'left', closeOnClick: true });
            // use height (defined via #navigation in CSS) of navigation bar as scroll offset
            elems = document.querySelectorAll('.scrollspy');
            M.ScrollSpy.init(elems, { scrollOffset: 0 });
            // Material Box for Zooming of images
            elems = document.querySelectorAll('.materialboxed');
            var materialboxOptions = {
                'onOpenStart': function () { this.$el.closest('section').css({ "z-index": "2147483638" }); },
                'onCloseEnd': function () { this.$el.closest('section').css({ "z-index": "" });},
            };
            M.Materialbox.init(elems, materialboxOptions);

            // selector element
            elems = document.getElementsByClassName('browser-default');
            M.FormSelect.init(elems, {'classes': 'account-role'});

            //slider (big images above articles)
            elems = document.querySelectorAll('#container-articles .slider');
            var winheight = $(window).height() - (16 + 39.2); // substract navigation height - correction of 40px + h1 height
            M.Slider.init(elems, { indicators: true, duration: 1000, height: winheight });
            $('#container-articles').height(winheight);

            //slider (embedded sliders)
            elems = document.querySelectorAll('.content-wrapper .slider');
            M.Slider.init(elems, { indicators: false, height: winheight/2 });

            elems = $('.tooltipped');
            elems.each(function () { M.Tooltip.init(this, instance.utils.addToolTipWithArrow($(this)))});
        }

        instance.setSectionBackground();
        intiMaterialDesign();

        var elem = $('.browser-default');
        elem.on('change', function () {
            var accountRole = $(this).val()
            registrationForm.attr('name', accountRole).attr('data-apiurl', accountRole);
        });
    };

    setSectionBackground() {

        let sectionWithBgImg = document.querySelectorAll("section[data-bgImg]");

        for (let i = 0; i < sectionWithBgImg.length; i++) {
            let style = sectionWithBgImg[i].style;
            style.setProperty('--background-image', 'url("' + sectionWithBgImg[i].getAttribute("data-bgImg") + '")');
        }
    }

    _initNavigateViaUpAndDownBtn() {
        //Defines side nav for scrolling

        var iconGrid = '<svg class="hidden">'
            +'  <g id="icon-grid" style="stroke-width:4; stroke-linejoin: round">'
            +'    <line x1="0" y1="8" x2="30.7" y2="23" />'
            +'    <line x1="60" y1="8" x2="29.3" y2="23" />'
            +'  </g>'
            +'</svg>';

        var upBtn = '<button class="scrollbtn-up" id="prev">'
            +'  <svg width="100%" height="100%" viewBox="0 0 60 60" preserveAspectRatio="none">'
            +'    <use class="icon icon--grid" xlink:href="#icon-grid" />'
            +'    <use class="icon icon--cross" xlink:href="#icon-cross" />'
            +'  </svg>'
            +'</button>';

        var dwnBtn = '<button class="scrollbtn-down" id="next">'
            +'  <svg width="100%" height="100%" viewBox="0 0 60 60" preserveAspectRatio="none">'
            +'    <use class="icon icon--grid" xlink:href="#icon-grid" />'
            +'    <use class="icon icon--cross" xlink:href="#icon-cross" />'
            +'  </svg>'
            +'</button>';

        $('body').append(iconGrid);
        $('body').append(upBtn);
        $('body').append(dwnBtn);

        $(function () {
            var pagePositon = 0,
                sectionsSelector = '.scrollspy',
                $scrollItems = $(sectionsSelector),
                offsetTolorence = 30,
                pageMaxPosition = $scrollItems.length - 1;

            //Map the sections:
            $scrollItems.each(function (index, ele) { $(ele).attr("debog", index).data("pos", index); });

            //Bind position to scroll and load
            $(window).bind('load, scroll', upPos);

            //Move on click:
            $('#next').on('click', function () {
                if (pagePositon + 1 <= pageMaxPosition) {
                    pagePositon++;
                    $('html, body').stop().animate({
                        scrollTop: $scrollItems.eq(pagePositon).offset().top
                    }, 300);
                }
            });
            $('#prev').on('click', function () {
                if (pagePositon - 1 >= 0) {
                    pagePositon--;
                    $('html, body').stop().animate({
                        scrollTop: $scrollItems.eq(pagePositon).offset().top
                    }, 300);
                    return false;
                }
            });

            //Update position func:
            function upPos() {
                var fromTop = $(this).scrollTop();
                var $cur = null;
                $scrollItems.each(function (index, ele) {
                    if ($(ele).offset().top < fromTop + offsetTolorence) {
                        $cur = $(ele);
                    }
                });
                if ($cur !== null && pagePositon !== $cur.data('pos')) {
                    pagePositon = $cur.data('pos');
                }
            }
        });
    }
}
